import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Slider from 'react-slick';
import { UilArrowCircleLeft } from '@iconscout/react-unicons';
import { UilArrowCircleRight } from '@iconscout/react-unicons';

const Offers = [
  {
    id: 0,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-1.png?alt=media&token=9bfdef4e-690c-44cc-97db-eb273ded346e",
    textBold: "Asesoría genética",
    textRegular: "",
  },
  {
    id: 1,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-2.png?alt=media&token=6c5ec26b-a321-4c19-b7e1-111a55edcc7d",
    textBold: "Interconsulta con especialistas",
    textRegular: " a nivel nacional e internacional.",
  },
  {
    id: 2,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-3.png?alt=media&token=350ab2c6-725d-49bf-9cd7-b763fcf1df14",
    textBold: "Integración de",
    textRegular: " expediente clínico.",
  },
  {
    id: 3,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-4.png?alt=media&token=e4ef79ff-0ea8-44f1-a9d3-1a3f8996d1fb",
    textBold: "Amplio catálogo",
    textRegular: " de paneles de secuenciación genética.",
  },
  {
    id: 4,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-5.png?alt=media&token=f8cd107b-f373-48c3-a892-8ba55e1642f1",
    textBold: "",
    textRegular: "Tumor Board",
  },
  {
    id: 5,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-6.png?alt=media&token=6bfe8002-cbf9-4d83-b90c-d0ae89af9742",
    textBold: "Servicio de acompañamiento",
    textRegular: " antes, durante y después de la secuenciación genética.",
  },
];

const Advantages = [
  {
    id: 0,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-advantage-image-1.png?alt=media&token=1c72a24f-d151-48b8-af74-25c9492fb70b",
    textBold: "Resultados de valor",
    textRegular: " diagnóstico, pronóstico y/o terapéutico.",
  },
  {
    id: 1,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-advantage-image-2.png?alt=media&token=63b59b9a-1741-4fa2-974e-9f31449172b5",
    textBold: "Menor tiempo",
    textRegular: " de entrega de resultados.",
  },
  {
    id: 2,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-advantage-image-3.png?alt=media&token=05dc61f8-1088-4d67-9923-eedd41fd6e0a",
    textBold: "Reporte de resultados con",
    textRegular: " información de bases de datos nacionales e internacionales.",
  },
  {
    id: 3,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-3.png?alt=media&token=981eee6d-93ae-4f16-9945-424deecca29f",
    textBold: "Secuenciación eficiente de la muestra,",
    textRegular: " aún con cantidades mínimas de muestra.",
  },
  {
    id: 4,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-advantage-image-5.png?alt=media&token=a279ca83-9a45-4859-bdb6-d298519b2c84",
    textBold: "Pruebas",
    textRegular: " accesibles y confiables",
  },
  {
    id: 5,
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-offer-image-6.png?alt=media&token=6bfe8002-cbf9-4d83-b90c-d0ae89af9742",
    textBold: "Acompañamiento y asesoría",
    textRegular: " durante todo el proceso.",
  },
];

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #C4C4C4;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    background: #686766;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const Card = styled.div`
  padding-top: 26px;
  padding-bottom: 25px;
  text-align: left;
  span {
    font-weight: 700;
  }
  .item-image {
    width: 130px;
    margin: 0 auto 27px;
  }
  ul {
    margin: 40px auto 0;
    width: 167px;
  }
  ul:nth-of-type(1) {
    list-style-type: disc;
  }
`;

export const SliderOfferAdvantages = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleLeft
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleRight
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }

  return (
    <StyledSlider
      dots={true}
      infinite={true}
      slidesToShow={
        screenWidth > 991
          ? 2
          : screenWidth > 767
          ? 2
          : 1
      }
      slidesToScroll={
        screenWidth > 991
          ? 2
          : screenWidth > 767
          ? 2
          : 1
      }
      autoplay={true}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
    >
      {(props.cat === 0 ? Offers : Advantages ).map((item, index) => (
        <Card>
          <img alt={`imagen-${index}`} src={item.image} className="item-image" />
          <ul>
            <li><span>{item.textBold}</span>{item.textRegular}</li>
          </ul>
        </Card>
      ))}
    </StyledSlider>
  );
};
