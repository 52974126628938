import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Modal,
} from "react-bootstrap";
import { Genes } from "./genesList";
import { useForm } from "react-hook-form";
import { UilSearch, UilTimes } from "@iconscout/react-unicons";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { Contact } from "./Contact";

const GenesSection = styled.div``;

const TitleRow = styled(Row)`
  margin-top: 52px;
  margin-bottom: 22px;
`;

const SubtitleRow = styled(Row)`
  margin-bottom: 35px;
`;

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin-bottom: 0;
  padding: 0;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const Subtitle = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
  margin-bottom: 0;
  padding: 0;
  @media only screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 26px;
  }
`;

const SearcherRow = styled(Row)`
  justify-content: center;
  margin-bottom: ${(props) => (props.search === 1 ? "16px" : "64px")};
`;

const FoundRow = styled(Row)`
  margin-bottom: 22px;
`;

const FoundText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
`;

const GenesRow = styled(Row)`
  margin-bottom: 16px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const CardSection = styled.div`
  padding: 0;
  width: auto;
  background: #f2f7f7;
  border-radius: 40px;
  @media only screen and (max-width: 992px) {
    overflow-x: hidden;
  }
`;

const GenesCard = styled.div`
  background: #f2f7f7;
  border-radius: 40px;
  padding: 0;
  width: auto;
`;

const CardHeader = styled.div`
  background: #eaac34;
  border-radius: 40px 40px 0px 0px;
  padding: 9px;
`;

const CardTitle = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #1c3245;
  margin-bottom: 0;
`;

const CardBodyContainer = styled.div`
  @media only screen and (max-width: 992px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      background: #f2f7f7;
      border-radius: 40px;
      height: 0px;
    }
  }
`;

const CardBody = styled.div`
  background: #f2f7f7;
  border-radius: 0 0 40px 40px;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 24px;
  padding-bottom: 36px;
  @media only screen and (max-width: 992px) {
    min-width: 992px;
  }
  .col-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-collapse: separate;
    border-left: outset;
  }
`;

const GeneName = styled.p`
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: ${(props) => (props.bold === 1 ? "bold" : "400")};
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: ${(props) => props.color};
  margin-bottom: 0;
`;

const StyledFloating = styled(FloatingLabel)`
  position: relative;
  text-align: left;
  width: 100%;
  padding: 0;
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: "IBM Plex Sans", sans-serif;
    transition: 200ms;
  }
  :active,
  :focus-within {
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 6px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  font-family: "IBM Plex Sans", sans-serif;
  background: #f2f7f7 !important;
  border: ${(props) => (props.error === 1 ? "1px solid #FF0505" : "none")};
  border-radius: 10px 0 0 10px;
  max-height: 45px;
  padding: 0 10px 0 40px !important;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :not(:placeholder-shown) {
    padding-top: 0em !important;
  }
  :focus {
    padding-top: 0em !important;
  }
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) =>
      props.error === 1 ? "1px solid #FF0505" : "1px solid #cccccc"};
    border-style: dashed;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 21px;
    border-radius: 10px;
  }
`;

const ColButton = styled(Col)`
  border-radius: 0 100px 100px 0;
  background-color: #f2f7f7;
  padding: 0;
  @media only screen and (max-width: 768px) {
    background-color: #ffffff;
    height: 45px;
  }
`;

const SearchButton = styled.button`
  background-color: #c2bfba;
  border-radius: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #353433;
  border: none;
  outline: none;
  max-width: 130px;
  max-height: 45px;
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  transition: background-color 300ms;
  :hover {
    background-color: #eaac34;
  }
`;

const DisclaimerRow = styled(Row)`
  margin-bottom: 24px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const DisclaimerText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353433;
  max-width: 460px;
  
`;

const DisclaimerLink = styled.a`
  background: #eaac34;
  border-radius: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #353433;
  text-align: center;
  margin: 0;
  max-width: 215px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #1379bc;
  }
`;

const ContinueButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 241px;
  height: 40px;
  background: #eaac34;
  border: 2px solid transparent;
  border-radius: 10px !important;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #fff;
  }
  :hover {
    border: 2px solid #eaac34;
    transition: all 0.4s ease;
    background: #eaac34;
  }
`;

const ContButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 241px;
  height: 40px;
  background: #1379BC;
  border: 2px solid transparent;
  border-radius: 10px !important;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #fff;
  }
  :hover {
    border: 2px solid #1379BC;
    transition: all 0.4s ease;
    background: #1379BC;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content{
    margin-left: -70px;
    border: none;
    min-width: 35vw;
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }
  .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid transparent;
  }
  .modal-title {
    font-style: italic;
    font-weight: 900;
    font-size: 25px;
    line-height: 24px;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00ac90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: 15px;
    right: 15px;
    position: absolute;
  }
`;

export const GenesTable = () => {
  const [newGenList, setNewGenList] = useState([]);
  const [search, setSearch] = useState(false);
  const [found, setFound] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const formatList = (array, size) => {
    let position = 0;
    let slicedList = [];
    const length = array.length;
    while (position < length) {
      slicedList.push(array.slice(position, (position += size)));
    }
    setNewGenList(slicedList);
    return newGenList;
  };

  const SearchGen = (data) => {
    if (data.searchGen !== "") {
      setSearch(true);
      let item = Genes.find((gen) => gen.name === data.searchGen.toUpperCase());
      if (item) {
        setSearch(item.name);
        setFound(true);
        const geneField = document.getElementById(data.searchGen.toUpperCase());
        const divTable = document.getElementById("gene-table");
        var x = getOffset(geneField).left;

        divTable.scrollBy({
          left: x - 45,
          behavior: "smooth",
        });
      } else {
        setFound(false);
      }
    }
  };

  function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  const handleInput = ({ target }) => {
    let { value } = target;
    value.length === 0
      ? setSearch(false)
      : value !== search && setSearch(false);
  };

  useEffect(() => {
    return formatList(Genes, 14);
  }, [Genes]);

  return (
    <GenesSection>
      <Container>
        <TitleRow>
          <Title>¿Qué genes analiza este panel?</Title>
        </TitleRow>
        <SubtitleRow>
          <Subtitle>Conozca los genes que analiza este panel.</Subtitle>
        </SubtitleRow>
        <SearcherRow search={search ? 1 : 0}>
          <Form
            style={{ maxWidth: "566px" }}
            onSubmit={handleSubmit(SearchGen)}
          >
            <Row>
              <Col xs={12} sm={12} md={9} className="p-0">
                <StyledFloating
                  controlId="searchGen"
                  label={"Escriba el gen de su interés aquí"}
                >
                  <UilSearch
                    size={20}
                    color={"#1379BC"}
                    className="w-auto position-absolute mt-auto mb-auto"
                    style={{ left: "10px", top: 0, bottom: 0 }}
                  />
                  <StyledInput
                    type="text"
                    name="searchGen"
                    placeholder={"Escriba el gen de su interés aquí"}
                    {...register("searchGen", {
                      required: false,
                    })}
                    onChange={(e) => handleInput(e)}
                  />
                </StyledFloating>
              </Col>
              <ColButton xs={12} sm={12} md={3}>
                <SearchButton onClick={setShow} type="submit">
                  Buscar
                </SearchButton>
              </ColButton>
            </Row>
          </Form>
        </SearcherRow>
        {found ? (
          <StyledModal show={show} onHide={handleClose}>
            <div onClick={handleClose}>
              <UilTimes color="#fff" style={{ cursor: "pointer" }} />
            </div>
            
            <Modal.Body style={{ background: "#1379bc", borderRadius:"7px" }}>
              {search && (
                <FoundRow>
                  <FoundText found={1} style={{ color: "#fff" }}>
                    <Modal.Title style={{ color: "#fff" }}>
                      {search}
                    </Modal.Title>
                    <br />
                    Este gen si es analizado en el panel.
                  </FoundText>
                  <ContinueButton>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://api.whatsapp.com/send/?phone=5215519694584&text&type=phone_number&app_absent=0"
                    >
                      ¡Haga una solicitud!
                    </a>
                  </ContinueButton>
                </FoundRow>
              )}
            </Modal.Body>
          </StyledModal>
        ) : (
          <StyledModal show={show} onHide={handleClose}>
            <div onClick={handleClose}>
              <UilTimes color="#353433" style={{ cursor: "pointer" }} />
            </div>
            
            <Modal.Body style={{ background: "#C2BFBA", borderRadius:"9px"}}>
              {search && (
                <FoundRow>
                  <FoundText found={0} style={{ color: "#353433" }}>
                    <br />
                    Este gen no es analizado en este panel.
                    <br />
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      Comunícate con nuestros especialistas para más
                      información.
                    </span>
                  </FoundText>
                  <Contact/>
                  <p style={{textAlign:"center"}}>ó</p>
                  <ContButton style={{marginTop:"0px"}}>
                    <a
                      href={`https://api.whatsapp.com/send/?phone=5215519694584&text&type=phone_number&app_absent=0`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Chatea con nosotros
                    </a>
                  </ContButton>
                  <br />
                  <br />
                  <DisclaimerText style={{marginLeft:"auto", marginRight:"auto"}}>
                  <p style={{textAlign:"center", fontWeight:"700", width:"350px", marginLeft:"auto", marginRight:"auto"}}>Al continuar se abrirá una nueva ventana para iniciar una coversacion de Whatsapp</p>
                  </DisclaimerText>
                </FoundRow>
              )}
            </Modal.Body>
          </StyledModal>
        )}

        <GenesRow>
          <CardSection>
            <GenesCard>
              <CardHeader>
                <CardTitle>Genes analizados</CardTitle>
              </CardHeader>
              <CardBodyContainer id="gene-table" component={Paper}>
                <Table size="small">
                  <CardBody>
                    <Row className="justify-content-between">
                      {newGenList.map((list, index) => (
                        <Col xs={1} key={index}>
                          {list.map((gen, index) => (
                            <GeneName
                              key={index}
                              color={
                                search === gen.name ? "#1379BC" : "#353433"
                              }
                              bold={search === gen.name ? 1 : 0}
                              id={gen.name}
                            >
                              <TableCell> <th>{gen.name}</th></TableCell>
                            </GeneName>
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Table>
              </CardBodyContainer>
            </GenesCard>
          </CardSection>
        </GenesRow>
        <DisclaimerRow>
          <DisclaimerText>
            Este estudio analiza únicamente regiones específicas de cada gen,
            para más información consulte el siguiente documento.
          </DisclaimerText>
          <div>
            <DisclaimerLink
              className="pt-2 pe-4 pb-2 ps-4"
              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FDisclaimer%20AXIONA%20GENE.pdf?alt=media&token=a8e13daf-0ccf-412e-b981-361fa2e93c55"
              target={"_blank"}
              rel="noreferrer"
            >
              Descargar documento
            </DisclaimerLink>
          </div>
        </DisclaimerRow>
      </Container>
    </GenesSection>
  );
};
