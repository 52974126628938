import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Container,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ResultsImg from '../../assets/img/aboutSolid/resultsModal.png';
import TherapiesImg from '../../assets/img/aboutSolid/therapiesModal.png';
import TrialsModal from '../../assets/img/aboutSolid/trialsModal.png';
import { IoMdClose } from 'react-icons/io';

const StyledModal = styled(Modal)`
  .large {
    max-width: 930px;
    @media only screen and (max-width: 1200px) {
      max-width: 80%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: scroll;
    @media only screen and (max-width: 768px) {
      border-radius: 40px 40px 40px 40px;
      ::-webkit-scrollbar {
        border-radius: 0 0 40px 40px !important;
        background-color: #f9f9f9!important;
        height: 0px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    max-width: 920px;
    width: 820px;
  }
`;

const DetailModal = styled.img`
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TrialsFooterContainer = styled.div`
  background-color: #EAAC34;
  border-radius: 0px 0px 40px 40px;
  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

const FooterText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 810px;
`;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const SolidModal = (props) => {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <StyledRow className='pt-4 ps-4 pe-4 pb-0'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={3} className='ps-0'>
                <TitleContainer>
                  <StyledModalTitle>
                    {t('solid.modals.results.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc>
                    {t('solid.modals.results.content-one')}
                    <b>{t('solid.modals.results.content-bold')}</b>
                    {t('solid.modals.results.content-two')}
                    <b>{t('solid.modals.results.content-bold-two')}</b>
                    {t('solid.modals.results.content-three')}
                    <b>{t('solid.modals.results.content-bold-three')}</b>
                    {t('solid.modals.results.content-four')}
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>{t('solid.modals.results.example')}</ExampleText>
                </Row>
              </Col>
            </StyledRow>
            <StyledRow className='pb-4'>
              <DetailModal
                src={ResultsImg}
                alt='Imagen de ejemplo de resultados'
                className='img-fluid p-0'
              />
            </StyledRow>
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <Row className='p-4 pb-0'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={3} className='ps-0'>
                <TitleContainer>
                  <StyledModalTitle>
                    {t('solid.modals.therapies.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc>
                    {t('solid.modals.therapies.content-one')}
                    <b>{t('solid.modals.therapies.content-bold')}</b>
                    {t('solid.modals.therapies.content-two')}
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>{t('solid.modals.therapies.example')}</ExampleText>
                </Row>
              </Col>
            </Row>
            <Row className='pb-4'>
              <DetailModal
                src={TherapiesImg}
                alt='Imagen de ejemplo de detalles del tratamiento'
                className='img-fluid p-0'
              />
            </Row>
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <Row className='p-4 pb-0'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={3} className='ps-0'>
                <TitleContainer>
                  <StyledModalTitle>
                    {t('solid.modals.trials.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc className='mb-4'>
                    {t('solid.modals.trials.content-one')}
                    <b>{t('solid.modals.trials.content-bold')}</b>
                    {t('solid.modals.trials.content-two')}
                    <a target='_blank' href='https://clinicaltrials.gov/'>
                      <u>
                        {t('solid.modals.trials.link')}
                      </u>
                    </a>
                  </StyledModalDesc>
                  <StyledModalDesc>
                    {t('solid.modals.trials.more-info.text')}
                    <a target='_blank' href='https://clinicaltrials.gov/' rel='noreferrer noopener'>
                      <u>
                        {t('solid.modals.trials.more-info.link')}
                      </u>
                    </a>
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>
                    {t('solid.modals.therapies.example')}
                  </ExampleText>
                </Row>
              </Col>
            </Row>
            <Row className='mb-4'>
              <DetailModal
                src={TrialsModal}
                alt='Imagen de ejemplo de ensayo clínico'
                className='img-fluid p-0'
                />
            </Row>
            <Row className='position-relative'>
              <TrialsFooterContainer className='pt-4 pb-4 d-flex flex-row justify-content-center'>
                <FooterText>
                  {t('solid.modals.trials.footer.content-one')}
                  <b>
                    {t('solid.modals.trials.footer.content-bold')}
                  </b>
                </FooterText>
              </TrialsFooterContainer>
            </Row>
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
