import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GeneModal } from './geneModal';
import PlantImg from '../../assets/img/report/plant.png';

const Results = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FExampleReport1.png?alt=media&token=09f66383-0180-4e28-a1fd-1041dc14f298';
const Alterations = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FExampleReport2.png?alt=media&token=2bbe958f-30dc-470f-874a-f9a472db4ebd';
const Biomarkers = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FExampleReport3.png?alt=media&token=023502cb-02c0-433d-825e-cf654063f546';
const Therapies = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FExampleReport4.png?alt=media&token=8cc7dd98-83ad-4118-b2bb-e0edef16d8f3';
const Trials = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FExampleReport5.png?alt=media&token=42e3dfed-f59a-4de4-9d6d-50fe7fdf5e6f';

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin: 0;
  padding: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
  margin: 0;
  padding: 0;
`;

const AxionaDocuments = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  .first {
    max-width: 410px;
    /* position: absolute; */
    /* left: -20px; */
    /* right: 0; */
    margin-left: auto;
    margin-right: auto;
    transition: all 300ms;
  }
  .second {
    transition: all 300ms;
    max-width: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .third {
    transition: all 300ms;
    max-width: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .mask {
    max-width: 350px;
  }
  @media only screen and (max-width: 992px) {
    .second {
      top: 5%;
    }
    .third {
      top: 5%;
    }
  }
  @media only screen and (max-width: 768px) {
    .first {
      max-width: 380px;
      height: auto;
    }
    .second {
      max-width: 320px;
      height: auto;
    }
    .third {
      max-width: 320px;
      height: auto;
    }
    .mask {
      max-width: 320px;
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 400px) {
    .first {
      max-width: 100%;
      height: auto;
    }
    .second {
      max-width: 85%;
      height: auto;
    }
    .third {
      max-width: 85%;
      height: auto;
    }
    .mask {
      max-width: 85%;
      width: auto;
      height: auto;
    }
  }
`;

const DocumentImg = styled.img`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.selected === 1 ? '#EAAC34' : '#1379BC')};
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  margin: 0;
  width: auto;
  outline: none;
  border: none;
  transition: 300ms;
  margin-bottom: 57px;
  :hover {
    transition: 300ms;
    color: #ffffff;
    background-color: #eaac34;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const StepText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  text-align: left;
`;

const DocumentInfo = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353433;
  max-width: 460px;
  margin-bottom: 0;
`;

const DownloadTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin: 0;
  padding: 0;
  max-width: 650px;
`;

const DownloadBtn = styled.a`
  background: #eaac34;
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #353433;
  text-align: center;
  margin: 0;
  max-width: 215px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #1379bc;
  }
`;

const Plant = styled.img`
  position: absolute;
  top: -150%;
  right: 0%;
  max-width: 312px;
  padding: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
  @media only screen and (max-width: 1200px) {
    right: -3%;
    max-width: 270px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 170px;
    right: -5%;
    top: -180%;
  }
  @media only screen and (max-width: 768px) {
    position: unset;
    max-width: 176px;
    margin-right: -10px;
  }
`;

export const InfoGene = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [documentSelected, setDocumentSelected] = useState(0);
  const [modalSelected, setModalSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 992;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Container className={`pt-${isMobile ? '4' : '5'} pb-5`}>
      <Row className='mb-5'>
        <Title>{t('hem.info.title')}</Title>
      </Row>
      <Row className='mb-5'>
        <Desc 
          // data-aos="fade-up"
        >{t('hem.info.desc')}</Desc>
      </Row>
      {width <= 992 ? (
        <>
          <Row 
            // data-aos="fade-up"
            className='mb-3'
          >
            <StepText className='ps-4 mb-2'>
              {t('hem.info.steps.first')}
            </StepText>
            <StyledButton
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 1 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(1);
                setModalSelected(1);
                setShowModal(true);
              }}
            >
              {t('hem.info.buttons.first')}
            </StyledButton>
          </Row>
          <Row 
            // data-aos="fade-up"
            className='mb-3'
          >
            <StepText className='ps-4 mb-2'>
              {t('hem.info.steps.second')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '300px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 2 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(2);
                setModalSelected(2);
                setShowModal(true);
              }}
            >
              {t('hem.info.buttons.second')}
            </StyledButton>
          </Row>
          <Row 
            // data-aos="fade-up" 
            className='mb-4'
          >
            <StepText className='ps-4 mb-2'>
              {t('hem.info.steps.third')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '300px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 3 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(3);
                setModalSelected(3);
                setShowModal(true);
              }}
            >
              {t('hem.info.buttons.third')}
            </StyledButton>
          </Row>
          <Row 
            // data-aos="fade-up" 
            className='mb-4'
          >
            <StepText className='ps-4 mb-2'>
              {t('hem.info.steps.fourth')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '300px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 4 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(4);
                setModalSelected(4);
                setShowModal(true);
              }}
            >
              {t('hem.info.buttons.fourth')}
            </StyledButton>
          </Row>
          <Row 
            // data-aos="fade-up" 
            className='mb-4'
          >
            <StepText className='ps-4 mb-2'>
              {t('hem.info.steps.fifth')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '300px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 5 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(5);
                setModalSelected(5);
                setShowModal(true);
              }}
            >
              {t('hem.info.buttons.fifth')}
            </StyledButton>
          </Row>
          <Row className='mb-4 pb-2'>
            <AxionaDocuments>
              <DocumentImg
                src={documentSelected === 5 ? Trials : documentSelected === 4 ? Therapies : documentSelected === 3 ? Biomarkers : documentSelected === 2 ? Alterations : Results}
                alt='Documento de ejemplo'
                className='first'
              />
            </AxionaDocuments>
          </Row>
          <Row className='justify-content-center position-relative mb-4 pb-2'>
            <DocumentInfo 
              // data-aos="fade-up"
            >{t('hem.info.document-info')}</DocumentInfo>
          </Row>
        </>
      ) : (
        <Row className='mb-5'>
          <AxionaDocuments className='pt-4 pb-4'>
            <DocumentImg
              src={documentSelected === 5 ? Trials : documentSelected === 4 ? Therapies : documentSelected === 3 ? Biomarkers : documentSelected === 2 ? Alterations : Results}
              alt='Documento de ejemplo'
              className='first'
            />
            <ButtonsContainer>
              <Col xs={12}>
                <Row className='h-100 justify-content-between'>
                  <Col md={5}>
                    <div className='h-100 d-flex flex-column justify-content-start'>
                      <Row data-aos="fade-up">
                        <StepText className='ps-4 mb-2'>
                          {t('hem.info.steps.first')}
                        </StepText>
                        <StyledButton
                          className='pt-2 pe-4 pb-2 ps-4'
                          selected={documentSelected === 1 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(1)}
                          onClick={() => {
                            setModalSelected(1);
                            setShowModal(true);
                          }}
                        >
                          {t('hem.info.buttons.first')}
                        </StyledButton>
                      </Row>
                      <Row data-aos="fade-up">
                        <StepText className='ps-4 mb-2'>
                          {t('hem.info.steps.second')}
                        </StepText>
                        <StyledButton
                          style={{
                            maxWidth: '290px'
                          }}
                          className='pt-2 pe-4 pb-2 ps-4'
                          selected={documentSelected === 2 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(2)}
                          onClick={() => {
                            setModalSelected(2);
                            setShowModal(true);
                          }}
                        >
                          {t('hem.info.buttons.second')}
                        </StyledButton>
                      </Row>
                      <Row data-aos="fade-up">
                        <StepText className='ps-4 mb-2'>
                          {t('hem.info.steps.third')}
                        </StepText>
                        <StyledButton
                          style={{
                            maxWidth: '248px'
                          }}
                          className='pt-2 pe-2 pb-2 ps-2'
                          selected={documentSelected === 3 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(3)}
                          onClick={() => {
                            setModalSelected(3);
                            setShowModal(true);
                          }}
                        >
                          {t('hem.info.buttons.third')}
                        </StyledButton>
                      </Row>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className='h-100 d-flex flex-column justify-content-start'>
                      <Row className='justify-content-end' data-aos="fade-up">
                        <StepText
                          className='ps-4 mb-2'
                          style={{ maxWidth: '260px', textAlign: 'left' }}
                        >
                          {t('hem.info.steps.fourth')}
                        </StepText>
                        <br/>
                        <StyledButton
                          style={{
                            maxWidth: '260px'
                          }}
                          className='pt-2 pe-4 pb-2 ps-4 w-100'
                          selected={documentSelected === 4 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(4)}
                          onClick={() => {
                            setModalSelected(4);
                            setShowModal(true);
                          }}
                        >
                          {t('hem.info.buttons.fourth')}
                        </StyledButton>
                      </Row>
                      <Row className='justify-content-end' data-aos="fade-up">
                        <StepText
                          className='ps-4 mb-2'
                          style={{ maxWidth: '260px', textAlign: 'left' }}
                        >
                          {t('hem.info.steps.fifth')}
                        </StepText>
                        <br/>
                        <StyledButton
                          style={{
                            maxWidth: '260px'
                          }}
                          className='pt-2 pe-4 pb-2 ps-4 w-100'
                          selected={documentSelected === 5 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(5)}
                          onClick={() => {
                            setModalSelected(5);
                            setShowModal(true);
                          }}
                        >
                          {t('hem.info.buttons.fifth')}
                        </StyledButton>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </ButtonsContainer>
          </AxionaDocuments>
          <Row className='mt-4 justify-content-center position-relative mb-3'>
            <Plant src={PlantImg} alt='Ilustración de planta' />
            <DocumentInfo data-aos="fade-up">{t('hem.info.document-info')}</DocumentInfo>
          </Row>
        </Row>
      )}
      <Row className='justify-content-center mb-4'>
        <DownloadTitle>{t('hem.info.download-title')}</DownloadTitle>
      </Row>
      <Row className='justify-content-center'>
        <DownloadBtn
          className='pt-2 pe-4 pb-2 ps-4'
          target='_blank'
          href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FREPORTE%20EJEMPLO%20AXIONA%20HEME%2023.pdf?alt=media&token=d5fd99d3-cfbf-47be-b14b-3ac5a3d29842'
          rel='noreferrer noopener'
        >
          {t('hem.info.download-btn')}
        </DownloadBtn>
      </Row>
      {isMobile && (
        <Row className='justify-content-end mt-2 position-relative' style={{overflowX: 'hidden'}}>
          <Plant src={PlantImg} alt='Ilustración de planta' />
        </Row>
      )}
      <GeneModal
        show={showModal}
        setShowModal={setShowModal}
        modalSelected={modalSelected}
      />
    </Container>
  );
};
