import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Col,
  Row,
  Modal,
  ModalBody,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { ResultsModalContent } from './resultsModalContent';
import { AlterationsModalContent } from './alterationsModalContent';
import { BiomarkersModalContent } from './biomarkersModalContent';
import { TherapiesModalContent } from './therapiesModalContent';
import { TrialsModalContent } from './trialsModalContent';

const StyledModal = styled(Modal)`
  .large {
    max-width: 960px;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 100%;
      margin: 0;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: hidden;
    @media only screen and (max-width: 768px) {
      overflow-x: scroll;
      border-radius: 40px 40px 40px 40px !important;
      ::-webkit-scrollbar {
        background-color: #f9f9f9!important;
        height:0px !important;
        -webkit-overflow-scrolling: auto !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    max-width: 920px;
    width: 820px;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const DetailsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  padding-top: 21px;
  padding-bottom: 21px;
  margin-bottom: 45px;
`;

const DetailsSection = styled.div`
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const DetailTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin-bottom: 17px;
  padding: 0;
`;

const DetailText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin-bottom: 0;
  padding: 0;
`;

const DescBullet = styled.div`
  background: #1379BC;
  border-radius: 200px;
  max-width: 10px;
  max-height: 10px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 8px;
`;

export const GeneModal = (props) => {
  const [t, i18n] = useTranslation('global');

  const IconsMean = [
    {
      alt: 'incierto',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FInciertoIcon.png?alt=media&token=ee946822-0e62-47e5-8d2e-f1445104792f',
      title: '*Variante de significado incierto: ',
      desc: 'Cambio en la secuencia de ADN de un gen cuyo efecto en la salud no se conoce en el momento de su clasificación.'
    },
    {
      alt: 'patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92',
      title: '*Variante patogénica: ',
      desc: 'Alteración genética que aumenta la susceptibilidad o predisposición a padecer cáncer hereditario.'
    },
    {
      alt: 'probabilidad patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FProbPatogenicaIcon.png?alt=media&token=44ba50ad-1448-41f0-abfe-ab4c1739c38c',
      title: '*Variante probablemente patogénica: ',
      desc: 'Alteración genética que probablemente sea responsable de causar cáncer hereditario, pero no hay suficiente evidencia científica para aseverarlo.'
    },
    {
      alt: 'interpretaciones contradictorias',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FContradictoriasIcon.png?alt=media&token=27c28d1e-a615-488f-b0f7-286711207aa7',
      title: '*Interpretaciones contradictorias de la patogenicidad: ',
      desc: 'Definición asignada por ClinVar a las variantes con resultados contradictorios que no pueden ser clasificadas usando los términos recomendados por la ACMG/AMP para describir la patogenicidad y aquellos recomendados por ClinGen para clasificar los alelos de riesgo y variantes mendelianas de baja penetrancia.'
    },
  ];

  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <ResultsModalContent show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='alterations-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <AlterationsModalContent show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='biomarkers-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <BiomarkersModalContent show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 4 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='therapies-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <TherapiesModalContent show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 5 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='trials-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <TrialsModalContent show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
