import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenesList } from './genesList';
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

const TextContainer = styled.div`
  max-width: 288px;
  padding: 0;
  margin: 0;
`;

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #1c3245;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const DetectionBtn = styled.a`
  background: ${(props) =>
    props.selected === 1
      ? '#1379BC'
      : props.selected === 2
      ? '#1379BC'
      : props.selected === 3
      ? '#1379BC'
      : '#EAAC34'};
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: ${(props) =>
    props.selected === 1
      ? '#FFFFFF'
      : props.selected === 2
      ? '#FFFFFF'
      : props.selected === 3
      ? '#FFFFFF'
      : '#353433'};
  margin: 0;
  text-align: left;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: ${(props) =>
      props.selected === 1
        ? '#FFFFFF'
        : props.selected === 2
        ? '#FFFFFF'
        : props.selected === 3
        ? '#FFFFFF'
        : '#1379BC'};
  }
  @media only screen and (max-width: 576px) {
    text-align: center;
    width: 100%;
  }
`;

const LeftColumn = styled.div`
  max-width: 360px;
`;

const LeftCol = styled.div`
  margin: 0;
`;

const TableContainer = styled.div`
  border-radius: 40px;
`;

const TableHeader = styled.div`
  background-color: #eaac34;
  border-radius: 40px 40px 0px 0px;
`;

const TableTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #1c3245;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const TableContent = styled.div`
  background-color: #f2f7f7;
  border-radius: 0px 0px 48px 48px;
`;

const TableDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #1c3245;
  margin: 0;
  padding: 0;
`;

const GenesUl = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListElement = styled.li`
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: ${(props) =>
    props.gen === 1
      ? 700
      : props.gen === 2
      ? 700
      : props.gen === 3
      ? 700
      : 700};
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: ${(props) =>
    props.gen === 1
      ? '#1379BC'
      : props.gen === 2
      ? '#1379BC'
      : props.gen === 3
      ? '#1379BC'
      : '#353433'};
`;

export const Genes = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation('global');
  const [newList, setNewList] = useState();
  const [gen, setGen] = useState(0);
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const formatList = (array, size) => {
    let position = 0;
    let slicedList = [];
    const length = array.length;
    while (position < length) {
      slicedList.push(array.slice(position, (position += size)));
    }
    setNewList(slicedList);
    return newList;
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    return formatList(GenesList, 10);
  }, []);

  return (
    <Container className='pt-5 pb-5'>
      <Row className='pt-4'>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={5}
          className='d-flex flex-column justify-content-center'
        >
          <Row
            className={`justify-content-start`}
          >
            <LeftColumn>
              <TextContainer className='mb-4'>
                <Title className='mb-4'>{t('solid.genes.title')}</Title>
                <Desc>{t('solid.genes.desc')}</Desc>
              </TextContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(1)}
                  selected={gen === 1 ? 1 : 0}
                >
                  {t('solid.genes.buttons.button-one')}
                </DetectionBtn>
              </ButtonContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(2)}
                  selected={gen === 2 ? 1 : 0}
                >
                  {t('solid.genes.buttons.button-two')}
                </DetectionBtn>
              </ButtonContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(3)}
                  selected={gen === 3 ? 1 : 0}
                >
                  {t('solid.genes.buttons.button-three')}
                </DetectionBtn>
              </ButtonContainer>
            </LeftColumn>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={7}>
          <TableContainer data-aos="fade-up">
            <TableHeader className='pt-2 pb-2'>
              <TableTitle>{t('solid.genes.table.title')}</TableTitle>
            </TableHeader>
            <TableContent className='pb-4'>
              <Row className='pt-4 pb-4'>
                <TableDesc>
                  {gen === 1 ? (
                    <>
                      {t('solid.genes.table.headers.one')}
                    </>
                  ) : gen === 2 ? (
                    <>
                      {t('solid.genes.table.headers.two')}
                    </>
                  ) : gen === 3 ? (
                    <>
                      {t('solid.genes.table.headers.three')}
                    </>
                  ) : (
                    <></>
                  )}
                </TableDesc>
              </Row>
              <Row className='justify-content-center'>
                {newList?.map((item, index) => (
                  <Col xs={4} lg={2} style={{borderLeft:"outset"}} key={index} className='mb-2'>
                    <GenesUl>
                      {item?.map((gene, index) => (
                        <ListElement
                          gen={gene.belongs.find((g) => g === gen) ? gen : 0}
                          bold={gen.name ? 1 : 0}
                          key={index}
                        >
                          <TableCell><th>{gene.name}</th> </TableCell>
                        </ListElement> 
                      ))}
                    </GenesUl>
                  </Col>
                ))}
              </Row>
            </TableContent>
          </TableContainer>
        </Col>
      </Row>
    </Container>
  );
};
