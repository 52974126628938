import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

const StudyContainer = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 30px;
    padding-left: 30px;
    > hr {
        width: 100%;
    }
    > #price {
        font-weight: 600;
    }
`;

const StudyTitle = styled.h1`
    font-size: 30px;
`;

export const StudyDetail = () => {
    const[t, i18n] = useTranslation("global");
    const {id} = useParams();
    const study = t('home.cards', { returnObjects: true });
    return (
        <div className="container">
            <StudyContainer>
                {/* <StudyTitle>{study[id].title}</StudyTitle>
                <hr/>
                <p>{study[id].desc}</p>
                <p id="price">{study[id].price} MXN.</p> */}
            </StudyContainer>
        </div>
    );
};