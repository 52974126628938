import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import LabopatIcon from "../../assets/logos/labopat.png";
import HeilabIcon from "../../assets/logos/heilab.png";
import VitalgeneIcon from "../../assets/logos/vitalgene.png";
import Logo from "../../assets/img/logo-white.png";
import {
  UilFacebook,
  UilInstagram,
  UilTwitter,
  UilLinkedin,
  UilYoutube,
} from "@iconscout/react-unicons";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const FooterMain = styled.div`
  width: 100%;
  background: #1379bc;
  padding-top: 3px;
  @media only screen and (max-width: 992px) {
    background: #1379bc;
  }
`;

const WhiteSection = styled.div`
  background: #1379bc;
  padding: 62px 0 53px;
  font-family: "IBM Plex Sans";
  @media only screen and (max-width: 768px) {
    .col-12 {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    #iso {
      margin: 0 auto 15px !important;
    }
    #nosotros,
    #sub {
      text-align: center !important;
    }
    #linea-lb {
      margin-right: -40px;
    }
  }
  // ---------------- FIRST COL ----------------
  #lb-logo {
    width: 80%;
    max-width: 276px;
    margin: 0 0 23px 15px;
  }
  #iso {
    width: 75%;
    max-width: 264px;
    margin: 0 0 15px -85px;
  }
  #nosotros {
    text-align: start;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #ffffff;
    max-width: 280px;
    a {
      color: #474a4b;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  // ---------------- SECOND COL ----------------
  #linea-lb {
    width: 90%;
    max-width: 277px;
    margin-bottom: 32px;
  }
  ul {
    text-align: start;
    list-style: none;
    padding-left: 5px;
    li {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 10px;
    }
    a {
      color: #ffffff;
    }
  }
  // ---------------- THIRD COL ----------------
  #social-logos {
    width: 100%;
    max-width: 246px;
    display: flex;
    justify-content: space-between;
  }
  hr {
    color: #ffffff;
    opacity: 1;
  }
  #sub {
    color: #ffffff;
    text-align: start;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 351px;
    span {
      color: #ffffff;
      font-weight: 700;
    }
  }
`;

const BottomSection = styled(Container)`
  padding: 31px 16px 28px;
  #logos {
    div {
      width: 100%;
      max-width: 372px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
  #axiona {
    width: 112px;
  }
  #vitalgene {
    width: 116px;
  }
  #heilab {
    width: 80px;
  }
  p {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-align: initial;
  }
  @media only screen and (max-width: 992px) {
    #logos div {
      margin: 0 auto 16px;
    }
    padding: 31px 26px 41px;
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 11px;
    p {
      max-width: 246px;
      margin: 0 auto 30px;
    }
  }
`;

const SubscribeForm = styled.form`
  margin-top: 30px;
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 5px;
    font-family: "IBM Plex Sans";
    height: 35px;
    padding: 2px 10px;
  }

  .form-floating > .form-control:focus {
    padding-top: 0.5em;
  }

  .form-floating > label {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #000;
    border-radius: 12px;
    transform-origin: 50px -70px;
    background: #f6f6f6;
    padding: 2px 10px;
    opacity: 1;
  }

  .form-floating > .form-control {
    padding-left: 10px;
    padding-top: 0.5em;
  }
`;

const GreenButton = styled.button`
  width: 100%;
  max-width: 145px;
  height: 35px;
  background: #eaac34;
  border-radius: 10px;
  border: none;
  color: #fff;
`;

export const Footer = () => {
  const number = "5215519694584";

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    // TODO: Add modal confirmation
  };

  return (
    <FooterMain>
      <WhiteSection>
        <Container>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={6}
              className="pt-3 d-flex flex-column justify-content-start mb-5"
            >
              <img src={Logo} alt="Logo Axiona" id="lb-logo" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={7}>
              <Row>
                {/* ISO and abstract */}
                <Col xs={12} md={12} lg={6}>
                  <img
                    alt="ISO"
                    id="iso"
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Ffooter%2FISO.png?alt=media&token=592cda2c-f830-4f80-a674-fd698f42a93d"
                  />
                  <p id="nosotros">
                    <span style={{ fontSize: "12px" }}>
                      Vigencia de 2023-03-01 al 2026-03-01
                    </span>{" "}
                    <br />
                    Alcance: Servicios genómicos mediante secuenciación de
                    siguiente generación (NGS).
                  </p>
                  <br />
                  <p id="nosotros">
                    Axiona® es una empresa dedicada a la difusión y
                    comercialización de la secuenciación y tecnologías del
                    diagnóstico molecular aplicado a la oncología.
                  </p>
                </Col>
                {/* Phone and links */}
                <Col xs={12} md={6} lg={5}>
                  <a
                    href={`https://wa.me/${number}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Linea Labopat"
                      id="linea-lb"
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Ffooter%2FLINEA%20LABO.png?alt=media&token=4a314960-f969-46d1-85ae-5972861ad219"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        className="link"
                        onClick={() => window.location.replace("/#contacto")}
                      >
                        Contacto
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fprivacy%2FTe%CC%81rminos%20y%20condiciones%20del%20servicio.pdf?alt=media&token=3349f4b2-41c9-4f99-81e4-150194ffcc77"
                      >
                        Términos y condiciones del servicio
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fprivacy%2FAviso%20de%20Privacidad_Clientes.pdf?alt=media&token=81da0471-c163-483f-bc12-01259c3f24ec"
                      >
                        Aviso de privacidad - Clientes
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fprivacy%2FAviso%20de%20Privacidad_Pacientes.pdf?alt=media&token=755eeb0e-de17-429f-b035-b7c5687c6ccd"
                      >
                        Aviso de privacidad - Pacientes
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fprivacy%2FSolicitud%20ARCO.pdf?alt=media&token=4b20af35-32b9-4429-a478-ed033caa02ad"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Solicitud ARCO
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            {/* Social networks and subscribe */}
            <Col xs={12} lg={5}>
              <div id="social-logos">
                <a
                  href="https://www.facebook.com/profile.php?id=100082002550080"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilFacebook size="26" color="#ffffff" />
                </a>
                <a
                  href="https://twitter.com/AxionaGenetics"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilTwitter size="26" color="#ffffff" />
                </a>
                <a
                  href="https://www.instagram.com/axionagenetics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilInstagram size="26" color="#ffffff" />
                </a>
                <a
                  href="https://www.linkedin.com/company/axiona-genetics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilLinkedin size="26" color="#ffffff" />
                </a>
                <a
                  href="https://www.youtube.com/@axionagenetics"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilYoutube size="26" color="#ffffff" />
                </a>
              </div>
              <hr />
              <p id="sub">
                <span>Suscríbete</span> a nuestra Newsletter.
                <br />
                Tenemos información de valor, interesante y lo mejor de todo,
                ¡es gratis!
              </p>
              {/* Subscribe form */}
              <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={7} style={{ paddingLeft: "0" }}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Correo electrónico"
                      className="mb-3"
                    >
                      <Form.Control
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                            message: "Correo inválido",
                          },
                        })}
                        type="email"
                        placeholder="name@example.com"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={5}>
                    <GreenButton type="submit">Suscribirme</GreenButton>
                  </Col>
                </Row>
              </SubscribeForm>
            </Col>
          </Row>
        </Container>
      </WhiteSection>
      <BottomSection>
        <Row>
          {/* Logos */}
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 5, offset: 7, order: 1 }}
            id="logos"
          >
            <div>
              <a
                href="https://www.axionagenetics.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LabopatIcon} alt="Labopat" id="axiona" />
              </a>
              <a
                href="https://www.vitalgene.com.mx/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={VitalgeneIcon} alt="Vitalgene" id="vitalgene" />
              </a>
              <a
                href="https://www.heilab.com.mx/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={HeilabIcon} alt="heilab" id="heilab" />
              </a>
            </div>
          </Col>
          {/* License and rights */}
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 3 }}>
            <p>Sitio web creado por: Heilab! www.heilab.com.mx</p>
          </Col>
          <Col xs={{ span: 12, order: 3 }} lg={{ span: 7, order: 2 }}>
            <p>© 2024 Axiona® Genetics - Todos los derechos reservados</p>
          </Col>
        </Row>
      </BottomSection>
    </FooterMain>
  );
};
