import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';
import BannerBg from '../../assets/backgrounds/report/banner.webp';
import BannerBgMobile from '../../assets/backgrounds/report/bannermobile.webp';
import { useTranslation } from 'react-i18next';

const BannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  @media only screen and (max-width: 768px){
    align-items: flex-start;
  }
`;

const BannerContent = styled(Col)`
  position: absolute;
  left: 10%;
  padding: 4% 6% 2% 5%;
  border-radius: 10rem 10rem 10rem 0;
  background-color: #EBAB34;
  @media only screen and (max-width: 768px){
    border-radius: 7rem 7rem 7rem 0;
    padding: 10%;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
`;

const BannerTitle = styled.p`
  font-family: WarblerText-Regular;
  font-weight: 500;
  font-size: 56px;
  line-height: 120%;
  color: #434444;
  text-align: left;
  margin: 0;
  @media only screen and (max-width: 992px){
    font-size: 42px;
  }
  @media only screen and (max-width: 768px){
    font-size: 34px;
  }
`;

const BannerDesc = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 21px;
  line-height: 120%;
  text-align: left;
  margin: 0;
  padding-top: 8px;
  padding-right: 34px;
  @media only screen and (max-width: 992px){
    font-size: 18px;
  }
  @media only screen and (max-width: 768px){
    font-size: 14px;
    padding-right: 0px;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation("global");

  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
         window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <BannerContainer>
      <BannerContent xs={10} lg={9} xl={7} data-aos='fade-up'>
        <BannerTitle>{t('report.banner.title-part-one')}</BannerTitle>
        <BannerTitle>{t('report.banner.title-part-two')}</BannerTitle>
        <BannerDesc>
          {t('report.banner.desc-part-one')}
          <b>{t('report.banner.desc-part-two')}</b>
           {t('report.banner.desc-part-three')}
          <b>{t('report.banner.desc-part-four')}</b>
        </BannerDesc>
      </BannerContent>
      <img src={isMobile ? BannerBgMobile : BannerBg} alt='Banner background' className='img-fluid w-100' />
    </BannerContainer>
  );
}