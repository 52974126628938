export const Genes = [
  {
    name: 'AIP',
    color: '#353433',
  },
  {
    name: 'AKT1',
    color: '#353433',
  },
  {
    name: 'ALK',
    color: '#353433',
  },
  {
    name: 'ANTXR1',
    color: '#353433',
  },
  {
    name: 'APC',
    color: '#353433',
  },
  {
    name: 'ASCC1',
    color: '#353433',
  },
  {
    name: 'ATM',
    color: '#353433',
  },
  {
    name: 'ATR',
    color: '#353433',
  },
  {
    name: 'AXIN2',
    color: '#353433',
  },
  {
    name: 'BAP1',
    color: '#353433',
  },
  {
    name: 'BARD1',
    color: '#353433',
  },
  {
    name: 'BLM',
    color: '#353433',
  },
  {
    name: 'BMPR1A',
    color: '#353433',
  },
  {
    name: 'BRCA1',
    color: '#353433',
  },
  {
    name: 'BRCA2',
    color: '#353433',
  },
  {
    name: 'BRIP1',
    color: '#353433',
  },
  {
    name: 'BUB1',
    color: '#353433',
  },
  {
    name: 'BUB1B',
    color: '#353433',
  },
  {
    name: 'CACNA1D',
    color: '#353433',
  },
  {
    name: 'CBL',
    color: '#353433',
  },
  {
    name: 'CDC73',
    color: '#353433',
  },
  {
    name: 'CDH1',
    color: '#353433',
  },
  {
    name: 'CDK4',
    color: '#353433',
  },
  {
    name: 'CDKN1B',
    color: '#353433',
  },
  {
    name: 'CDKN2A',
    color: '#353433',
  },
  {
    name: 'CHEK2',
    color: '#353433',
  },
  {
    name: 'CTHRC1',
    color: '#353433',
  },
  {
    name: 'CYLD',
    color: '#353433',
  },
  {
    name: 'DDB2',
    color: '#353433',
  },
  {
    name: 'DICER1',
    color: '#353433',
  },
  {
    name: 'EGFR',
    color: '#353433',
  },
  {
    name: 'ELAC2',
    color: '#353433',
  },
  {
    name: 'EPCAM',
    color: '#353433',
  },
  {
    name: 'ERCC2',
    color: '#353433',
  },
  {
    name: 'ERCC3',
    color: '#353433',
  },
  {
    name: 'ERCC4',
    color: '#353433',
  },
  {
    name: 'ERCCS',
    color: '#353433',
  },
  {
    name: 'EXT1',
    color: '#353433',
  },
  {
    name: 'EXT2',
    color: '#353433',
  },
  {
    name: 'FANCA',
    color: '#353433',
  },
  {
    name: 'FANCB',
    color: '#353433',
  },
  {
    name: 'FANCC',
    color: '#353433',
  },
  {
    name: 'FANCD2',
    color: '#353433',
  },
  {
    name: 'FANCE',
    color: '#353433',
  },
  {
    name: 'FANCF',
    color: '#353433',
  },
  {
    name: 'FANCG',
    color: '#353433',
  },
  {
    name: 'FANCI',
    color: '#353433',
  },
  {
    name: 'FANCL',
    color: '#353433',
  },
  {
    name: 'FANCM',
    color: '#353433',
  },
  {
    name: 'FH',
    color: '#353433',
  },
  {
    name: 'FLCN',
    color: '#353433',
  },
  {
    name: 'GPC3',
    color: '#353433',
  },
  {
    name: 'HNF1A',
    color: '#353433',
  },
  {
    name: 'HNF1B',
    color: '#353433',
  },
  {
    name: 'HOXB13',
    color: '#353433',
  },
  {
    name: 'KDR',
    color: '#353433',
  },
  {
    name: 'KIT',
    color: '#353433',
  },
  {
    name: 'KLLN',
    color: '#353433',
  },
  {
    name: 'LIG4',
    color: '#353433',
  },
  {
    name: 'MAX',
    color: '#353433',
  },
  {
    name: 'MEN1',
    color: '#353433',
  },
  {
    name: 'MET',
    color: '#353433',
  },
  {
    name: 'MITF',
    color: '#353433',
  },
  {
    name: 'MLH1',
    color: '#353433',
  },
  {
    name: 'MLH3',
    color: '#353433',
  },
  {
    name: 'MRE11A',
    color: '#353433',
  },
  {
    name: 'MSH2',
    color: '#353433',
  },
  {
    name: 'MSH6',
    color: '#353433',
  },
  {
    name: 'MSR1',
    color: '#353433',
  },
  {
    name: 'MTAP',
    color: '#353433',
  },
  {
    name: 'MUTYH',
    color: '#353433',
  },
  {
    name: 'MYH8',
    color: '#353433',
  },
  {
    name: 'NBN',
    color: '#353433',
  },
  {
    name: 'NCOA4',
    color: '#353433',
  },
  {
    name: 'NF1',
    color: '#353433',
  },
  {
    name: 'NF2',
    color: '#353433',
  },
  {
    name: 'NTRK1',
    color: '#353433',
  },
  {
    name: 'PALB2',
    color: '#353433',
  },
  {
    name: 'PALLD',
    color: '#353433',
  },
  {
    name: 'PDGFRB',
    color: '#353433',
  },
  {
    name: 'PHOX2B',
    color: '#353433',
  },
  {
    name: 'PIK3CA',
    color: '#353433',
  },
  {
    name: 'PMS2',
    color: '#353433',
  },
  {
    name: 'POLD1',
    color: '#353433',
  },
  {
    name: 'POLE',
    color: '#353433',
  },
  {
    name: 'POLH',
    color: '#353433',
  },
  {
    name: 'PPM1D',
    color: '#353433',
  },
  {
    name: 'PRF1',
    color: '#353433',
  },
  {
    name: 'PRKAR1A',
    color: '#353433',
  },
  {
    name: 'PRSS1',
    color: '#353433',
  },
  {
    name: 'PTCH1',
    color: '#353433',
  },
  {
    name: 'PTCH2',
    color: '#353433',
  },
  {
    name: 'PTEN',
    color: '#353433',
  },
  {
    name: 'PTPN11',
    color: '#353433',
  },
  {
    name: 'RAD50',
    color: '#353433',
  },
  {
    name: 'RAD51D',
    color: '#353433',
  },
  {
    name: 'RAD5IC',
    color: '#353433',
  },
  {
    name: 'RB1',
    color: '#353433',
  },
  {
    name: 'RECQL4',
    color: '#353433',
  },
  {
    name: 'RET',
    color: '#353433',
  },
  {
    name: 'RHBDF2',
    color: '#353433',
  },
  {
    name: 'RNASEL',
    color: '#353433',
  },
  {
    name: 'RNF168',
    color: '#353433',
  },
  {
    name: 'RSPO1',
    color: '#353433',
  },
  {
    name: 'RUNX1',
    color: '#353433',
  },
  {
    name: 'SBDS',
    color: '#353433',
  },
  {
    name: 'SDHA',
    color: '#353433',
  },
  {
    name: 'SDHAF2',
    color: '#353433',
  },
  {
    name: 'SDHB',
    color: '#353433',
  },
  {
    name: 'SDHC',
    color: '#353433',
  },
  {
    name: 'SDHD',
    color: '#353433',
  },
  {
    name: 'SETBP1',
    color: '#353433',
  },
  {
    name: 'SH2D1A',
    color: '#353433',
  },
  {
    name: 'SLX4',
    color: '#353433',
  },
  {
    name: 'SMAD4',
    color: '#353433',
  },
  {
    name: 'SMARCB1',
    color: '#353433',
  },
  {
    name: 'SMARCE1',
    color: '#353433',
  },
  {
    name: 'SPINK1',
    color: '#353433',
  },
  {
    name: 'SPRED1',
    color: '#353433',
  },
  {
    name: 'STK11',
    color: '#353433',
  },
  {
    name: 'SUFU',
    color: '#353433',
  },
  {
    name: 'TERT',
    color: '#353433',
  },
  {
    name: 'TGFBR1',
    color: '#353433',
  },
  {
    name: 'TMEM127',
    color: '#353433',
  },
  {
    name: 'TP53',
    color: '#353433',
  },
  {
    name: 'TSC1',
    color: '#353433',
  },
  {
    name: 'TSC2',
    color: '#353433',
  },
  {
    name: 'VHL',
    color: '#353433',
  },
  {
    name: 'WAS',
    color: '#353433',
  },
  {
    name: 'WRN',
    color: '#353433',
  },
  {
    name: 'WT1',
    color: '#353433',
  },
  {
    name: 'XPA',
    color: '#353433',
  },
  {
    name: 'XPC',
    color: '#353433',
  },
  {
    name: 'XRCC2',
    color: '#353433',
  },
];
