import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { SliderProcess } from './sliderProcess';
import { SliderOfferAdvantages } from './sliderOfferAdvantages';

const WhiteSection = styled.div`
  width: 100%;
  height: auto;
  background-color: #FFF;
  h2 {
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1C3245;
    margin-bottom: 64px;
    @media only screen and (max-width: 768px){
      font-size: 17px;
      line-height: 26px;
      padding-left: 22px;
      text-align: left;
      margin-bottom: 45px;
    }
  }
  #sliders-div {
    margin-bottom: 80px;
  }
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #EAAC34;
  margin-bottom: 35px;
  @media only screen and (max-width: 768px){
    font-size: 30px;
    line-height: 39px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 12px;
    margin-bottom: 25px;
  }
  `;
  
  const Process = styled(Col)`
  text-align: left;
  a {
    text-decoration: underline;
    color: #353433;
  }
  li {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #353433;
  }
  span {
    font-weight: 700;
  }
  h1 {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 22px;
    @media only screen and (max-width: 768px){
      font-size: 24px;
      line-height: 31px;
      padding-left: 12px
    }
  }
`;

const YellowDiv = styled.div`
  background: #FFFFFF;
  border: 2px solid #EAAC34;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
  max-width: 544px;
  margin: 0 auto;
  h1 {
    background: #EAAC34;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    @media only screen and (max-width: 768px){
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const BlueDiv = styled.div`
  background: #FFFFFF;
  border: 2px solid #1379BC;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  max-width: 544px;
  @media only screen and (max-width: 992px) {
    margin-top: 31px;
  }
  h1 {
    background: #1379BC;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    @media only screen and (max-width: 768px){
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export const WhyAxiona = () => {
  return(
    <WhiteSection>
      <Container>
        <Title>¿Qué tiene Axiona® para usted?</Title>
        <h2>Acompañamiento para su paciente.</h2>
        <Row id="sliders-div">
          <Col md={12} lg={6} data-aos='fade-up'>
            <YellowDiv>
              <h1>Ofrecemos</h1>
              <SliderOfferAdvantages cat={0}/>
            </YellowDiv>
          </Col>
          <Col md={12} lg={6} data-aos='fade-up'>
            <BlueDiv>
              <h1>Ventajas Axiona®</h1>
              <SliderOfferAdvantages cat={1}/>
            </BlueDiv>
          </Col>
        </Row>
        <Row>
          <Process xs={12} lg={5}>
            <h1><span>Conoce el proceso de las pruebas Axiona®</span></h1>
            <ol>
              <li>Solicitar prueba a <a href='#'>contacto@axionagenetics.com</a> o <a href='#'>www.axionagenetics.com</a>, también llame <span>solicitando consejería a la línea Axiona® +52 55 19 69 4584</span></li>
              <li>Envío de la muestra al laboratorio.</li>
              <li>Procesamiento de la muestra en el laboratorio.</li>
              <li>Entrega de resultados al médico tratante</li>
              <li>Tumor board</li>
            </ol>
          </Process>
          <Col xs={12} lg={7}>
            <SliderProcess/>
          </Col>
        </Row>
      </Container>
    </WhiteSection>
  );
}