import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const TeamContainer = styled.div`

`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 2rem;
  color: #1378BC;
  text-align: center;
  @media only screen and (max-width: 768px){
    font-size: 28px;
    text-align: left;
  }
`;

export const Team = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  }
  return(
    <div className='pt-5 pb-5'>
      <Title className='mb-5'>Consejo médico</Title>
      <Slider {...settings}>
      </Slider>
    </div>
  );
}
