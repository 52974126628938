import React from "react";
import { LiquidBanner } from "./banner";
import { AboutLiquid } from "./aboutLiquid";
import { LiquidSlider } from './liquidSlider';
import { Genes } from './genes';
import { InfoLiquid } from './infoLiquid';
import { VideoLiquid } from "./videoLiquid";
import { Helmet } from "react-helmet";

export const AxionaLiquid = () => {
  return(
    <>
    <Helmet>
            <title>Axiona® LIQUID</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <LiquidBanner />
      <VideoLiquid />
      <AboutLiquid />
      <LiquidSlider />
      <Genes />
      <InfoLiquid />
    </>
  );
}