import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons';

const Processes = [
  {
    id: 0,
    number: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-number-1.png?alt=media&token=ade898db-7e39-4908-bf48-b2a5cbcd4731",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-1.png?alt=media&token=7d8e968c-656f-452d-9cf5-ff1b73c43857",
    text: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-text-1.png?alt=media&token=10670863-d0eb-4249-ab20-b705e4558171",
    textWidth: "78px",
  },
  {
    id: 1,
    number: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-number-2.png?alt=media&token=84005dfb-cb04-4b5e-953c-dead3f9f62c9",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-2.png?alt=media&token=2d645388-71d1-4d73-ac6b-81969ce75a73",
    text: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-text-2.png?alt=media&token=1bd169cb-a2ac-42a4-a0f1-61a397ea522c",
    textWidth: "104px",
  },
  {
    id: 2,
    number: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-number-3.png?alt=media&token=2fd2c4bf-fd6b-4a3a-8fda-686ca7340607",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-3.png?alt=media&token=981eee6d-93ae-4f16-9945-424deecca29f",
    text: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-text-3.png?alt=media&token=3a25887f-890a-47a0-8913-acb595967a3d",
    textWidth: "137px",
  },
  {
    id: 3,
    number: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-number-4.png?alt=media&token=1a7d0541-5e94-4e16-9e8e-f8f5440642e1",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-4.png?alt=media&token=235a9942-d729-41e3-b97c-c345e9c5c3be",
    text: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-text-4.png?alt=media&token=fd657753-ecda-457c-b73d-7161f050e096",
    textWidth: "149px",
  },
  {
    id: 4,
    number: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-number-5.png?alt=media&token=949b5c2c-82ea-4d06-8234-65739a4266c6",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-image-5.png?alt=media&token=f8cd107b-f373-48c3-a892-8ba55e1642f1",
    text: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fslide-process-text-5.png?alt=media&token=b5d1b47d-c659-4faa-8936-efee9e48ab6e",
    textWidth: "112px",
  },
];

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #C4C4C4;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    background: #686766;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const Card = styled.div`
  padding-top: 26px;
  padding-bottom: 25px;
  .process-image {
    width: 130px;
    margin: 0 auto 27px;
  }
  .process-text {
    margin: 0 auto;
  }
  .process-number {
    margin: 0 auto 25px;
    width: 26px;
  }
`;

export const SliderProcess = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowLeft
        width={40}
        className={className}
        style={{ ...style, display: 'block', color: '#1C3245' }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowRight
        className={className}
        style={{ ...style, display: 'block', color: '#1C3245' }}
        onClick={onClick}
      />
    );
  }

  return (
    <StyledSlider
      dots={true}
      infinite={true}
      slidesToShow={
        screenWidth > 1199
          ? 3
          : 2
      }
      slidesToScroll={
        screenWidth > 1199
          ? 1
          : 1
      }
      autoplay={true}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
    >
      {Processes.map((process, index) => (
        <Card>
          <img alt={`numero-${index}`} src={process.number} className="process-number" />
          <img alt={`imagen-${index}`} src={process.image} className="process-image" />
          <img alt={`texto-${index}`} src={process.text} style={{width: process.textWidth}} className="process-text" />
        </Card>
      ))}
    </StyledSlider>
  );
};
