import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './components/Home/Home';
import { FAQ } from './components/FAQ/FAQ';
import { Contact } from './components/Contact/Contact';
import { NavBar } from './components/navbar/navbar.jsx';
import 'antd/dist/antd.css';
import { Profile } from './components/Profile/Profile';
import { Footer } from './components/Footer/footer';
import { StudyDetail } from './components/Study/StudyDetail';
import { AxionaSolid } from './components/AxionaSolid/AxionaSolid';
import { Report } from './components/Report/Report';
import { Patients } from './components/Patients/Patients';
import { Doctors } from './components/Doctors/Doctors';
import { AboutUs } from './components/AboutUs/AboutUs';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScrollToTop from './Router/scrollToTop';
import { AxionaGene } from './components/AxionaGene/AxionaGene';
import { AxionaHem } from './components/AxionaHem/AxionaHem';
import { ContactLiquid } from './components/Contact/ContactLiquid';
import { AxionaLiquid } from './components/AxionaLiquid/AxionaLiquid';


function App() {
  useEffect(()=>{
		AOS.init({ duration: 2000 });
		AOS.refresh();
	},[])
  return (
    <div className="App">
        <Router>
          <NavBar/>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route exact path="/axionasolid">
              <AxionaSolid/>
            </Route>
            <Route exact path="/pacientes">
              <Patients />
            </Route>
            <Route exact path="/axionagene">
              <AxionaGene/>
            </Route>
            <Route exact path="/axionaheme">
              <AxionaHem/>
            </Route>
            <Route exact path="/axionaliquid">
              <AxionaLiquid/>
            </Route>
            <Route exact path="/contact-liquid">
              <ContactLiquid/>
            </Route>
            {/* <Route exact path="/report">
              <Report />
            </Route>
            </Route>
            <Route exact path="/aboutus">
              <AboutUs />
            </Route>
            <Route exact path="/FAQ">
              <FAQ/>
            </Route>
            <Route exact path="/contact">
              <Contact/>
            </Route>
            <Route exact path="/profile">
              <Profile/>
            </Route> */}
            <Route exact path="/medicos">
              <Doctors />
            </Route>
            <Route path='/study/:id'>
              <StudyDetail/>
            </Route>
          </Switch>

          <Footer/>
      </Router>
      {() => {AOS.refresh()}}
    </div>
  );
}

export default App;
