import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { ContactBorder } from '../Contact/contactBorder';
import { ContactYellow } from '../Contact/contactYellow';

const Background = styled.div`
  background: #F2F7F7;
  text-align: left;
  font-family: 'IBM Plex Sans';
  @media only screen and (min-width: 992px) {
    padding-bottom: 49px;
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #EAAC34;
    margin-top: 45px;
    margin-bottom: 24px;
    span {
      font-style: italic;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 40px;
      margin-bottom: 28px;
      font-size: 30px;
      line-height: 39px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #1C3245;
    max-width: 422px;
    margin-bottom: 24px;
    @media only screen and (max-width: 992px) {
      font-size: 17px;
      line-height: 26px;
    }
  }
  p {
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: #353433;
    max-width: 454px;
  }
  span {
    font-weight: 700;
  }
  img {
    width: 100%;
    max-width: 640px;
  }
  #imgMb {
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }
  #imgDesk {
    position: absolute;
    bottom: 0px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  #contactDesk {
    margin-top: 91px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  #contactMb {
    margin-top: 60px;
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }
`;

export const TumorBoard = () => {
  return(
    <Background>
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <h1>¿Qué es un <span>Tumor Board</span> ?</h1>
            <h2>Es la revisión de casos con un equipo multidisciplinario de profesionales de la salud...</h2>
            <img id="imgMb" alt='' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2FtumorBoard.png?alt=media&token=a57eba31-8302-43a7-823d-28fdc2b34d7c'/>
            <p>En la que participan <span>patólogos, oncólogos, biólogos moleculares, médicos generales, genetistas,</span> entre otros.</p>
            <p>Se discuten aspectos relacionados al <span>manejo personalizado del caso,</span> analizando la parte clínica, patológica y oncológica en donde <span>los expertos comparten diferentes puntos de vista buscando mejorar el diagnóstico y tratamiento del paciente.</span></p>
          </Col>
          <Col xs={12} lg={7} style={{position: "relative"}}>
            <img id="imgDesk" alt='' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2FtumorBoard.png?alt=media&token=a57eba31-8302-43a7-823d-28fdc2b34d7c'/>
          </Col>
        </Row>
        <div id="contactDesk">
          <ContactBorder/>
        </div>
      </Container>
      <div id="contactMb">
        <ContactYellow/>
      </div>
    </Background>
  );
}