import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const BlueSection = styled.div`
  width: 100%;
  height: auto;
  padding: 3rem 2rem 3rem 2rem;
  background-color: #E2F0F7;
  .info {
    padding-left: 4%;
  }
  .image {
    padding-right: 6%;
  }
  @media only screen and (max-width: 768px){
    .info {
      padding-left: 0;
    }
    .image {
      padding: 0;
    }
  }
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 2rem;
  color: #1378BC;
  text-align: center;
  @media only screen and (max-width: 768px){
    font-size: 28px;
    text-align: left;
  }
`;

const Text = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  margin: 0;
  color: #434444;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    text-align: left;
  }
`;

export const AboutAxiona = () => {
  return(
    <BlueSection className='pt-5 pb-5'>
      <Container>
        <Title>¿Qué es Axiona?</Title>
        <Row className='justify-content-center'>
          <Col xs={12} sm={11} md={10} lg={7} xl={7}>
            <Text>
              Axiona® SOLID analiza 50 genes con alteraciones 
              genéticas asociadas a terapias dirigidas, dando 
              al médico un panorama robusto para el tratamiento 
              de su paciente, al mismo tiempo que accesible y 
              de manera rápida.
            </Text>
          </Col>
        </Row>
      </Container>
    </BlueSection>
  );
}
