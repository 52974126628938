import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FBannerDesk.png?alt=media&token=37464341-421d-4632-94bb-569da8b94a79');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FBannerMobile.png?alt=media&token=1aca9a74-686a-4eab-a044-41a4b691163d');
    height: 490px;
  }
  .row {
    width: 100%;
    align-items: center;
  }
  #col-img {
    height: 400px;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 350px;
    }
    img {
      width: 680px;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 992px) {
        width: 430px;
      }
      @media only screen and (max-width: 768px) {
        transform: translate(-50%, 0%);
        width: 400px;
        bottom: 110px;
      }
    }
  }
`;

const ContentSection = styled(Col)`
  padding-left: 50px;
  padding-top: 200px;
  padding-bottom: 126px;
  @media only screen and (max-width: 992px) {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px; 
  }
  @media only screen and (max-width: 366px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const TitleRow = styled(Row)`
  margin-bottom: 18px;
`;

const Title = styled.h1`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  color: #EAAC34;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const DescRow = styled(Row)`
  margin: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #FFFFFF;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 19px;
    line-height: 25px;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <BannerBackground>
      <Container>
        <Row>
          <ContentSection xs={{span: 12, order: 1}} md={{span: 5, order:2}} lg={{span:6, order:2}}>
            <TitleRow>
              <Title>
                Siempre un{' '}
                {isMobile && width > 310 && (
                  <br/>
                )}
                paso adelante
              </Title>
            </TitleRow>
            <DescRow>
              <Desc>
                Un nuevo horizonte para usted{' '}
                {isMobile && width > 310 && (
                  <br/>
                )}
                y su familia
              </Desc>
            </DescRow>
          </ContentSection>
          <Col
            xs={{span:12, order: 2}}
            md={{span:7, order:1}}
            lg={{span:6, order: 1}}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FsolidPerson.png?alt=media&token=6938b851-1ffe-4613-99d9-ae2f6d2038f2'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
}