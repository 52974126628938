import React from "react";
import { SolidBanner } from "./banner";
import { AboutSolid } from "./aboutSolid";
import { SolidSlider } from './solidSlider';
import { Genes } from './genes';
import { InfoSolid } from './infoSolid';
import { VideoSolid } from "./videoSolid";
import { Helmet } from "react-helmet";

export const AxionaSolid = () => {
  return(
    <>
    <Helmet>
            <title>Axiona® SOLID</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <SolidBanner />
      <VideoSolid />
      <AboutSolid />
      <SolidSlider />
      <Genes />
      <InfoSolid />
    </>
  );
}