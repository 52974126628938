import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import LabImg from '../../assets/img/report/lab.png';
import { useTranslation } from 'react-i18next';
import { Documents } from './documents';
import { MobileDocs } from './mobileDocs';

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 2rem;
  color: #434444;
  @media only screen and (max-width: 768px){
    font-size: 30px;
  }
`;

const Text = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: left;
  margin: 0;
  color: #434444;
`;

const DownloadBtn = styled.a`
  padding: .5rem 3rem .5rem 3rem;
  border-radius: 8rem;
  width: 16%;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
  margin: 0;
  background-color: #EBAB34;
  color: #434444;
`;

const BlueSection = styled.div`
  width: 100%;
  height: auto;
  padding: 3rem 2rem 3rem 2rem;
  background-color: #E2F0F7;
  .info {
    padding-left: 4%;
  }
  .image {
    padding-right: 6%;
  }
  @media only screen and (max-width: 768px){
    .info {
      padding-left: 0;
    }
    .image {
      padding: 0;
    }
  }
`;

const InfoButton = styled.a`
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8rem;
  width: 60%;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
  margin-left: 4%;
  background-color: ${props => props.bg === 'blue' ? '#1379BC' : '#EBAB34'};
  color: ${props => props.bg === 'blue' ? '#FFFFFF' : '#434444'};
  @media only screen and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
  @media only screen and (max-width: 576px) {
    width: 70%;
  }
`;

export const Info = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation("global");

  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
         window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <>
      <Container className='mt-5 p-5 pb-0 p-sm-5 p-md-0 p-lg-0 p-xl-0'>
        <Row className='justify-content-center mb-5'>
          <Col xl={9}>
            <Title>{t('report.info.title')}</Title>
            <Text data-aos='fade-up'>
              {t('report.info.about-axiona')}
            </Text>
          </Col>
        </Row>
      </Container>
      {isMobile ? (
        <MobileDocs />
      ) : (
        <>
          <div className='d-flex flex-row flex-wrap justify-content-center mb-5' data-aos='fade-up'>
            <Col xl={7}>
              <Text className='text-center'>
                <b>
                  {t('report.info.document-info')}
                </b>
              </Text>
            </Col>
          </div>
          <Documents />
        </>
      )}
      <div className='d-flex flex-row flex-wrap justify-content-center mb-5'>
        <Col xl={7}>
          <Text className='text-center mb-4'>
            <b>{t('report.info.download-example')}</b>
          </Text>
          <DownloadBtn>{t('report.info.download-btn')}</DownloadBtn>
        </Col>
      </div>
      <BlueSection data-aos='fade-up'>
        <Title className='mb-5'>{t('report.info.more-about-axiona')}</Title>
        <Container>
          <Row className='justify-content-end info'>
            <Col xl={4} className='mb-4' data-aos='fade-up'>
              <Row className='mb-4'>
                <Text>
                  {t('report.info.doctor-part-one')}
                  <b>{t('report.info.doctor-part-two')}</b> 
                  {t('report.info.doctor-part-three')}
                </Text>
              </Row>
              <Row className='mb-5'>
                <InfoButton bg='blue'>{t('report.info.more-info-btn')}</InfoButton>
              </Row>
              <Row className='mb-4'>
                <Text>
                  {t('report.info.patient-part-one')}
                  <b>{t('report.info.patient-part-two')}</b> 
                  {t('report.info.patient-part-three')}
                </Text>
              </Row>
              <Row className='mb-5'>
                <InfoButton bg='orange'>{t('report.info.more-info-btn')}</InfoButton>
              </Row>
            </Col>
            <Col xl={7} className='image' data-aos='fade-up'>
              <img src={LabImg} alt="Lab image" className='img-fluid w-100' />
            </Col>
          </Row>
        </Container>
      </BlueSection>
    </>
  );
}