import React from 'react';
import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BackgroundSection = styled.div`
  background-color: #F2F7F7;
  padding-top: 3%;
  padding-bottom: 3%;
  @media only screen and (max-width: 768px) {
    padding-top: 7%;
    padding-bottom: 7%;
  }
`;

const Description = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #1C3245;
  text-align: left;
  margin: 0;
`;

export const AboutAxiona = () => {
  const [t, i18n] = useTranslation("global");
  return(
    <BackgroundSection>
      <Container>
        <Row>
          <Description>
            <b>
              {t('home.about.start')}
            </b>
            {t('home.about.desc')}
          </Description>
        </Row>
      </Container>
    </BackgroundSection>
  );
}