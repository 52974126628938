import React from "react";
import { ContactLiq } from "./ContactLiq";
import { Helmet } from "react-helmet";

export const ContactLiquid = () => {
    return (
      <>
      <Helmet>
            <title>Contacto - Axiona® LIQUID</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
        <ContactLiq/>
      </>
    );
  };
  