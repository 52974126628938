import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled, { keyframes } from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { useEffect } from 'react';

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 2%;
  right: 2%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 300px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px;
  background: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  // z-index: 10;
  .header {
    background: #1379bc;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0 5px;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .header-row {
    background: rgba(19, 121, 188, 0.15);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #eaac34;
  }
  .black-text,
  .yellow-text-bold,
  .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold,
  .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #eaac34;
  }
  .blue-text-bold {
    color: #1379bc !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
  .handler {
    color: #eaac34;
    font-style: italic;
    transition: 300ms;
    :focus, :hover {
      color: #1379bc;
      text-decoration: underline;
      transition: 300ms;
    }
  }
`;

const Icon = styled.img`
  margin-top: 8px;
  max-width: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  max-height: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  width: auto;
  padding: 0;
`;

const DropdownDiv = styled.div`
  position: absolute;
  width: 83%;
  margin-left: 16.66%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 60px 26px 50px;
  z-index: 10;
  h1,
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #353433;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 4px;
    div {
      height: 104px;
      padding: 4px 10px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #1379bc;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 165px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379bc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
      @media only screen and (max-width: 992px) {
        min-height: 220px !important;
      }
    }
  }
`;

const animate = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const AnimatedItem = styled.div`
  animation: ${animate} 2s infinite;
  text-decoration: underline;
`;

export const AlterationsModalContent = (props) => {
  const [t, i18n] = useTranslation('global');
  const [alelo, setAlelo] = useState(false);
  const [transcripto, setTranscripto] = useState(false);
  const [variant, setVariant] = useState(false);
  const [clinvar, setClinvar] = useState(false);
  const [funcion, setFuncion] = useState(false);

  const IconsMean = [
    {
      alt: 'incierto',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FInciertoIcon.png?alt=media&token=ee946822-0e62-47e5-8d2e-f1445104792f',
      title: '*Variante de significado incierto: ',
      desc: 'Cambio en la secuencia de ADN de un gen cuyo efecto en la salud no se conoce en el momento de su clasificación.',
    },
    {
      alt: 'patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92',
      title: '*Variante patogénica: ',
      desc: 'Alteración genética que aumenta la susceptibilidad o predisposición a padecer cáncer hereditario.',
    },
    {
      alt: 'probabilidad patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FProbPatogenicaIcon.png?alt=media&token=44ba50ad-1448-41f0-abfe-ab4c1739c38c',
      title: '*Variante probablemente patogénica: ',
      desc: 'Alteración genética que probablemente sea responsable de causar cáncer hereditario, pero no hay suficiente evidencia científica para aseverarlo.',
    },
    {
      alt: 'interpretaciones contradictorias',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FContradictoriasIcon.png?alt=media&token=27c28d1e-a615-488f-b0f7-286711207aa7',
      title: '*Interpretaciones contradictorias de la patogenicidad: ',
      desc: 'Definición asignada por ClinVar a las variantes con resultados contradictorios que no pueden ser clasificadas usando los términos recomendados por la ACMG/AMP para describir la patogenicidad y aquellos recomendados por ClinGen para clasificar los alelos de riesgo y variantes mendelianas de baja penetrancia.',
    },
  ];

  return (
    <>
      <StyledRow className='pt-4 ps-4 pe-4 pb-0 mb-4'>
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
        <Col xs={4} sm={4} md={5} className='ps-0'>
          <TitleContainer>
            <StyledModalTitle className='pt-3 pb-3'>
              {t('hem.modals.alterations.title')}
            </StyledModalTitle>
          </TitleContainer>
        </Col>
        <Col xs={7} sm={7} md={7} lg={7} xl={7} className='pe-0'>
          <Row className='mb-4 pe-3'>
            <StyledModalDesc>
              {t('hem.modals.alterations.content.normal.first')}
            </StyledModalDesc>
          </Row>
          <Row>
            <ExampleText>
              {t('hem.modals.alterations.content.exampleText')}
            </ExampleText>
          </Row>
        </Col>
      </StyledRow>
      <StyledRowExample className='pb-2'>
        <Col xs={12} className='header'>
          {t('hem.modals.alterations.title')}
        </Col>
        <Col xs={12} className='header-col p-0' style={{ marginBottom: '6px' }}>
          <div className='justify-content-start ps-4 pe-4'>Variantes de la secuencia del ADN</div>
        </Col>
        <Col xs={4} className='ps-0 pe-0'>
          <Row>
            <Col xs={3} className='ps-0 pe-2'>
              <div>Gen</div>
            </Col>
            <Col xs={4} className='ps-0 pe-2'>
              <div>Cambios de aminoácidos</div>
            </Col>
            <Col xs={5} className='pe-2 ps-0'>
              <div>Codificación</div>
            </Col>
          </Row>
        </Col>
        <Col xs={8} className='right flex-column pe-0'>
          <Row className='justify-content-between' id='handlers'>
            <Col xs={2} className='pe-2 ps-2'>
              <AnimatedItem
                className='handler botoncito w-auto'
                id='alelo'
                tabindex='1'
                onClick={() => {
                  setFuncion(false);
                  setAlelo(true);
                  setTranscripto(false);
                  setVariant(false);
                  setClinvar(false);
                }}
              >
                Frecuencia de alelo
              </AnimatedItem>
            </Col>
            <Col xs={2} className='pe-2 ps-2'>
              <AnimatedItem
                className='handler botoncito w-auto'
                tabindex='2'
                onClick={() => {
                  setFuncion(false);
                  setAlelo(false);
                  setTranscripto(true);
                  setVariant(false);
                  setClinvar(false);
                }}

              >                Transcripto
              </AnimatedItem>
            </Col>
            <Col xs={2} className='pe-2 ps-2'>
              <AnimatedItem
                className='handler botoncito w-auto'
                tabindex='3'
                onClick={() => {
                  setFuncion(false);
                  setAlelo(false);
                  setTranscripto(false);
                  setVariant(true);
                  setClinvar(false);
                }}

              >
                Efecto de la variante
              </AnimatedItem>            </Col>
            <Col xs={2} className='pe-2 ps-2'>
              <AnimatedItem
                className='handler botoncito w-auto'
                tabindex='4'
                onClick={() => {
                  setClinvar(true);
                  setFuncion(false);
                  setAlelo(false);
                  setTranscripto(false);
                  setVariant(false);
                }}

              >
                ClinVar
              </AnimatedItem>
            </Col>
            <Col xs={2} className='pe-2 ps-2'>              <AnimatedItem
                className='handler botoncito w-auto'
                tabindex='5'
                onClick={() => {
                  setFuncion(true);
                  setAlelo(false);
                  setTranscripto(false);
                  setVariant(false);
                  setClinvar(false);
                }}

              >
                Efecto en la función
              </AnimatedItem>
            </Col>
          </Row>
        </Col>
        <div className='p-0 mt-2 mb-2'>          <hr className='m-0' />
        </div>
        <Col xs={12} className='right p-0 pb-2'>
          <Col xs={4} className='ps-0 pe-0'>
            <Row>
              <Col xs={3} className='ps-0 pe-2'>
                <div>FLT3</div>
              </Col>
              <Col xs={4} className='ps-0 pe-2'>
                <div>p.(D835Y)</div>
              </Col>
              <Col xs={4} className='pe-2 ps-0'>
                <div>{'c.2503G>T'}</div>
              </Col>
            </Row>
          </Col>
          <Col xs={8} className='right flex-column pe-0'>
            <Row className='justify-content-between'>
              <Col xs={2} className='pe-2 ps-2'>
                <div>47.30%</div>
              </Col>
              <Col xs={2} className='pe-2 ps-2'>
                <div>NM_004119.3</div>
              </Col>
              <Col xs={2} className='pe-2 ps-2'>
                <div>Cambio de sentido</div>
              </Col>
              <Col xs={2} className='pe-2 ps-2'>
                <div>Pathogenic</div>
              </Col>
              <Col xs={2} className='pe-2 ps-2'>
                <div>
                  Ganancia
                  <br />
                  de función
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={3} className='info-col black-text position-relative'>
            Significado incierto &nbsp;
            <Icon
              src={IconsMean[0].icon}
              alt={`Icono ${IconsMean[0].alt}`}
              lg={0}
            />
          </Col> */}
        </Col>
      </StyledRowExample>
      <div className='position-relative'>
        <StyledRowDown className='mb-3'>
          <Col xs={4} className='ps-0 pe-0'>
            <Row>
              <Col xs={3} className='text-col p-0 pe-1'>
                <div>Nombre del gen que se encontró alterado.</div>
              </Col>
              <Col xs={4} className='text-col p-0 pe-1'>
                <div>Sitio de cambio a nivel proteína.</div>
              </Col>
              <Col xs={5} className='text-col pe-0 ps-0'>
                <div>
                  Cambio o alteración de la(s) variante(s) dentro del gen a
                  nivel del ADN codificante.
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={8} className='right justify-content-start'>
            <Col xs={12} className='text-col pe-0 ps-2'>
              <div>{alelo ? (
                'Se refiere a la métrica para describir que tan común es la presencia de un alelo (un alelo es la manifestación de una versión de un gen) con respecto a una población determinada.'
              ) : transcripto ? (
                'La nomenclatura NM_004119.3 NM=Migración de Neutrófilos que está en un locus.'
              ) : variant ? (
                'Es el cambio en la secuencia de ADN que tiene el gen, dónde existen diversos tipos de mutaciones.'
              ) : clinvar ? (
                'Se refiere al tipo de altercación encontrada de acuerdo al archivo público de acceso libre de NCBI.'
              ) : funcion ? (
                'Se describe cual es el resultado en la función de la proteína a partir del cambio de aminoácidos que ocurrió.'
              ) : ''}</div>
            </Col>
          </Col>
        </StyledRowDown>
        <StyledRowExample className='pb-3'>
          <Col xs={12} className='header-col p-0' style={{ marginBottom: '6px' }}>
            <div className='justify-content-start ps-4 pe-4'>Variantes de la secuencia del ADN</div>
          </Col>
          <Col xs={3} className='ps-0 pe-2' style={{fontWeight: '600'}}>
            <div>
              Genes
            </div>
          </Col>
          <Col xs={3} className='ps-0 pe-2' style={{fontWeight: '600'}}>
            <div>
              ID de la variante
            </div>
          </Col>
          <Col xs={4} className='ps-0 pe-2' style={{fontWeight: '600'}}>
            <div>
              Locus
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-2' style={{fontWeight: '600'}}>
            <div>
              Efecto en la función
            </div>
          </Col>
          <div className='p-0'>
            <hr className='m-0 mt-2 mb-2' />
          </div>
          <Row className='p-0'>
            <Col xs={3} className='ps-0 pe-2'>
              <div>
                CBFB-MYH11
              </div>
            </Col>
            <Col xs={3} className='ps-0 pe-2'>
              <div>
                CBFB-MYH11.C5M33
              </div>
            </Col>
            <Col xs={4} className='ps-0 pe-2'>
              <div>
                chr16:67116211 - chr16:15814908
              </div>
            </Col>
          </Row>
        </StyledRowExample>
        <StyledRowDown className='p-0 mb-4'>
            <Col xs={3} className='ps-0 pe-2'>
            </Col>
            <Col xs={3} className='ps-0 pe-2 text-col'>
              <div>
                Funciona para tener específicamente el código de tema que apunten a variantes de productos específicas.
              </div>
            </Col>
            <Col xs={4} className='ps-0 pe-2 text-col'>
              <div>
                Posición fija en un cromosoma, que determina la posición de un gen o de un marcador.
              </div>
            </Col>
          </StyledRowDown>
        {/* <StyledRowDown
          className='pb-4'
          style={{ position: 'absolute', top: '0', zIndex: '6' }}
        >
          <Col xs={2} />
          <Col xs={10} className='right'>
            <Col xs={3} />
            <Col xs={3} className='text-col'>
              <div
                style={
                  openThirdCol
                    ? {
                        height: 'auto',
                        alignItems: 'end',
                        justifyContent: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : {}
                }
              >
                {openThirdCol ? (
                  <>
                    <p>
                      Se refiere a la métrica para describir que tan común es la
                      presencia de un alelo (un alelo es la manifestación de una
                      versión de un gen) con respecto a una población
                      determinada.
                    </p>
                    <UilAngleUp
                      size={36}
                      color='#EAAC34'
                      className='botoncito'
                      onClick={() => {
                        setOpenThirdCol(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p>
                      Se refiere a la métrica para describir que tan común es la
                      presencia...
                    </p>
                    <UilAngleDown
                      size={36}
                      color='#EAAC34'
                      className='botoncito'
                      onClick={() => {
                        setOpenFourthCol(false);
                        setOpenThirdCol(true);
                        setOpenSecondCol(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col xs={3} className='text-col'>
              <div
                style={
                  openFourthCol
                    ? {
                        height: 'auto',
                        alignItems: 'end',
                        justifyContent: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : {}
                }
              >
                {openFourthCol ? (
                  <>
                    <p>
                      Tipo de alelo, dicho gen puede ser heterocigoto u
                      homocigoto, si es homocigoto quiere decir que se cuenta
                      con la misma versión de alelo heredado tanto por la madre
                      como del padre biológico; si es heterocigoto, quiere decir
                      que existe diferencia entre la versión del alelo heredado
                      por parte de alguno de los progenitores.
                    </p>
                    <UilAngleUp
                      size={36}
                      color='#EAAC34'
                      className='botoncito'
                      onClick={() => {
                        setOpenFourthCol(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p>
                      Tipo de alelo, dicho gen puede ser heterocigoto u
                      homocigoto, si es...
                    </p>
                    <UilAngleDown
                      size={36}
                      color='#EAAC34'
                      className='botoncito'
                      onClick={() => {
                        setOpenFourthCol(true);
                        setOpenThirdCol(false);
                        setOpenSecondCol(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col xs={3} />
          </Col>
        </StyledRowDown> */}
      </div>
    </>
  );
};
