import React from 'react';
import styled from 'styled-components';
import One from '../../assets/img/report/1.png'
import Two from '../../assets/img/report/2.png'
import Three from '../../assets/img/report/3.png'
import ResultsMobile from '../../assets/img/report/ResultsMobile.png';
import TherapiesMobile from '../../assets/img/report/TherapiesMobile.png';
import TrialsMobile from '../../assets/img/report/TrialsMobile.png';
import ResultsDetails from '../../assets/img/report/results_details.png';
import TherapiesDetails from '../../assets/img/report/therapies_details.png';
import TrialsDetails from '../../assets/img/report/trials_details.png';
import { useTranslation } from 'react-i18next';

const ReportSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0rem 2rem 3rem 2rem;
  justify-content: center;
  .number {
    max-width: 20%;
  }
`;

const DocContainer = styled.div`
  border-radius: 1rem 1rem 1rem 1rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
`;

const Header = styled.div`
  background-color: #317ac7;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 576px) {
    padding: 1rem;
  }
`;


const DocTitle = styled.p`
  font-family: IBM Plex Sans;
  font-size: 30px;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
  color: #FFFFFF;
  @media only screen and (max-width: 576px) {
    font-size: 24px;
  }
`;

const Content = styled.div`
  padding: 1rem;
  width: 100%;
`;

const DescText = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #434444;
  margin: 0;
  text-align: left;
`;

const DetailContainer = styled.div`
max-width: 100%;
overflow-x: scroll;
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05); 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #EBAB34; 
    border-radius: 10px;
  }
  > img {
    max-width: 900px;
  }
  @media only screen and (max-width: 576px) {
    > img {
      max-width: 650px;
    }
  }
`;

export const MobileDocs = () => {
  const [t, i18n] = useTranslation("global");
  return(
    <ReportSection>
      <div className='pb-5 w-100'>
        <img src={One} alt='Numero uno' className='number pb-4' />
        <DocContainer data-aos='fade-up'>
          <Header>
            <DocTitle>{t('report.documents.results.modal.header')}</DocTitle>
          </Header>
          <Content>
            <img src={ResultsMobile} alt='Documento de resultados' className='img-fluid pb-4' />
            <DescText className='mb-5' data-aos='fade-up'>
            {t('report.documents.results.modal.desc')}
            </DescText>
            <DescText className='text-center' style={{fontSize: '16px'}}>
              <b>
                {t('report.documents.example-doc-modal')}
              </b>
            </DescText>
            <DetailContainer className='pt-3 pb-5' data-aos='fade-up'>
              <img src={ResultsDetails} alt='Detalles de resultados' />
            </DetailContainer>
          </Content>
        </DocContainer>
      </div>
      <div className='pb-5 w-100'>
        <img src={Two} alt='Numero dos' className='number pb-4' />
        <DocContainer data-aos='fade-up'>
          <Header>
            <DocTitle>{t('report.documents.therapies.modal.header')}</DocTitle>
          </Header>
          <Content>
            <img src={TherapiesMobile} alt='Documento de terapias' className='img-fluid pb-4' />
            <DescText className='mb-5' data-aos='fade-up'>
              {t('report.documents.therapies.modal.desc')}
            </DescText>
            <DescText className='text-center' style={{fontSize: '16px'}}>
              <b>
                {t('report.documents.example-doc-modal')}
              </b>
            </DescText>
            <DetailContainer className='pt-3 pb-5' data-aos='fade-up'>
              <img src={TherapiesDetails} alt='Detalles de terapias' />
            </DetailContainer>
          </Content>
        </DocContainer>
      </div>
      <div className='pb-2 w-100'>
        <img src={Three} alt='Numero tres' className='number pb-4' />
        <DocContainer data-aos='fade-up'>
          <Header>
            <DocTitle>{t('report.documents.trials.modal.header')}</DocTitle>
          </Header>
          <Content>
            <img src={TrialsMobile} alt='Documento de ensayos' className='img-fluid pb-4' />
            <DescText className='mb-4' data-aos='fade-up'>
              {t('report.documents.trials.modal.desc-part-one')}
              <a style={{color: '#434444'}}
                href='https://www.clinicaltrials.gov/' 
                target='_blank'><u>{t('report.documents.trials.modal.desc-part-two')}</u>
              </a>
            </DescText>
            <DescText className='mb-5' data-aos='fade-up'>
              {t('report.documents.trials.modal.desc-part-three')}
              <u>{t('report.documents.trials.modal.desc-part-four')}</u>
            </DescText>
            <DescText className='text-center' style={{fontSize: '16px'}}>
              <b>
                {t('report.documents.example-doc-modal')}
              </b>
            </DescText>
            <DetailContainer className='pt-3 pb-5' data-aos='fade-up'>
              <img src={TrialsDetails} alt='Detalles de terapias' />
            </DetailContainer>
          </Content>
        </DocContainer>
      </div>
    </ReportSection>
  );
}