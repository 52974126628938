import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useTranslation } from "react-i18next";

const StyledSlider = styled(Slider)`
  .slick-list {
    margin-bottom: 15px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
   color: #1E1E1E;
  }
`;

const Slide = styled.div`
  text-align: left;
`;

const FeatureText = styled.p`
  display: inline;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #1C3245;
  text-align: left;
  width: auto;
  margin: 0;
`;

const FeatureHighlight = styled.p`
  display: inline;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #EAAC34;
  text-align: left;
  width: auto;
  margin: 0;
`;

export const FeaturesSlider = () => {
  const [t, i18n] = useTranslation("global");
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  }
  return(
    <StyledSlider {...settings} className='p-0'>
      <Slide className='pe-3'>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-one.highlight')}
        </FeatureHighlight>
        <FeatureText>
          {t('home.why-axiona.slider.slide-one.text')}
        </FeatureText>
      </Slide>
      <Slide className='pe-3'>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-two.text')}
        </FeatureHighlight>
      </Slide>
      <Slide className='pe-3'>
        <FeatureText>
          {t('home.why-axiona.slider.slide-three.text')}
        </FeatureText>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-three.highlight')}
        </FeatureHighlight>
      </Slide>
      <Slide className='pe-3'>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-four.highlight')}
        </FeatureHighlight>
        <FeatureText>
          {t('home.why-axiona.slider.slide-four.text')}
        </FeatureText>
      </Slide>
      <Slide className='pe-3'>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-five.text')}
        </FeatureHighlight>
      </Slide>
      <Slide>
        <FeatureHighlight>
          {t('home.why-axiona.slider.slide-six.highlight')}
        </FeatureHighlight>
        <FeatureText>
          {t('home.why-axiona.slider.slide-six.text')}
        </FeatureText>
      </Slide>
    </StyledSlider>
  );
}