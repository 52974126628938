import React from "react";
import styled from 'styled-components'
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { UilPlus } from '@iconscout/react-unicons';

const GrayDiv = styled.div`
  background: #F2F7F7;
  padding: 18px 20px;
  @media only screen and (max-width: 768px) {
    padding: 30px 36px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1C3245;
    max-width: 928px;
    margin: 0px auto;
    @media only screen and (max-width: 768px) {
      text-align: left;
      font-size: 17px;
      line-height: 26px;
    }
  }
`;

const MainDiv = styled.div`
  background: #FFFFFF;
  font;
  h1 {
    font-weight: 700;
    text-align: center;
    color: #EAAC34;
    font-size: 40px;
    line-height: 130%;
    max-width: 446px;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
  span {
    font-weight: 700;
  }
  #recursos-right {
    @media only screen and (max-width: 768px) {
      display: none;
    }
    background: #F2F7F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    overflow: hidden;
    width: 90%;
    max-width: 472px;
    margin: 0 auto;
    padding-bottom: 15px;
    p {
      background-color: #EAAC34;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      color: #FFFFFF;
      padding-top: 11px;
      padding-bottom: 7px;
    }
    li {
      text-align: left;
      a {
        text-decoration: underline;
        color: #353433;
      }
    }
    #ver-mas {
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      text-decoration-line: underline;
      color: #EAAC34;
    }
  }
`;

const BlueButton = styled.button`
  background: #1379BC;
  border-radius: 100px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  min-width: 213px;
  padding: 10px 25px 12px;
`;

const ForMe = styled(Row)`
  div img {
    max-width: 300px;
    width: 70%;
    @media only screen and (max-width: 992px) {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  #info {
    margin: 0 auto 86px;
    max-width: 384px;
    padding: 13px 13px 28px;
    background: #F2F7F7;
    border-radius: 20px 20px 0px 20px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
    div {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      width: 228px;
      img{
        width: 40px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    #link-copiado {
      transition: all ease .5s;
      padding: 3px 5px;
      background: #fff;
      box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
      position: absolute;
      right: 0px;
      bottom: -45px;
      display: none;
      z-index: 10;
    }
  }
  h1 {
    width: 75%;
    margin: 74px auto 37px;
    @media only screen and (max-width: 768px) {
      margin: 42px auto 21px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1C3245;
    margin: 0 auto 64px;
    max-width: 448px;
    @media only screen and (max-width: 768px) {
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 31px;
    }
  }
  #col-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Doubts = styled(Row)`
  border: 2px solid #EAAC34;
  border-radius: 40px;
  padding: 7px 53px 0px 0px;
  text-align: left;
  padding: 7px 53px 0px 0px;
  @media only screen and (max-width: 992px) {
    padding: 31px 29px 0px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  div > #linea-labopat {
    position: relative;
  }
  div #linea-labopat {
    positiion: absolute;
    bottom: 0px;
    max-width: 267px;
    width: 100%;
  }
  #phone {
    width: 26px;
    position: absolute;
    top: 8px;
    left: -37px;
    @media only screen and (max-width: 992px) {
      top: -30px;
      left: 0px;
    }
  }
  h5 {
    margin-right: auto;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #1379BC;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #EAAC34;
    position: relative;
    @media only screen and (max-width: 992px) {
      margin-top: 30px;
    }
  }
  p {
    max-width: 320px;
    margin-right: auto;
  }
  button {
    margin-right: auto;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      margin-bottom: 25px;
    }
    @media only screen and (max-width: 992px) {
      margin: 26px auto;
    }
  }
`;

const Terapies = styled(Row)`
  padding: 69px 0 81px;
  text-align: left;
  h1 {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #1C3245;
    margin-bottom: 22px;
  }
  img {
    width: 100%;
    max-width: 430px;
    @media only screen and (max-width: 768px) {
      margin-top: 39px;
    }
  }
  p {
    max-width: 450px;
    margin-bottom: 58px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 27px;
    }
  }
  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Resources = styled.div`
  width: 100%;
  position: relative;
  background: #F2F7F7;
  padding-top: 132px;
  #fondo-img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 84px;
  }
  h1 {
    margin: 0 auto 16px;
    @media only screen and (max-width: 768px) {
      margin: 0 auto 26px 28px;
      text-align: left;
    }
  }
  h6 {
    max-width: 576px;
    margin: 0 auto;
    margin-bottom: 49px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1C3245;
    @media only screen and (max-width: 768px) {
      text-align: left;
      margin-left: 36px;
      margin-right: 36px;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  width: 90%;
  max-width: 384px;
  margin: 0 auto;
  --bs-accordion-border-color: none;
  .accordion-header button{
    color: #353433;
    padding: 13px 25px 13px 20px;
    gap: 15px;
    height: 60.75px;
    background: #F2F7F7;
    border-radius: 20px 20px 0px 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 47px;
    .plus-icon {
      position: absolute;
      right: 25px;
      top: 18px;
    }
    p {
      margin-bottom: 0px;
    }
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-button:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    color: #353433;
  }
  .accordion-body {
    padding-top: 0px;
    padding-left: 48px;
    padding-right: 56px;
    margin-bottom: 20px;
    background: #F2F7F7;
    color: #1379BC;
    text-align: left;
    @media only screen and (max-width: 768px) {
      padding-left: 31px;
      padding-right: 29px;
    }
  }
  .accordion-item {
    background: #F2F7F7;
  }
`;


export const Axiona = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText("axionagenetics.com");
    let text = document.getElementById("link-copiado");
    text.setAttribute("style", "display:block;");
    setTimeout(()=>{
      text.setAttribute("style", "display:none !important;");
    }, 2000);
  }

  return(
    <>
      <MainDiv>
        <GrayDiv>
          <p>Axiona® proporciona información valiosa para generar un plan de acción de manejo clínico del paciente y sus familiares.</p>
        </GrayDiv>

        <Container>

          <ForMe>
            <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}} id="col-img">
              <img alt="doctor-paciente" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2FblueGuys.png?alt=media&token=14644d9d-9cf1-422d-b7a7-a16a3b37f8b3"/>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}}>
              <h1>¿Cómo puedo saber si Axiona® es para mi?</h1>
              <h2>Hable con su médico sobre los beneficios de la secuenciación tumoral y las terapias dirigidas.</h2>
              <div id="info">
                Comparta esta información con su médico
                <div>
                  <a target='_blank' href="https://wa.me/5215519694584?text=Conoce%20Axiona%20axionagenetics.com" rel='noreferrer noopener'>
                    <img alt="whatsapp" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Fwhats.png?alt=media&token=fbcbd2db-1587-4978-8d32-917efe640bac"/>
                  </a>
                  <a href="mailto: ?subject=Conoce%20Axiona&body=axionagenetics.com">
                    <img alt="mail" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Femail.png?alt=media&token=065416ad-1019-4c38-926c-b0955c41d82b"/>
                  </a>
                  <img onClick={() => copyToClipboard()}
                    alt="copy-link" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Fcopy%20link.png?alt=media&token=4809ebae-9ac9-486f-8cdb-d3d4d4c172a4"/>
                  <p id="link-copiado">Link copiado</p>
                </div>
              </div>
            </Col>
          </ForMe>

          <Doubts>
            <Col xs={{span: 12, order: 3}} lg={{span: 3, order: 1}}>
              <img id="linea-labopat" alt="linea-labopat" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2FlineaAxiona.png?alt=media&token=33d7cd20-be52-4bd1-a00a-960edd53f2dc"/>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 2}}>
              <h5>¿Tienes dudas?</h5>
              <p><span>Nuestro equipo está capacitado</span> para responder cualquier duda y darle la asistencia necesaria.</p>
            </Col>
            <Col xs={{span: 12, order: 2}} lg={{span: 5, order: 3}}>
              <h3><img id="phone" alt="phone" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Fphone.png?alt=media&token=5425d569-f710-4c43-84ec-872cf1eaf977"/>
              Llame al +52 55 19 69 4584 o escríbanos por Whatsapp</h3>
              <BlueButton><a target='_blank' href='https://wa.me/5215519694584' rel='noreferrer noopener' style={{color: "#fff"}}>Iniciar conversación</a></BlueButton>
            </Col>
          </Doubts>

          <Terapies>
            <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
              <img alt="pastillas-terapia" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Fpill.png?alt=media&token=8e1d912e-a00e-4106-8961-603b3b8a445d"/>
            </Col>
            <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}}>
              <h1>Terapias dirigidas</h1>
              <h4>La medicina de precisión</h4>
              <p>Las terapias dirigidas o terapias blanco, <span>son tratamientos contra el cáncer que actúan sólo en las alteraciones genéticas de las células cancerosas o malignas,</span> que no se encuentran en las células sanas.
              <br />
              <br />
              A través de las pruebas genéticas de Axiona® es más sencillo identificar estas mutaciones, ayudando a desarrollar un plan de acción de tratamiento personalizado para su paciente.</p>
              <BlueButton onClick={()=> window.location.href = "/#contacto"}>Me interesa</BlueButton>
            </Col> 
          </Terapies>

        </Container>
        
        <Resources>
          <img id="fondo-img" alt="fondo-img" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2FbgForma.png?alt=media&token=b0a3ae54-ca7e-4fb3-a03e-5bd9d36e5074"/>
          <Container>
            <h1>Recursos</h1>
            <h6>Conceptos y herramientas disponibles para nuestros pacientes.</h6>
            <Row>    
              <Col xs={12} lg={5}>
                <StyledAccordion defaultActiveKey="">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><p>¿Qué es un <span>gen</span>?</p><UilPlus color="#EAAC34" size={25} className="plus-icon"/></Accordion.Header>
                    <Accordion.Body>
                      Es un <span>segmento de ADN donde se almacena la información genética</span> importante para el funcionamiento del cuerpo humano. A través del ADN se instruye a las células sobre cómo desarrollarse correctamente, esta información genética se hereda de generación en generación.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header><p>¿Qué es una <span>mutación</span>?</p><UilPlus color="#EAAC34" size={25} className="plus-icon"/></Accordion.Header>
                    <Accordion.Body>
                      Los cambios en la secuencia del ADN se les conoce como mutaciones o alteraciones; éstas pueden ocurrir en genes importantes, <span>alterando su correcto funcionamiento y causando diversas enfermedades,</span> entre ellas el cáncer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header><p>¿Qué es la <span>secuenciación genética</span>?</p><UilPlus color="#EAAC34" size={25} className="plus-icon"/></Accordion.Header>
                    <Accordion.Body>
                      Se refiere a la técnica de laboratorio empleada para <span>encontrar mutaciones dentro del ADN.</span> En los últimos años se han desarrollado nuevas metodologías de secuenciación, la más nueva es la Secuenciación de Nueva Generación (NGS), capaz de generar de forma masiva millones de fragmentos de ADN en un único proceso de secuenciación, mejorando el costo y los tiempos para la obtención de resultados.
                    </Accordion.Body>
                  </Accordion.Item>
                </StyledAccordion>
              </Col>
              <Col xs={12} lg={7}>
                <div id="recursos-right">
                  <p>Recursos y formularios descargables</p>
                  <ul>
                    <li><a 
                      target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2FGlosario_axiona%20(2).pdf?alt=media&token=76385b4a-a471-42e4-a38f-b33b1992db69'
                      rel='noreferrer noopener'
                    >Glosario de palabras clave</a></li>
                    <li><a 
                      target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fpdf%2FREPORTE%20AXIONA%20SOLID%20EJEMPLO.pdf?alt=media&token=2c64bb47-b69a-419d-bc22-f5006b88545d'
                      rel='noreferrer noopener'
                    >Ejemplo de Reporte de resultados Axiona® SOLID</a></li>
                    <li><a 
                      target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fpdf%2FREPORTE%20DE%20RESULTADO%20AXIONA%20GENE%20EJEMPLO.pdf?alt=media&token=0b06859c-992b-4392-a82d-c55314303bac'
                      rel='noreferrer noopener'
                    >Ejemplo de Reporte de resultados Axiona® GENE</a></li>
                    <li><a 
                      target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fpdf%2FREPORTE%20EJEMPLO%20AXIONA%20HEM.pdf?alt=media&token=2dcdeb17-151f-4277-93e3-8726503cb7e0'
                      rel='noreferrer noopener'
                    >Ejemplo de Reporte de resultados Axiona® HEME</a></li>
                    <li><a 
                      target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fpdf%2FREPORTE%20AXIONA%20LIQUID%20EJEMPLO.pdf?alt=media&token=21f6129e-a8e0-44e0-92da-f8d9a8c09aa2'
                      rel='noreferrer noopener'
                    >Ejemplo de Reporte de resultados Axiona® LIQUID</a></li>
                  </ul>
                  {/* <a id="ver-mas" href="#">Ver más</a> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Resources>

      </MainDiv>
    </>    
  );
}