import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import BannerImg from '../../assets/backgrounds/patients/banner.webp';
import BannerMobile from '../../assets/backgrounds/patients/bannermobile.webp';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2Fbg%20banner%20pac%201.png?alt=media&token=ea98cf1e-90ab-47fc-9188-a3269c10fa8f") center no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2Fbg%20banner%20pac%201%20mb.png?alt=media&token=10038fef-591a-4d42-9d7d-49bfbfba7f24" center no-repeat);
    height: 400px;
  }
  .row {
    width: 100%;
    align-items: center;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 350px;
    }
    img {
      width: 940px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 992px) {
        width: 600px;
        bottom: 75px;
      }
      @media only screen and (max-width: 768px) {
        transform: translate(-50%, 9%);
        width: 547px;
        bottom: 80px;
      }
      @media only screen and (max-width: 405px) {
        transform: translate(-50%, 3%);
      }
    }
  }
`;

const BannerContent = styled(Col)`
  height: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px){
    padding: 10% 10% 0 10%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const BannerDesc = styled.p`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 28.5px;
  line-height: 120%;
  color: #F2F7F7;
  margin: auto 0;
  text-align: left;
  max-width: 520px;
  @media only screen and (max-width: 1200px){
    font-size: 30px;
  }
  @media only screen and (max-width: 992px){
    font-size: 25px;
  }
  @media only screen and (max-width: 768px){
    font-size: 25px;
    max-width: 100%;
    line-height: 135.5%;
    margin-top: 23px;
  }
  @media only screen and (max-width: 668px){
    margin-top: 43px;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <BannerBackground>
      <Container>
        <Row>
          <BannerContent xs={12} sm={12} md={6} lg={{span: 5, offset: 1}}data-aos='fade-up' >
            <BannerDesc>En Axiona® impulsamos el desarrollo de pruebas genéticas para la búsqueda de nuevas alternativas de tratamiento oncológico, gracias al uso de la Secuenciación de Nueva Generación (NGS).
            </BannerDesc>
          </BannerContent>
          <Col
            xs={12}
            md={6}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2Fpacientes.png?alt=media&token=58f1bb0b-cbf9-421a-8988-d13d00f7ffa2'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
}