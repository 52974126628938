import React, { useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';

const ContactContainer = styled.div`
  background-color: #efefef;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 5em;
`;

const RowInput = styled(Col)`
  width: 100%;
  text-align: left;

  > * {
    padding-left: 0px;
    padding-right: 0px;
  }

  > div:nth-child(1) {
    @media only screen and (min-width: 768px) {
      padding-right: 10px;
    }
  }

  > div:nth-child(2) {
    @media only screen and (min-width: 768px) {
      padding-left: 10px;
    }
  }
`;

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 7px;
`;

const Input = styled.input`
  margin: 1rem 0;
`;

// Request example
// https://web-service-email.uc.r.appspot.com/contact?nombre=luis&apellido=hernandez&email=luisangelhdz19@gmail.com&telefono=22232931239&msg=hola

export const Contact = (props) => {
  const [token, setToken] = useState('');
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState('');
  // const [phone, setPhone] = useState();
  const [buttonEnable, setButtonEnable] = useState(true);
  // const studyData = props.data;
  const [t, i18n] = useTranslation('global');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setButtonEnable(true);
    const id = toast.loading('Enviando...');
    Object.assign(data, { token: token });
    console.log(data);
    axios
      .get(
        `https://web-service-email.uc.r.appspot.com/contact?nombre=${data.name}&apellido= &email=${data.email}&telefono=${data.phone}&msg=${data.message}<br><br>No. Estudio: Pendiente <br><br>Estudio: Pendiente <br><br>Precio: Pendiente MXN.&to=teofilo.labopat@gmail.com`
      )
      .then((res) => {
        setSubmit('Enviado con éxito');
        toast.dismiss(id);
        setTimeout(() => {
          toast.success('Mensaje enviado!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            // setShow(false);
            setButtonEnable(false);
          }, 2000);
        }, 200);
      })
      .catch((err) => {
        setError('Mensaje no enviado');
        console.log(err);
        toast.dismiss(id);
        setTimeout(() => {
          toast.error('Ha ocurrido un error :(', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setButtonEnable(false);
          }, 2000);
        }, 200);
      });
  };

  var printChange = (response) => {
    setButtonEnable(false);
    setToken(response);
    console.log(response);
  };

  return (
    <div className='d-flex align-items-center'>
      <div className='mt-4 container '>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ContactContainer>
            <h1>Contacto</h1>

            <Label>{t('home.contact.name')}*</Label>
            <Form.Control
              placeholder={t('home.contact.name')}
              {...register('name', {
                required: `${t('home.contact.required')}`,
              })}
            />
            {errors.name ? <p>{errors.name.message}</p> : null}

            <Label>{t('home.contact.email')}*</Label>
            <Form.Control
              placeholder={t('home.contact.email')}
              {...register('email', {
                required: `${t('home.contact.required')}`,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: 'El correo no cumple con el formato',
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}

            <RowInput md={12} className='row'>
              <Col sm={12} md={6}>
                <Label>{t('home.contact.phone')}*</Label>
                <Form.Control
                  type='number'
                  placeholder={t('home.contact.phone')}
                  {...register('phone', {
                    required: `${t('home.contact.required')}`,
                  })}
                />
                {errors.name ? <p>{errors.name.message}</p> : null}
              </Col>

              <Col sm={12} md={6}>
                <Label>{t('home.contact.subject')}*</Label>
                <Form.Control
                  placeholder={t('home.contact.subject')}
                  {...register('subject', {
                    required: `${t('home.contact.required')}`,
                  })}
                />
                {errors.name ? <p>{errors.name.message}</p> : null}
              </Col>
            </RowInput>

            <Label>{t('home.contact.message')}*</Label>
            <Form.Control
              as='textarea'
              className='form-control'
              placeholder={t('home.contact.message')}
              name='message'
              {...register('message', {
                required: `${t('home.contact.required')}`,
              })}
            ></Form.Control>
            {errors.consulta && <p>{errors.consulta.message}</p>}

            <br />
            <ReCAPTCHA
              sitekey='6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT'
              render='explicit'
              onChange={printChange}
            />
            <br />
            <Button disabled={buttonEnable ? true : false} type='submit'>
              {t('home.contact.send')}
            </Button>
            <br />

            <ToastContainer
              position='top-center'
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ContactContainer>
        </Form>
      </div>
    </div>
  );
};
