import React from "react";
import { AboutHem } from "./aboutHem";
import { Banner } from './banner';
import { GenesTable } from './genes';
import { SliderHem } from "./sliderHem";
import { InfoGene } from './infoGene';
import { VideoHem } from './videoHem';
import { Helmet } from "react-helmet";

export const AxionaHem = () => {
  return(
    <>
    <Helmet>
            <title>Axiona® HEME</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <Banner />
      <VideoHem />
      <AboutHem />
      <SliderHem />
      <GenesTable />
      <InfoGene />
    </>
  );
}