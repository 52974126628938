import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const SectionContainer = styled.div`
  background-color: #FFFFFF;
`;

const Title = styled.h2`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1C3245;
  margin: 0;
  padding: 0;
`;

const SmallText = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 448px;
`;

const InstructionsText = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
  margin: 0;
  padding: 0;
`;

const StyledFloating = styled(FloatingLabel)`
  text-align: left;
  width: 100%;
  padding: 0;
  .form-control {
    border: ${props => props.error === 1 ? '1px solid #FF0505' : 'none'};
    border-radius: 12px;
    font-family: 'IBM Plex Sans', sans-serif;
    max-height: 45px;
    padding: 0 40px 0 40px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
    :disabled {
      background-color: #F6F6F6;
      border: ${props => props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
      border-style: dashed;
    }
  }
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'IBM Plex Sans', sans-serif;
    transition: 200ms;
  }
  :active,
  :focus-within {
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 6px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  background: #F2F7F7 !important;
  border: ${props => props.error === 1 ? '1px solid #FF0505' : 'none'};
  border-radius: 10px !important;
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 1;
  :disabled {
    color: ${props => props.disabled === 1 ? '#a6a6a6;' : 'red'}
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
`;

const StyledTextArea = styled(Form.Control)`
  background: #F2F7F7 !important;
  border: ${props => props.error === 1 ? '1px solid #FF0505' : 'none'};
  z-index: 1;
  border-radius: 12px;
  font-family: 'IBM Plex Sans', sans-serif;
  min-height: 120px;
  max-height: 500px;
  width: 100%;
  padding: 0.7em 40px 0 40px;
  font-size: 1rem;
  :not(:placeholder-shown) {
      padding-top: 0.7em;
  }
  :focus {
    padding-top: 0.7em;
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  ::placeholder {
    color: #828282;
  }
`;

const SendBtn = styled.button`
  background: #EAAC34;
  border-radius: 100px;
  padding: 1.4% 9.2%;
  width: auto;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #353433;
  margin: 0;
  border: none;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #F2F7F7;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  :disabled {
    filter: grayscale();
    :hover {
      color: #353433;
      box-shadow: none;
    }
  }
`;

const ChatBtn = styled.a`
  background: #1379BC;
  border-radius: 100px;
  padding: 1.4% 4%;
  width: auto;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px 0px 40px;
  position: static;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #F2F7F7;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #F2F7F7;
  }
  :focus-within {
    input {
      outline: 0px !important;
      -webkit-appearance:none;
      box-shadow: none !important;
    }
  }
`;

const ErrorMessage = styled.p`
  width: auto;
  margin: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #FF0505;
  padding: 0;
  transition: all 300ms;
`;

const LittleP = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #717171;
  margin: 0;
`;

const InputStyled = styled(Form.Check)`
  > input {
    border: 1px solid #1379BC !important;
    :checked {
      border: 1px solid #1379BC !important;
      background-color: #1379BC !important
    }
  }
  >label {
    text-align: left;
  }
`;

export const ContactLiq = () => {
  const [t, i18n] = useTranslation("global");
  const [width, setWidth] = useState(window.innerWidth);
  const [phone, setPhone] = useState();
  const [buttonEnable, setButtonEnable] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [token, setToken] = useState("");
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  var printChange = (data) => {
    setButtonEnable(true);
    setToken(data);
    console.log(data);
}

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const msg = toast.loading('Enviando...')
    axios
      .get(`https://web-service-email.uc.r.appspot.com/contact-liquid?nombre=${data.name}&apellido=${data.lastName}&email=${data.email}&telefono=${data.phone}&question1=${data.question1}&question2=${data.question2}&msg=${data.message}&to=contacto@axionagenetics.com`)
      .then(res => {
        toast.dismiss(msg);
        setTimeout(() => {
          toast.success('Mensaje enviado!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setPhone('');
          document.getElementById('contactForm').reset();
        }, 200)
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(msg);
        setTimeout(() => {
          toast.error('Ha ocurrido un error :(', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }, 200)
      })
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <>
      <SectionContainer className='pt-5 pb-5 mb-3' id='contacto'>
        <Container className={isMobile ? '' : 'mt-4 mb-4'}>
          <Row className='justify-content-center'>
            <Col xs={12} sm={12} md={7}>
              <Row className='mb-5'>
                <Title>
                  {t('contact.title1')}
                </Title>
              </Row>
              <Row className='mb-5 justify-content-center'>
                <SmallText>
                  {t('contact.dec1')}
                </SmallText>
              </Row>
              <Row className='mb-5'>
                <InstructionsText>
                  {t('contact.instructions')}
                </InstructionsText>
              </Row>
              <Form onSubmit={handleSubmit(onSubmit)} id='contactForm'>
                <Row className='mb-4 pb-2'>
                  <StyledFloating
                    controlId='name'
                    label={t('contact.fields.name')}
                    error={errors?.name ? 1 : 0}
                  >
                    <StyledInput
                      type='text'
                      name='name'
                      placeholder={t('contact.fields.name')}
                      {...register('name', {
                        required: true,
                      })}
                    />
                  </StyledFloating>
                  {errors.name?.type === 'required' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.required')}
                    </ErrorMessage>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className='mb-4 pb-2'>
                  <StyledFloating
                    controlId='lastName'
                    label={t('contact.fields.lastName')}
                  >
                    <StyledInput
                      type='text'
                      name='lastName'
                      placeholder={t('contact.fields.lastName')}
                      {...register('lastName', {
                        required: false,
                      })}
                    />
                  </StyledFloating>
                </Row>
                <Row className='mb-3'>
                  <StyledFloating
                    controlId='email'
                    label={t('contact.fields.email')}
                    error={errors?.name ? 1 : 0}
                  >
                    <StyledInput
                      type='email'
                      name='email'
                      placeholder={t('contact.fields.email')}
                      {...register('email', {
                        required: true,
                        pattern: {
                          value:
                              /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i
                        },
                      })}
                    />
                  </StyledFloating>
                  {errors.email?.type === 'required' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.required')}
                    </ErrorMessage>
                  ) : errors.email?.type === 'pattern' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.email-pattern')}
                    </ErrorMessage>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className='mb-4 pb-2'>
                  <Form.Label style={{textAlign: 'left'}}>
                    Teléfono celular
                  </Form.Label>
                  <StyledPhoneInput
                    type='tel'
                    international
                    defaultCountry='MX'
                    placeholder='Teléfono celular'
                    name='phone'
                    id='phone'
                    autoComplete='off'
                    list='autocompleteOff'
                    value={phone}
                    {...register('phone')}
                    onChange={setPhone}
                  />
                </Row>
                <Row className='mb-3'>
                  <StyledTextArea
                    as='textarea'
                    type='text'
                    name='question1'
                    placeholder={t('contact.fields.question1')}
                    error={errors?.question1 ? 1 : 0}
                    {...register('question1', {
                      required: true,
                    })}
                  />
                  {errors.question1?.type === 'required' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.required')}
                    </ErrorMessage>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className='mb-3'>
                  <StyledTextArea
                    as='textarea'
                    type='text'
                    name='question2'
                    placeholder={t('contact.fields.question2')}
                    error={errors?.question2 ? 1 : 0}
                    {...register('question2', {
                      required: true,
                    })}
                  />
                  {errors.question2?.type === 'required' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.required')}
                    </ErrorMessage>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className='mb-3'>
                  <StyledTextArea
                    as='textarea'
                    type='text'
                    name='floatingMessage'
                    placeholder={t('contact.fields.message1')}
                    error={errors?.message ? 1 : 0}
                    {...register('message', {
                      required: true,
                    })}
                  />
                  {errors.message?.type === 'required' ? (
                    <ErrorMessage className='pt-2 pb-1 ps-2'>
                      {console.log(errors)}
                      {t('contact.errors.required')}
                    </ErrorMessage>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className='mb-3'>
                  <LittleP>
                    <b>{t('contact.warning-title')}</b>
                    {t('contact.warning')}
                    <a 
                      style={{
                      textDecoration: 'underline',
                      color: '#717171'}}
                      target='_blank' 
                      href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2FAP%20INTEGRAL%20MEDICO%20TRATANTE%20AGO22.pdf?alt=media&token=602b9061-9e92-4f69-8e8b-a221baff7f22'> 
                        {t('contact.policies-underline')}
                    </a>
                  </LittleP>
                </Row>
                <Row className='justify-content-center mb-3'>
                  <InputStyled
                    className='w-auto'
                    type='checkbox'
                    label={t('contact.policies-label')}
                    onChange={() => setPolicies(!policies)}
                  />
                </Row>
                <Row className='justify-content-center mb-4'>
                  <ReCAPTCHA
                    className='w-auto'
                    sitekey="6LfpEaohAAAAAJx0XGw8LIOyfLoZyi6u4Fwuiw28"
                    render="explicit"
                    onChange={printChange}
                    onExpired={()=>{setToken(undefined); setButtonEnable(false);}}
                  />
                </Row>
                <Row className='justify-content-center mb-4'>
                  <SendBtn type='submit' disabled={buttonEnable && policies === true ? false : true}>
                    {t('contact.buttons.send')}
                  </SendBtn>
                </Row>
                <Row className='justify-content-center mb-4'>
                  <InstructionsText>
                    {t('contact.or')}
                  </InstructionsText>
                </Row>
                <Row className='justify-content-center mb-5'>
                  <ChatBtn target='_blank' href='https://wa.me/5215519694584' rel='noreferrer noopener'>
                    {t('contact.buttons.chat')}
                  </ChatBtn>
                </Row>
                <Row className='justify-content-center'>
                  <SmallText>
                    <span style={{fontWeight:"700", color:'#000'}}>{t('contact.conversation')}</span>
                  </SmallText>
                </Row>
              </Form>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
            </Col>
          </Row>
        </Container>
      </SectionContainer>
    </>
  );
}
