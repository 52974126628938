import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Background from '../../assets/backgrounds/axionasolid/banner.webp';
import BannerBgMobile from '../../assets/backgrounds/axionasolid/bannerMobile.png';
import Mask from '../../assets/backgrounds/axionasolid/maskSolid.png';
import MaskMobile from '../../assets/backgrounds/axionasolid/maskSolidMobile.png';
import { useTranslation } from 'react-i18next';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FbgSolid.png?alt=media&token=30c129e7-30ab-4858-afb3-fbee24dd6f73');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FbgSolidMb.png?alt=media&token=b185ccfc-e478-4b96-83ed-1a8a7e888375');
    height: 490px;
  }
  .row {
    width: 100%;
    align-items: center;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 992px) {
      height: 400px;
    }
    @media only screen and (max-width: 768px) {
      height: 350px;
    }
    img {
      width: 530px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-60%, 0);
      @media only screen and (max-width: 992px) {
        transform: translate(-50%, 0);
        width: 430px;
      }
      @media only screen and (max-width: 768px) {
        transform: translate(-50%, 0%);
        width: 375px;
        bottom: 140px;
      }
      @media only screen and (max-width: 576px) {
        bottom: 80px;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px; 
  }
  @media only screen and (max-width: 366px) {
    padding-top: 52px;
    padding-bottom: 30px;
  }
`;

const Title = styled.p`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  color: #eaac34;
  text-align: left;
  max-width: 662px;
  margin: 0;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 556px;
    font-size: 50px;
    line-height: 48px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 280px;
    font-size: 32px;
  }
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 130%;
  color: #ffffff;
  margin: 0;
  text-align: left;
  max-width: 662px;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 556px;
    font-size: 22px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 300px;
    font-size: 19px;
  }
`;

export const SolidBanner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 768;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <BannerBackground>
      <Container>
        <Row>
          <DescColumn
            xs={{span: 12, order: 1}} md={{span: 5, order:2}} lg={{span:6, order:2}}
            className={
              isTablet
                ? 'justify-content-start h-100'
                : 'justify-content-center h-100'
            }
          >
            <Container className='d-flex flex-column justify-content-center' data-aos="fade-up">
              <Title className={isMobile ? 'mb-3' : 'mb-4'}>
                {t('solid.banner.title')}
              </Title>
              <Desc>{t('solid.banner.desc')}</Desc>
            </Container>
          </DescColumn>
          <Col
            xs={{span:12, order: 2}}
            md={{span:7, order:1}}
            lg={{span:6, order: 1}}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FgenePerson.png?alt=media&token=cb943e08-809c-40a5-8719-d00e28715838'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
};
