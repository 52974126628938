import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";

const ContactContainer = styled.div`
  background-color: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 1em;
`;

const RowInput = styled(Col)`
  width: 100%;
  text-align: left;

  > * {
    padding-left: 0px;
    padding-right: 0px;
  }

  > div:nth-child(1) {
    @media only screen and (min-width: 768px) {
      padding-right: 10px;
    }
  }

  > div:nth-child(2) {
    @media only screen and (min-width: 768px) {
      padding-left: 10px;
    }
  }
`;

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 7px;
`;

const Input = styled.input`
  margin: 1rem 0;
`;

const ContinueButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 241px;
  height: 40px;
  background: #1c3245;
  border: 2px solid transparent;
  border-radius: 10px !important;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #fff;
  }
  :hover {
    border: 2px solid #1c3245;
    transition: all 0.4s ease;
    background: #1c3245;
  }
  :disabled {
    filter: grayscale();
    :hover {
      color: #353433;
      box-shadow: none;
    }
  }
`;

// Request example
// https://web-service-email.uc.r.appspot.com/contact?nombre=luis&apellido=hernandez&email=luisangelhdz19@gmail.com&telefono=22232931239&msg=hola

export const Contact = (props) => {
  const [token, setToken] = useState("");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  // const [phone, setPhone] = useState();
  const [buttonEnable, setButtonEnable] = useState(true);
  // const studyData = props.data;
  const [t, i18n] = useTranslation("global");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setButtonEnable(true);
    const id = toast.loading("Enviando...");
    Object.assign(data, { token: token });
    console.log(data);
    axios
      .get(
        `https://web-service-email.uc.r.appspot.com/contact?nombre=${data.name}&apellido=${data.lastName}&email=${data.email}&telefono=${data.phone}&msg=${data.message}&to=contacto@axionagenetics.com`
      )
      .then((res) => {
        setSubmit("Enviado con éxito");
        toast.dismiss(id);
        setTimeout(() => {
          toast.success("Mensaje enviado!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            // setShow(false);
            setButtonEnable(false);
          }, 2000);
        }, 200);
      })
      .catch((err) => {
        setError("Mensaje no enviado");
        console.log(err);
        toast.dismiss(id);
        setTimeout(() => {
          toast.error("Ha ocurrido un error :(", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setButtonEnable(false);
          }, 2000);
        }, 200);
      });
  };

  var printChange = (response) => {
    setButtonEnable(false);
    setToken(response);
    console.log(response);
  };

  return (
    <div className="align-items-center">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ContactContainer>
          <RowInput style={{ marginBottom: "10px" }} md={6} className="row">
            <Col sm={6} md={6} style={{ marginBottom: "10px" }}>
              <Form.Control
                style={{ background: "#F2F7F7" }}
                placeholder={t("Nombre*")}
                {...register("name", {
                  required: `${t("Campo obligatorio")}`,
                })}
              />
              {errors.name ? <p>{errors.name.message}</p> : null}
            </Col>
            <Col sm={6} md={6}>
              <Form.Control
                style={{ background: "#F2F7F7" }}
                placeholder={t("Apellido*")}
                {...register("lastName", {
                  required: `${t("Campo obligatorio")}`,
                })}
              />
              {errors.lastName ? <p>{errors.lastName.message}</p> : null}
            </Col>
          </RowInput>
          <RowInput style={{ marginBottom: "10px" }} md={12} className="row">
            <Col sm={6} md={6} style={{ marginBottom: "10px" }}>
              <Form.Control
                style={{ background: "#F2F7F7" }}
                placeholder={t("Email*")}
                {...register("email", {
                  required: `${t("Campo obligatorio")}`,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                    message: "El correo no cumple con el formato",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </Col>
            <Col sm={12} md={6}>
              <Form.Control
                style={{ background: "#F2F7F7" }}
                type="number"
                placeholder={t("Teléfono")}
                {...register("phone", {
                  required: `${t("Campo obligatorio")}`,
                })}
              />
              {errors.name ? <p>{errors.name.message}</p> : null}
            </Col>
          </RowInput>
          <Form.Control
            style={{ background: "#F2F7F7", minHeight: "5em" }}
            as="textarea"
            type="text"
            className="form-control"
            placeholder={t("Mensaje*")}
            name="message"
            {...register("message", {
              required: `${t("Campo obligatorio")}`,
            })}
          ></Form.Control>
          {errors.consulta && <p>{errors.consulta.message}</p>}

          <br />
          <ReCAPTCHA
            sitekey="6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT"
            render="explicit"
            onChange={printChange}
          />
          <br />
          <ContinueButton disabled={buttonEnable ? true : false} type="submit">
            Enviar mensaje
          </ContinueButton>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ContactContainer>
      </Form>
    </div>
  );
};
