import React, { useState } from 'react';
import { Accordion, Card, Row } from 'react-bootstrap';
import styled from 'styled-components';
import OpenedArrow from '../../assets/img/openedArrow.png';
import ClosedArrow from '../../assets/img/closedArrow.png';
import CardFAQ from './cardFAQ';
import { useTranslation } from 'react-i18next';

const CardQuestion = styled.p`
    margin-bottom: 5px;
`;

const AxionaWord = styled.span`
    font-weight: 800;
`;

const Arrow = styled.img`
    width: 12px;
`;

export const FAQ = () => {
    const [t, i18n] = useTranslation('global');
    const [activeId, setActiveId] = useState(2);
    const faqs = t('home.faqs', { returnObjects: true });

    function toggleActive(id) {
        if (activeId === id) {
          setActiveId(null);
        } else {
          setActiveId(id);
        }
      }

    return (
        <div className='container'>
        <br/><br/><br/><h1>FAQ's</h1>
        <br/><br/>
        <Accordion>
            {faqs.map((faq, index) => (
                <CardFAQ data={faq} key={index}/>
            ))}
        </Accordion>
        <br/><br/><br/><br/><br/><br/>
        </div>
    );
};