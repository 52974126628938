export const GenesList = [
  {
    name: 'AKT1',
    belongs: [
      1
    ] 
  },
  {
    name: 'AKT2',
    belongs: [
      1
    ]
  },
  {
    name: 'AKT3',
    belongs: [
      1
    ]
  },
  {
    name: 'ALK',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'AR',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'ARAF',
    belongs: [
      1
    ]
  },
  {
    name: 'BRAF',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'CD274',
    belongs: [
      2
    ]
  },
  {
    name: 'CDK4',
    belongs: [
      1
    ]
  },
  {
    name: 'CDKN2A',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'CHEK2',
    belongs: [
      1
    ]
  },
  {
    name: 'CTNNB1',
    belongs: [
      1
    ]
  },
  {
    name: 'EGFR',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'ERBB2',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'ERBB3',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'ERBB4',
    belongs: [
      1
    ]
  },
  {
    name: 'ESR1',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'FGFR1',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'FGFR2',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'FGFR3',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'FGFR4',
    belongs: [
      1
    ]
  },
  {
    name: 'FLT3',
    belongs: [
      1
    ]
  },
  {
    name: 'GNA11',
    belongs: [
      1
    ]
  },
  {
    name: 'GNAQ',
    belongs: [
      1
    ]
  },
  {
    name: 'GNAS',
    belongs: [
      1
    ]
  },
  {
    name: 'HRAS',
    belongs: [
      1
    ]
  },
  {
    name: 'IDH1',
    belongs: [
      1
    ]
  },
  {
    name: 'IDH2',
    belongs: [
      1
    ]
  },
  {
    name: 'KIT',
    belongs: [
      1
    ]
  },
  {
    name: 'KRAS',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'MAP2K1',
    belongs: [
      1
    ]
  },
  {
    name: 'MAP2K2',
    belongs: [
      1
    ]
  },
  {
    name: 'MET',
    belongs: [
      1,
      2,
      3
    ]
  },
  {
    name: 'MTOR',
    belongs: [
      1
    ]
  },
  {
    name: 'NRAS',
    belongs: [
      1
    ]
  },
  {
    name: 'NRG1',
    belongs: [
      3
    ]
  },
  {
    name: 'NTRK1',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'NTRK2',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'NTRK3',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'NUTM1',
    belongs: [
      3
    ]
  },
  {
    name: 'PDGFRA',
    belongs: [
      1
    ]
  },
  {
    name: 'PIK3CA',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'PTEN',
    belongs: [
      1,
      2
    ]
  },
  {
    name: 'RAF1',
    belongs: [
      1
    ]
  },
  {
    name: 'RET',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'ROS1',
    belongs: [
      1,
      3
    ]
  },
  {
    name: 'RSPO2',
    belongs: [
      3
    ]
  },
  {
    name: 'RSPO3',
    belongs: [
      3
    ]
  },
  {
    name: 'SMO',
    belongs: [
      1
    ]
  },
  {
    name: 'TP53',
    belongs: [
      1
    ]
  },
];