import React, { useState } from 'react';
import { Accordion, Card, Row } from 'react-bootstrap';
import styled from 'styled-components';
import OpenedArrow from '../../assets/img/openedArrow.png';
import ClosedArrow from '../../assets/img/closedArrow.png';

const CardContainer = styled(Card)`
    border: none;
    margin-bottom: 10px;
    text-align: left;
    
    > div.card-header {
        background-color: #dfdfdf;
        font-weight: 800;
        border-radius: 15px;
    }
`;

const CardQuestion = styled.p`
    margin-bottom: 5px;
`;

const AxionaWord = styled.span`
    font-weight: 800;
`;

const Arrow = styled.img`
    width: 12px;
`;

const CardFAQ = (props) => {
    const data = props.data;
    const [active, setActive] = useState(false);
    const questions = data.questions;

    function toggleActive() {
        if (active) {
          setActive(false);
        } else {
          setActive(true);
        }
      }

    return (
        <CardContainer>
            <Accordion.Toggle 
                as={Card.Header} 
                eventKey={data.id}
                onClick={() => toggleActive()}>
                <Row>
                <div style={{fontFamily: 'Bruna-Medium'}}className='col-11'>
                    {data.title}
                </div> 
                <div className='col-1'>
                    <Arrow src={active ? OpenedArrow : ClosedArrow} alt="Arrow" />
                </div> 
                </Row>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={data.id}>
                <Card.Body>
                    {questions.map((question)=>(
                        <CardQuestion>{question}</CardQuestion>
                    ))}
                </Card.Body>
            </Accordion.Collapse>
        </CardContainer>
    )
};

export default CardFAQ;