import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px !important;
  background: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  // z-index: 10;
  .header {
    background: #1379bc;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0 5px;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .header-row {
    background: rgba(19, 121, 188, 0.15);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #eaac34;
  }
  .black-text,
  .yellow-text-bold,
  .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold,
  .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #eaac34;
  }
  .blue-text-bold {
    color: #1379bc !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  margin-top: 8px;
  max-width: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  max-height: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  width: auto;
  padding: 0;
`;

const DropdownDiv = styled.div`
  position: absolute;
  width: 83%;
  margin-left: 16.66%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 60px 26px 50px;
  z-index: 10;
  h1,
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #353433;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      height: 104px;
      padding: 5px 11px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #1379bc;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 165px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379bc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

export const ResultsModalContent = (props) => {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <StyledRow className='pt-4 ps-4 pe-4 pb-0 mb-4'>
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
        <Col xs={4} sm={4} md={3} className='ps-0'>
          <TitleContainer>
            <StyledModalTitle>{t('hem.modals.results.title')}</StyledModalTitle>
          </TitleContainer>
        </Col>
        <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
          <Row className='mb-4 pe-3'>
            <StyledModalDesc>
              {t('hem.modals.results.content.normal.first')}
              <b>{t('hem.modals.results.content.bold.first')}</b>
              {t('hem.modals.results.content.normal.second')}
              <b>{t('hem.modals.results.content.bold.second')}</b>
              {t('hem.modals.results.content.normal.third')}
              <b>{t('hem.modals.results.content.bold.third')}</b>
              {t('hem.modals.results.content.normal.fourth')}
            </StyledModalDesc>
          </Row>
          <Row>
            <ExampleText>
              {t('hem.modals.results.content.exampleText')}
            </ExampleText>
          </Row>
        </Col>
      </StyledRow>
      <StyledRowExample className='pb-4'>
        <Col xs={12} className='header'>
          {t('hem.modals.results.title')}
        </Col>
        <Col xs={2} className='header-col ps-0'>
          <div>GEN</div>
        </Col>
        <Col xs={2} className='header-col'>
          <div>ALTERACIÓN</div>
        </Col>
        <Col xs={8} className='right flex-column pe-0'>
          <Row
            className='justify-content-center'
            style={{ background: 'rgba(19, 121, 188, 0.15)' }}
          >
            <div className='w-auto' style={{ fontWeight: '700' }}>
              TERAPIA ASOCIADA
            </div>
          </Row>
          <Row
            className='justify-content-between'
            style={{ background: 'rgba(19, 121, 188, 0.08)' }}
          >
            <Col xs={4} className='pe-2 ps-2'>
              <div style={{ fontWeight: '700' }} className='text-center'>
                EN ESTE TIPO DE CÁNCER
              </div>
            </Col>
            <Col xs={4} className='pe-2 ps-2'>
              <div style={{ fontWeight: '700' }} className='text-center'>
                EN OTRO TIPO DE CÁNCER
              </div>
            </Col>
            <Col xs={4} className='pe-2 ps-2'>
              <div style={{ fontWeight: '700' }} className='text-center'>
                ENSAYOS CLÍNICOS
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className='right p-0'>
          <Col
            xs={2}
            className='info-col justify-content-start'
            style={{ textAlign: 'left' }}
          >
            FLT3
            <br />
            Frecuencia
            <br />
            de alelo: 47.30%
          </Col>
          <Col xs={2} className='info-col'>
            D835Y
          </Col>
          <Col xs={8} className='d-flex flex-column justify-content-center'>
            <Row>
              <Col xs={4} className='info-col'>
                NINGUNO
              </Col>
              <Col xs={4} className='info-col'>
                NINGUNO
              </Col>
              <Col xs={4} className='info-col'>
                3
              </Col>
            </Row>
          </Col>
          {/* <Col xs={3} className='info-col black-text position-relative'>
            Significado incierto &nbsp;
            <Icon
              src={IconsMean[0].icon}
              alt={`Icono ${IconsMean[0].alt}`}
              lg={0}
            />
          </Col> */}
        </Col>
        <div className='p-0'>
          <hr className='m-0' />
        </div>
        <Col
          xs={2}
          className='info-col justify-content-start'
          style={{ textAlign: 'left' }}
        >
          CBFB-MYH11
          <br />
          Frecuencia
          <br />
          de alelo: 47.30%
        </Col>
        <Col xs={2} className='info-col'>
          FUSIÓN
        </Col>
        <Col xs={8} className='right'>
          <Col xs={4} className='info-col'>
            NINGUNO
          </Col>
          <Col xs={4} className='info-col'>
            NINGUNO
          </Col>
          <Col xs={4} className='info-col d-flex flex-direction-column'>
            0
          </Col>
        </Col>
      </StyledRowExample>
      <div className='position-relative mb-3'>
        <StyledRowDown className='pb-2'>
          <Col xs={2} className='text-col'>
            <div>Nombre del gen que se encontró alterado.</div>
          </Col>
          <Col xs={2} className='text-col'>
            <div>
              Detalle de la alteración encontrada. 
            </div>
          </Col>
          <Col xs={8} className='right justify-content-center'>
            <Col xs={11} className='text-col pe-3 ps-3'>
              <div>Terapias que se ha demostrado funcionan para esta alteración ya sea en su tipo de Cáncer o en otros tipos de Cáncer  con la misma alteración.</div>
            </Col>
          </Col>
        </StyledRowDown>
      </div>
    </>
  );
};
