import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Kit from "../../assets/img/home/kit.png";
import LungImg from "../../assets/img/home/lung.png";
import StomachImg from "../../assets/img/home/stomach.png";
import TrialImg from "../../assets/img/home/trial.png";
import Girl from "../../assets/img/home/ilustracion_chica.png";
import { useTranslation } from "react-i18next";
import { FeaturesSlider } from "./featuresSlider";
import Vimeo from "@u-wave/react-vimeo";

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #eaac34;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 576px) {
    font-size: 30px;
    line-height: 31px;
    text-align: left;
    max-width: 265px;
  }
`;

const Description = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  color: #353433;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.h2`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  text-align: start;
  color: #1c3245;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 31px;
    text-align: left;
  }
`;

const GirlImage = styled.img`
  max-width: 480px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const HowItWorksContainer = styled.div`
  background: linear-gradient(180deg, #f2f7f7 81.77%, #ffffff 100%);
`;

const HowItWorksContent = styled.div`
  max-width: 402px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

const SmallText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #353433;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const OncologyContainer = styled.div`
  background-color: #ffffff;
`;

const RequestKitBtn = styled.a`
  background: #1379bc;
  border-radius: 100px;
  padding: 2% 6%;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  max-width: 194px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: #1c3245;
    color: #ffffff;
  }
`;

const InfoContainer = styled.div`
  background-color: #f2f7f7;
`;

const InfoFeatures = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1c3245;
  text-align: left;
  margin: 0;
  padding: 0;
  max-width: 202px;
  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

const FeaturesImg = styled.img`
  max-width: 117px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
  @media only screen and (max-width: 992px) {
    max-width: 100%;
  }
`;

const StyledImg = styled.img`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

export const AboutSolid = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation("global");
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Container className="pt-5">
        <Row className="mb-5">
          <Col xs={12} sm={12} md={12} className={isMobile ? "p-0" : "mb-5"}>
            <Vimeo
              color="ffbc45"
              video={"https://vimeo.com/899281377?share=copy"}
              responsive={true}
              autopause
            />
          </Col>
          {!isMobile && (
            <Col
              xs={12}
              sm={12}
              md={6}
              className="d-flex flex-column align-items-start"
            >
              <GirlImage
                src={Girl}
                alt="Ilustración de una persona"
                className="img-fluid w-100 p-0"
                data-aos="fade-up"
              />
            </Col>
          )}
          <Col xs={12} sm={12} md={6}>
            <Row className="mb-4">
              <SubTitle>{t("home.why-axiona.title")}</SubTitle>
            </Row>
            <Row className="mb-5">
              <Description data-aos="fade-up">
                {t("home.why-axiona.desc-one")}
                <b>{t("home.why-axiona.bold")}</b>
                {t("home.why-axiona.desc-two")}
                <i>{t("home.why-axiona.italic")}</i>
                {t("home.why-axiona.desc-three")}
              </Description>
            </Row>
            {isMobile && (
              <Row className="mb-5">
                <GirlImage
                  src={Girl}
                  alt="Ilustración de una persona"
                  className="img-fluid w-100 p-0"
                  data-aos="fade-up"
                />
              </Row>
            )}
            <Row className="mb-5" data-aos="fade-up">
              <FeaturesSlider />
            </Row>
          </Col>
        </Row>
      </Container>
      <HowItWorksContainer className="pt-5 pb-5">
        <Container className={isMobile ? "" : "pt-5 pb-5"}>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <HowItWorksContent className="justify-content-around">
                <Title className="mb-4" style={{ textAlign: "left" }}>
                  {t("home.how-it-works.title")}
                </Title>
                <SubTitle
                  className="mb-4"
                  data-aos="fade-up"
                  style={{ textAlign: "left" }}
                >
                  {t("home.how-it-works.subtitle")}
                </SubTitle>
                <SmallText
                  className="mb-5"
                  data-aos="fade-up"
                  style={{
                    textAlign: "left",
                    maxWidth: isMobile ? "290px" : "100%",
                  }}
                >
                  {t("home.how-it-works.desc")}
                </SmallText>
              </HowItWorksContent>
            </Col>
            <Col xs={12} sm={12} md={6} className={isMobile ? "p-0" : ""}>
              <Vimeo
                color="ffbc45"
                video={"https://player.vimeo.com/video/745131433"}
                responsive={true}
                autopause
              />
            </Col>
          </Row>
        </Container>
      </HowItWorksContainer>
      <OncologyContainer className="pt-3">
        <Container>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={6}
              className={`d-flex flex-column justify-content-center align-items-center ${
                isMobile ? "mb-4" : ""
              }`}
            >
              <Title className="text-center mb-5" style={{ maxWidth: "100%" }}>
                {t("home.oncology.title")}
              </Title>
              {!isMobile && (
                <RequestKitBtn
                  target="_blank"
                  href="https://solicitudes.axionagenetics.com/"
                >
                  {t("home.oncology.request")}
                </RequestKitBtn>
              )}
            </Col>
            <Col xs={12} sm={12} md={6} className={isMobile ? "mb-5" : ""}>
              <StyledImg
                src={Kit}
                alt="Imagen de kit axiona solid"
                className={isMobile ? "img-fluid p-0 mb-5" : "img-fluid p-0"}
                data-aos="fade-up"
              />
              {isMobile && (
                <RequestKitBtn
                  target="_blank"
                  href="https://solicitudes.axionagenetics.com/"
                >
                  {t("home.oncology.request")}
                </RequestKitBtn>
              )}
            </Col>
          </Row>
        </Container>
      </OncologyContainer>
      <InfoContainer className="pt-5 pb-5">
        <Container className={isMobile ? "" : "pt-5 pb-5"}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={6}
              className={`d-flex flex-column justify-content-center ${
                isMobile ? "mb-4" : ""
              }`}
            >
              <div className="p-0" style={{ maxWidth: "448px" }}>
                <SubTitle className="mb-4" style={{ textAlign: "left" }}>
                  {t("home.info.title")}
                  <br />
                  {t("home.info.title-two")}
                </SubTitle>
                <SmallText className="mb-4" data-aos="fade-up">
                  {t("home.info.desc-one")}
                  <b>{t("home.info.bold")}</b>
                  {t("home.info.desc-two")}
                </SmallText>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Row className="mb-4" data-aos="fade-up">
                <Col
                  xs={3}
                  sm={4}
                  md={4}
                  className="d-flex flex-column justify-content-center"
                >
                  <FeaturesImg
                    src={LungImg}
                    alt="Ilustración de pulmón"
                    className="img-fluid p-0"
                  />
                </Col>
                <Col xs={9} sm={8}>
                  <InfoFeatures>
                    {t("home.info.features.type-one")}
                  </InfoFeatures>
                </Col>
              </Row>
              <Row className="mb-4" data-aos="fade-up">
                <Col
                  xs={3}
                  sm={4}
                  md={4}
                  className="d-flex flex-column justify-content-center"
                >
                  <FeaturesImg
                    src={StomachImg}
                    alt="Ilustración de estómago"
                    className="img-fluid p-0"
                  />
                </Col>
                <Col xs={9} sm={8}>
                  <InfoFeatures>
                    {t("home.info.features.type-two")}
                  </InfoFeatures>
                </Col>
              </Row>
              <Row className="mb-4" data-aos="fade-up">
                <Col
                  xs={3}
                  sm={4}
                  md={4}
                  className="d-flex flex-column justify-content-center"
                >
                  <FeaturesImg
                    src={TrialImg}
                    alt="Ilustración de ensayo"
                    className="img-fluid p-0"
                  />
                </Col>
                <Col xs={9} sm={8}>
                  <InfoFeatures>
                    {t("home.info.features.type-three")}
                  </InfoFeatures>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </InfoContainer>
    </>
  );
};
