import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Vimeo from '@u-wave/react-vimeo';

const MainContainer = styled.div`
  background: #f2f7f7;
  padding: 45px 0 45px 0;
  @media only screen and (max-width: 576px) {
        padding: 0px 0 45px 0;
      }
`;
const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: left;
  color: #eaac34;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 576px) {
    font-size: 30px;
    line-height: 31px;
    text-align: left;
    max-width: 265px;
  }
`;
const Description = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  color: #353433;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.h2`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1c3245;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 31px;
    text-align: left;
  }
`;

export const VideoHem = () => {
  const [t, i18n] = useTranslation('global');
	const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 992;
	
	function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <MainContainer>
        <Container className='mt-4 mb-4'>
          <Row className='mt-4 mb-4'>
            <Col md={6} lg={6} xl={6} xs={12} sm={12} className='mb-4'>
              <Container>
                <Title className='mb-3'>
                  {t('hem.how-it-works-video.titleA')}
                  <br />
                  {t('hem.how-it-works-video.titleB')}
                </Title>
                <SubTitle className='mb-3'>
                  {t('hem.how-it-works-video.descA')}
                  {!isMobile && (
										<br />
									)}
                  {t('hem.how-it-works-video.descB')}
									{!isMobile && (
										<br />
									)}
                  {t('hem.how-it-works-video.descC')}
                </SubTitle>
                <Description>
                  {t('hem.how-it-works-video.small-textA')}
                  {!isMobile && (
										<br />
									)}
                  {t('hem.how-it-works-video.small-textB')}
                </Description>
              </Container>
            </Col>
            <Col md={6} lg={6} xl={6} xs={12} sm={12}>
              <Vimeo
                color='ffbc45'
                video={'https://player.vimeo.com/video/792413044'}
                responsive={true}
                autopause
              />
            </Col>
          </Row>
        </Container>
      </MainContainer>
    </>
  );
};
