import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'antd';
import Logo from '../../assets/img/axiona-logo.png';
import MenuIcon from '../../assets/icons/menu.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderContainer = styled.div`
    background-color: #1379BC;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 80px;
    display: flex;
    justify-content: flex-end !important;
    vertical-align: middle;
    padding: 15px 0px;
    width: 100%;
    @media only screen and (min-width: 992px) {
      margin-bottom: 70px;
    } 
    @media only screen and (max-width: 768px) {
      height: 70px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
`;

const LogoImg = styled.img`
    height: 45px;
    @media only screen and (max-width: 768px) {
        max-height: 40px;
    }
`;

const StyledLink = styled(Link)`
  > p {
    font-size: 15px;
    font-family: IBM Plex Sans;
    font-weight: 500;
    text-decoration: none;
    color: #FFFFFF;
    margin: 0;
  }
`;

const StyledSection = styled.a`
  > p {
    font-size: 15px;
    font-family: IBM Plex Sans;
    font-weight: 500;
    text-decoration: none;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
  }
`;

const TestsButton = styled.button`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  width: 91px;
  height: 43px;
  background: ${props => props.showTests ? '#EAAC34' : '#1C3245'};
  border-radius: 35px;
  border: none;
  @media only screen and (max-width: 992px) {
    height: 27px;
  }
`;

const StyledDivTests = styled.div`
  padding: 5px 40px;
  width: 100%;
  background: #E0EDF5;
  position: absolute;
  left: 50%;
  div div {
    align-items: center;
  }
  #container-tests {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .col-8 {
    padding: 0 6px;
  }
  margin-left: auto;
  margin-right: auto;
  visibility: ${props => props.show === 1 ? 'hidden' : 'visible'};
  transform: ${props => props.showmenu ? 'translate(-50%,0)' : 'translate(-50%, -125px)'};
  transition: all 300ms;
  transition-delay: 50ms;
  .opened {
    border-radius: 10px;
    background: rgba(19, 121, 188, 0.15);
    @media only screen and (max-width: 992px) {
      padding: 0 4px;
    }
  }
  @media only screen and (min-width: 992px) {
    margin-top: -70px;
  } 
  @media only screen and (max-width: 992px) {
    border-radius: 0px 0px 15px 15px;
    left: auto;
    right: 0;
    width: 200px;
    padding: 6px 6px;
    transform: ${props => props.showmenu ? 'translate(0, 0);' : 'translate(0, -355px) !important'};
    transition: all 600ms;
    .col-12 {
      padding: 0;
    }
    hr {
      margin: 6px 0;
    }
  }
  text-align: left;
  span {
    font-weight: 700;
  }
  img {
    width: 60px;
  }
  #heme-img {
    width: 60px;
  }
  .col-lg-3 {
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 2px;
    @media only screen and (max-width: 992px) {
      padding: 0;
    }
  }
  .test {
    cursor: pointer;

  }
  #second {
    @media only screen and (min-width: 992px) {
      border-left: 1.5px solid #1379BC;
      border-right: 1.5px solid #1379BC;
    }
  }
  #third {
    @media only screen and (min-width: 992px) {
      border-right: 1.5px solid #1379BC;
    }
  }
  #links-div {
    display: flex !important;
    flex-direction: column;
    a {
      text-align: center;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #1C3245;
      margin 9px 0;
      span {
        font-weight: 700;
      }
    }
  }
`;

const TopWhite = styled.div`
  width: 100%;
  height: 40px;
  background: #E0EDF5;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }
  a {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #1C3245;
    margin-bottom: 0;
  }
  img {
    width: 18px;
  }
`;

const NavImage = styled.img`
  max-width: 2em;
  max-height: 2em;
  @media only screen and (max-width: 768px) {
    max-width: 25px;
  }
  :hover {
    transform: scale(1.1, 1.1);
  }
`;

const BurguerButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const RowItemTest = styled(Row)`
  transition: transform 0.3s;

  ${(props) =>
    props.bigger &&
    css`
      transform: scale(1.08);
    `}
`;

const animate = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const AnimatedItem = styled(RowItemTest)`
  @media only screen and (min-width: 768px) {
    animation: ${({ anima }) =>
      !anima
        ? 'none'
        : css`
            ${animate} 3s infinite
          `};
  }
`;

export const NavBar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showTests, setShowTests] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  let isMobile = width <= 992;
  const {pathname} = useLocation();
  
  // For navbar testitems
  const [biggerItems, setBiggerItems] = useState(false);

  document.addEventListener('click', function (event) {
    if (event.target.id !== 'tests-menu' && event.target.id !== 'tests-menu-button') {
      setShowTests(false);
    }
  });

  document.addEventListener('click', function (event) {
    if (event.target.id !== 'menu-div' && event.target.id !== 'menu-div-button') {
      setShowMenu(false);
    }
  });


  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(()=> {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  });

  return (
    <>
    <div style={{width: "100%", position: "-webkit-sticky", position: "sticky", top: "0", zIndex: "101"}}>
    <TopWhite>
      <Container>
        <a href={"tel:+525519694584"}>
          <img alt='phone' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fnavbar%2Fphone.png?alt=media&token=a3a9c2a1-b928-48d6-9cc9-f6483bbc0fb6'/>
          &nbsp;&nbsp;&nbsp;{!isMobile && "Línea Axiona®:"} +52 55 19 69 4584
        </a>
        <div>
          {isMobile &&
            <TestsButton showTests={showTests} onClick={()=>setShowTests(true)} id="tests-menu-button">Pruebas</TestsButton>
          }
        </div>
      </Container>
    </TopWhite>
    <HeaderContainer>
      <Container>
        <Row className='align-items-center'>
          <Col xs={5} lg={3} xl={4}>
            <div className='d-flex'>
              <Link to='/'>
                <LogoImg alt="Axiona Web Logo" className='img-fluid' src={Logo} />
              </Link>
            </div>
          </Col>
          <Col xs={7} lg={9} xl={8}>
            <Row className='justify-content-end'>
              {
                isMobile ? (
                  <></>
                ) : (
                  <Col xs={12}>
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                      <StyledLink to='/'>
                        <p>Inicio</p>
                      </StyledLink>
                      <TestsButton showTests={showTests} onClick={()=>setShowTests(!showTests)} onMouseEnter={()=>setBiggerItems(true)} onMouseLeave={()=>setBiggerItems(false)} id="tests-menu-button">Pruebas</TestsButton>
                      {/* <StyledDdButton align={"start"} title="Pruebas">
                        <StyledDropdown.Item eventKey="1" as={StyledLink} to='/axionasolid'>Axiona Solid</StyledDropdown.Item>
                        <StyledDropdown.Item eventKey="2" as={StyledLink} to='/axionagene'>Axiona Gene</StyledDropdown.Item>
                        <StyledDropdown.Item eventKey="3" as={StyledLink} to='/axionaheme'>Axiona Heme</StyledDropdown.Item>
                      </StyledDdButton> */}
                      <StyledLink to='/pacientes'>
                        <p>Pacientes</p>
                      </StyledLink>
                      <StyledLink to='/medicos'>
                        <p>Médicos</p>
                      </StyledLink>
                      <StyledLink onClick={() => window.location.replace('/#contacto')}>
                        <p>Contacto</p>
                      </StyledLink>
                      <StyledSection target='_blank' href='https://solicitudes.axionagenetics.com/'>
                        <p>Generar solicitud</p>
                      </StyledSection>
                      {/* <StyledLink to='/report'>
                        <p>Reporte</p>
                      </StyledLink>
                      <StyledLink to='/patients'>
                        <p>Pacientes</p>
                      </StyledLink>
                      <StyledLink to='/doctors'>
                        <p>Médicos</p>
                      </StyledLink> */}
                      {/* <StyledLink to='/aboutus'>
                        <p>Nosotros</p>
                      </StyledLink> */}
                    </div>
                  </Col>
                )
              }
              {/* <Col xs={12} sm={12} lg={4} className='d-flex justify-content-end'>
                <StyledLink to='/' className='d-flex flex-row align-items-center'
                  style={{marginRight: '.8rem'}}>
                  <p style={{marginRight: '.8rem'}}>LogIn</p>
                  <img src={LoginIcon} style={{maxWidth: '2em', maxHeight: '2em'}} />
                </StyledLink>
                <LangButton>
                  <LangImg onClick={changeLanguageButton} src={ t('home.therapy.info-highlight') === "Targeted" ? usFlag : mxFlag } alt="flag"/> 
                </LangButton>
                <SelectLng id='lang' onChange={changeLanguage}>
                  <option value='es'>Español</option>
                  <option value='en'>English</option>
                </SelectLng>
                {isMobile ? (
                  <NavDrawer />
                  ) : (
                    <>
                    </>
                )}
              </Col> */}
              {isMobile ? (
                <Col xs={12} sm={12} lg={4} className='d-flex justify-content-end'>
                  <BurguerButton
                    style={{ border: 'none' }}
                    type='ghost'
                    onClick={()=>setShowMenu(true)}
                    id="menu-div-button"
                  >
                    <NavImage src={MenuIcon} alt='Menu' id="menu-div-button"/>
                  </BurguerButton>
                </Col>
                // <Col xs={12} sm={12} lg={4} className='d-flex justify-content-end'>
                //   <NavDrawer />
                // </Col>
                ) : (
                  <>
                  </>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
    </div>
    <div style={{position: 'fixed', width: '100%', zIndex: '100'}}>
      <div style={{position: 'relative', width: '100%', zIndex: '100'}}>
        <StyledDivTests showmenu={isMobile ? showTests : true} show={!(isMobile ? showTests : true) ? 1 : 0} id="tests-menu">
          <div id='container-tests'>
            <Row>
              <Col xs={12} lg={3}>
                <AnimatedItem className={`item-bigger test ${pathname.includes('axionasolid') ? 'opened' : ''}`} onClick={() => window.location.replace('/axionasolid')} bigger={biggerItems} anima={showTests}>
                  <Col xs={4} className="d-flex justify-content-center"><img alt='Axiona solid' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fnavbar%2FA%20SOLID.png?alt=media&token=f6f84c05-806c-4e8d-9561-d65b0c5be9e7'/></Col>
                  <Col xs={8}><span>Axiona®</span> SOLID</Col>
                </AnimatedItem>
              </Col>
              {isMobile && <hr/>}
              <Col xs={12} lg={3} id="second">
                <AnimatedItem className={`item-bigger test ${pathname.includes('axionagene') ? 'opened' : ''}`} onClick={() => window.location.replace('/axionagene')} bigger={biggerItems} anima={showTests}>
                  <Col xs={4} className="d-flex justify-content-center"><img alt='Axiona gene' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fnavbar%2FA%20GENE.png?alt=media&token=e6975af6-9fed-46ab-ad35-b422c2ce73a3'/></Col>
                  <Col xs={8}><span>Axiona®</span> GENE</Col>
                </AnimatedItem>
              </Col>
              {isMobile && <hr/>}
              <Col xs={12} lg={3} id="third">
                <AnimatedItem className={`item-bigger test w100 ${pathname.includes('axionaheme') ? 'opened' : ''}`} onClick={() => window.location.replace('/axionaheme')} bigger={biggerItems} anima={showTests}>
                  <Col xs={4} className="d-flex justify-content-center"><img id="heme-img" alt='Axiona heme' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fnavbar%2FA%20HEME.png?alt=media&token=7d09bcac-ae5b-451e-905f-d1a7d36eb143'/></Col>
                  <Col xs={8}><span>Axiona®</span> HEME</Col>
                </AnimatedItem>
              </Col>
              {isMobile && <hr/>}
              <Col xs={12} lg={3}>
                <AnimatedItem className={`item-bigger test w100 ${pathname.includes('axionaliquid') ? 'opened' : ''}`} onClick={() => window.location.replace('/axionaliquid')} bigger={biggerItems} anima={showTests}>
                  <Col xs={4} className="d-flex justify-content-center"><img id="heme-img" alt='Axiona liquid' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fnavbar%2FA%20LIQUID.png?alt=media&token=31232160-bce6-441c-9d08-8d921161fb37'/></Col>
                  <Col xs={8}><span>Axiona®</span> LIQUID</Col>
                </AnimatedItem>
              </Col>
            </Row>
          </div>
        </StyledDivTests>
      </div>
    </div>
    <div style={{position: 'fixed', width: '100%', zIndex: '100'}}>
      <Container style={{position: 'relative', width: '100%', zIndex: '100'}}>
        <StyledDivTests showmenu={showMenu} show={!showMenu ? 1 : 0} id="menu-div">
          <div id="links-div">
            <Link to='/'>Home</Link>
            <p
              id="tests-menu-button"
              onClick={()=>setShowTests(true)}
              style={{textAlign: 'center', margin: '9px 0', fontWeight: '700'}}
            >Pruebas</p>
            {/* <Link to='/axionasolid'><span>Axiona®</span> SOLID</Link>
            <Link to='/axionagene'><span>Axiona®</span> GENE</Link>
            <Link to='/axionaheme'><span>Axiona®</span> HEME</Link>
            <Link to='/axionaliquid'><span>Axiona®</span> LIQUID</Link> */}
            <Link to='/pacientes'>Pacientes</Link>
            <Link to='/medicos'>Médicos</Link>
            <Link onClick={() => window.location.replace('/#contacto')}>Contacto</Link>
            <a style={{fontWeight: '700'}} target='_blank' rel='noreferrer' href='https://solicitudes.axionagenetics.com/'>Generar solicitud</a>
          </div>
        </StyledDivTests>
      </Container>
    </div>

    </>
  );
};