import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px !important;
  background: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  // z-index: 10;
  .header {
    background: #1379bc;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0 5px;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .header-row {
    background: rgba(19, 121, 188, 0.15);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #eaac34;
  }
  .black-text,
  .yellow-text-bold,
  .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold,
  .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #eaac34;
  }
  .blue-text-bold {
    color: #1379bc !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  margin-top: 8px;
  max-width: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  max-height: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  width: auto;
  padding: 0;
`;

const DropdownDiv = styled.div`
  position: absolute;
  width: 83%;
  margin-left: 16.66%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 60px 26px 50px;
  z-index: 10;
  h1,
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #353433;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      height: 104px;
      padding: 5px 11px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #1379bc;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 165px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379bc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

export const TrialsModalContent = (props) => {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <StyledRow className='pt-4 ps-4 pe-4 pb-0 mb-4'>
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
        <Col xs={4} sm={4} md={3} className='ps-0'>
          <TitleContainer>
            <StyledModalTitle>{t('hem.modals.trials.title')}</StyledModalTitle>
          </TitleContainer>
        </Col>
        <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
          <Row className='mb-4 pe-3'>
            <StyledModalDesc>
              {t('hem.modals.trials.content.normal.first')}
              <b>{t('hem.modals.trials.content.bold.first')}</b>
              {t('hem.modals.trials.content.normal.second')}
              <a href='https://clinicaltrials.gov/' target={'_blank'} rel='noreferrer'>{t('hem.modals.trials.content.link.first')}</a>
              <br />
              <br />
              <span style={{ fontSize: '14px' }}>
                {t('hem.modals.trials.content.normal.third')}
                <a href='https://clinicaltrials.gov/' target={'_blank'} rel='noreferrer'>{t('hem.modals.trials.content.link.second')}</a>
              </span>
            </StyledModalDesc>
          </Row>
          <Row>
            <ExampleText>
              {t('hem.modals.trials.content.exampleText')}
            </ExampleText>
          </Row>
        </Col>
      </StyledRow>
      <StyledRowExample className='pb-4'>
        <Col
          xs={12}
          className='header'
          style={{ background: '#ffffff', color: '#1379BC', textAlign: 'left' }}
        >
          {t('hem.modals.trials.title')}
        </Col>
        <Col
          xs={12}
          className='header'
          style={{ textAlign: 'left', fontWeight: '400', fontStyle: 'italic' }}
        >
          TPM3-NTRK1 Fusión
        </Col>
        <Col xs={2} className='header-col ps-0'>
          <div>ID NTC</div>
        </Col>
        <Col xs={8} className='header-col' style={{ textAlign: 'left' }}>
          <div className='ps-3 justify-content-start'>Título</div>
        </Col>
        <Col xs={2} className='right flex-column pe-0'>
          <Row
            className='justify-content-center'
            style={{ background: 'rgba(19, 121, 188, 0.15)' }}
          >
            <div className='w-auto' style={{ fontWeight: '700' }}>
              Fase
            </div>
          </Row>
        </Col>
        <Col xs={12} className='right p-0 mb-2'>
          <Col
            xs={2}
            className='info-col justify-content-center'
            style={{ textAlign: 'center' }}
          >
            NCT04116541
          </Col>
          <Col xs={8} className='info-col' style={{ textAlign: 'left' }}>
            MegaMOST - A Multicenter, Open-label, Biology Driven, Phase II Study
            Evaluating the Activity of Anti-cancer Treatments Targeting Tumor
            Molecular Alterations /Characteristics in Advanced / Metastatic
            Tumors.
          </Col>
          <Col
            xs={2}
            className='d-flex flex-column justify-content-center align-items-center'
          >
            II
          </Col>
          {/* <Col xs={3} className='info-col black-text position-relative'>
            Significado incierto &nbsp;
            <Icon
              src={IconsMean[0].icon}
              alt={`Icono ${IconsMean[0].alt}`}
              lg={0}
            />
          </Col> */}
        </Col>
      </StyledRowExample>
      <div className='position-relative'>
        <StyledRowDown className='pb-2 mb-3'>
          <Col xs={2} className='text-col'>
            <div>Identificación del ensayo clínico</div>
          </Col>
          <Col xs={8} className='text-col'>
            <div>
              Detalle sobre el ensayo clínico asociado a la terapia blanco para
              las alteraciones genéticas encontradas.
            </div>
          </Col>
          <Col xs={2} className='text-col justify-content-center'>
            <div>Fase actual del ensayo clínico.</div>
          </Col>
        </StyledRowDown>
        <Row className='p-0' style={{ background: '#EAAC34' }}>
          <p className='mb-0 pt-3 pb-3 pe-5 ps-5' style={{fontSize: '16px'}}>
            Si usted y su médico están interesados en algún ensayo clínico,{' '}
            <b>
              Axiona® puede ayudarle a contactar con los centros de investigación
              una vez que se ha realizado la prueba.
            </b>
          </p>
        </Row>
      </div>
    </>
  );
};
