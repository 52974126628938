import React from 'react';
import { Carousel } from './Carousel';
import { AboutAxiona } from './aboutAxiona';
import { AboutSolid } from './aboutSolid';
import { ContactForm } from '../Contact/ContactForm';

export const Home = () => {
  return (
    <>
      <Carousel/>
      <AboutAxiona />
      <AboutSolid />
      <ContactForm />
    </>
  );
};
