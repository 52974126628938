import React from 'react';
import { Banner } from './banner';
import { TumorBoard } from './tumorBoard';
import { WhyAxiona } from './whyAxiona';
import { Helmet } from "react-helmet";

export const Doctors = () => {
  return(
    <>
    <Helmet>
            <title>Medicos</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <Banner />
      <WhyAxiona />
      <TumorBoard />
    </>
  );
}