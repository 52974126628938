import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FhemeBg.png?alt=media&token=a4b07eb0-e6ff-4151-b753-769ad25ae08c") center no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FhemeBgMb.png?alt=media&token=edae8d9f-2608-4050-b13f-c053584b2e7b" center no-repeat);
    height: 460px;
  }
  .row {
    width: 100%;
    align-items: center;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 350px;
    }
    img {
      width: 452px;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 768px) {
        transform: translate(-50%, 9%);
        width: 312px;
        bottom: 80px;
      }
      @media only screen and (max-width: 405px) {
        transform: translate(-50%, 3%);
      }
    }
  }
`;

const ContentSection = styled(Col)`
  padding-top: 200px;
  padding-bottom: 126px;
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 40px; 
  }
  @media only screen and (max-width: 366px) {
    padding-top: 52px;
    padding-bottom: 30px;
  }
`;

const TitleRow = styled(Row)`
  margin-bottom: 18px;
`;

const Title = styled.h1`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  color: #EAAC34;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    margin-top: -20px;
  }
`;

const DescRow = styled(Row)`
  margin: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #FFFFFF;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 19px;
    line-height: 25px;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation('global');
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <BannerBackground>
      <Container>
        <Row>
          <ContentSection xs={12} md={6} xl={{span: 5, offset: 1}}>
            <TitleRow>
              <Title>
                {t('hem.banner.title')}
                {isMobile && width > 310 && (
                  <br/>
                )}
              </Title>
            </TitleRow>
            <DescRow>
              <Desc>
                {t('hem.banner.desc')}
                {isMobile && width > 310 && (
                  <br/>
                )}
              </Desc>
            </DescRow>
          </ContentSection>
          <Col
            xs={12}
            md={6}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FhemePerson.png?alt=media&token=a5b4070d-8bb1-4009-8d44-416ce1796e29'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
}