import React, {useState} from 'react';
import styled from 'styled-components';
import { Container, Col, Row, Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Results from '../../assets/img/report/Results.png';
import Therapies from '../../assets/img/report/Therapies.png';
import Trials from '../../assets/img/report/Trials.png';
import Close from '../../assets/img/report/close.png';
import One from '../../assets/img/report/1.png';
import Two from '../../assets/img/report/2.png';
import Three from '../../assets/img/report/3.png';
import ResultsDetails from '../../assets/img/report/results_details.png';
import TherapiesDetails from '../../assets/img/report/therapies_details.png';
import TrialsDetails from '../../assets/img/report/trials_details.png';
import PlantImg from '../../assets/img/report/plant.png';
import { useTranslation } from 'react-i18next';

const ReportPartText = styled.p`
  text-align: left;
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #434444;
  margin: 0;
  width: auto;
  margin-left: .8rem;
`;

const StyledButton = styled.button`
  text-align: center;
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  margin: 0;
  width: auto;
  padding: .8rem 1.5rem .8rem 1.5rem;
  background-color: ${props => props.selected ? '#EBAB34' : '#1479bc'};
  border: none;
  border-radius: 8rem;
  border-style: none;
  transition: 300ms;
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

const StyledColumnLeft = styled(Col)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10%;
  top: 0;
  @media only screen and (max-width: 992px) {
    left: 5%;
  }
`;

const StyledColumnRight = styled(Col)`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10%;
  top: 0;
  @media only screen and (max-width: 992px) {
    right: 5%;
  }
`;

const ReportSection = styled.div`
  position: relative;
  padding: 3rem 2rem 3rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .two {
    max-width: 338px;
    margin-top: 1.5%;
  }
  .three {
    max-width: 400px;
    margin-top: -.5%;
  }
  .float {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 1200px) {
    padding: 0;
    .two {
      max-width: 328px;
    }
    .three {
      max-width: 390px;
    }
  }
  @media only screen and (max-width: 992px) {
    .two {
      margin-top: 5.5%;
      max-width: 280px;
    }
    .three {
      margin-top: 3%;
      max-width: 340px;
    }
  }
`;

const AxionaDocument = styled.img`
  position: relative;
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
  z-index: ${props => props.selected ? '1' : '0'};
  @media only screen and (max-width: 1200px) {
    max-width: 380px;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 4%;
    max-width: 320px;
  }
`;

const FloatingPlant = styled.img`
  position: absolute;
  max-width: 24%;
  right: 5%;
  bottom: -15%;
`;

const DescText = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #434444;
  margin: 0 0 2rem 0;
  text-align: justify;
`;

const ModalText = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #434444;
  margin: 0;
  text-align: left;
  padding: 3rem 4rem 3rem 4rem;
`;

const StyledModal = styled(Modal)`
  .large {
    max-width: 60%;
    @media only screen and (max-width: 1200px) {
      max-width: 80%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 90%;
    }
  }
  .content {
    border-radius: 1.1rem 1.1rem 1rem 1rem;
    background-color: #F9F9F9;
    border: none;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  position: relative;
  background-color: #317ac7;
  border-radius: 1rem 1rem 0 0;
  max-height: 94px;
  padding: 1rem 2rem 1rem 2rem;
  justify-content: flex-start;
  .number {
    position: absolute;
    max-width: 70px;
    left: 5%;
    top: 45%;
  }
`;

const StyledModalTitle = styled(ModalTitle)`
  position: relative;
  font-family: IBM Plex Sans;
  font-weight: 600;
  line-height: 120%;
  font-size: 28px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
`;

const CloseBtn = styled.img`
  max-width: 25px;
  margin-left: auto;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
`;

export const Documents = () => {
  const [t, i18n] = useTranslation("global");
  const [docId, setDocId] = useState({first: false, second: false, third: false});
  const [modalId, setModalId] = useState({results: false, therapies: false, trials: false});

  const handleDocument = (docName) => {
    setDocId(prev => ({[docName]: !prev[docName]}));
  }

  const handleModal = (modalName) => {
    setModalId(prev => ({[modalName]: !prev[modalName]}));
  }

  return(
    <>
      <ReportSection>
        <div className='position-relative w-100 mb-5'>
          <AxionaDocument src={Trials} alt='Ensayos clínicos' className='three' selected={docId.third} />
          <AxionaDocument src={Therapies} alt='Terapias encontradas' className='two float' selected={docId.second} />
          <AxionaDocument src={Results} alt='Resumen de resultados' className='float' selected={docId.first} />
          <FloatingPlant src={PlantImg} alt='Planta' data-aos='fade-up'/>
        </div>
        <StyledColumnLeft md={4} lg={3} xl={6} className='d-flex flex-column' data-aos='fade-up'>
          <div className='mb-5 d-flex flex-column w-auto'>
            <Row className='mb-2'>
              <ReportPartText>{t('report.documents.buttons.one.title')}</ReportPartText>
            </Row>
            <Row>
              <StyledButton 
                onClick={()=> handleModal('results')} 
                onMouseEnter={() => handleDocument('first')} 
                selected={docId.first}>{t('report.documents.buttons.one.content')}</StyledButton>
            </Row>
          </div>
          <div className='pt-5'>
            <Row className='mb-2'>
              <ReportPartText>{t('report.documents.buttons.two.title')}</ReportPartText>
            </Row>
            <Row>
              <StyledButton 
                onClick={()=> 
                handleModal('therapies')} 
                onMouseEnter={() => handleDocument('second')} 
                selected={docId.second}>{t('report.documents.buttons.two.content')}</StyledButton>
            </Row>
          </div>
        </StyledColumnLeft>
        <StyledColumnRight md={4} lg={3} xl={6} className='d-flex flex-column align-items-end' data-aos='fade-up'>
          <div className='mt-5 pt-5 d-flex flex-column w-auto'>
            <Row className='mb-2'>
              <ReportPartText>{t('report.documents.buttons.three.title')}</ReportPartText>
            </Row>
            <Row>
              <StyledButton 
                onClick={()=> handleModal('trials')} 
                onMouseEnter={() => handleDocument('third')} 
                selected={docId.third}>{t('report.documents.buttons.three.content')}</StyledButton>
            </Row>
          </div>
        </StyledColumnRight>
      </ReportSection>
      <Container className='p-5 p-sm-5 p-md-0 p-lg-0 p-xl-0'>
        <Row className='justify-content-center'>
          <Col xl={9}>
            {docId.first ? (
              <DescText>
                {t('report.documents.results.desc')}
              </DescText>
            ) : docId.second ? (
              <DescText>
                {t('report.documents.therapies.desc')}
              </DescText>
            ) : docId.third ? (
              <>
              <DescText>
                {t('report.documents.trials.desc-part-one')}
                <a style={{color: '#434444'}}
                href='https://www.clinicaltrials.gov/' 
                target='_blank'>
                  <u>{t('report.documents.trials.desc-part-two')}</u>
                </a>
              </DescText>
              <DescText>
                {t('report.documents.trials.desc-part-three')}<u>{t('report.documents.trials.desc-part-four')}</u>
              </DescText>
              </>
            ) : (
              <DescText>
                {t('report.documents.results.desc')}
              </DescText>
            )}
            <DescText className='mb-5 pb-5'>
              <b>
                {t('report.documents.axiona-profile')}
              </b>
            </DescText>
          </Col>
        </Row>

        {/* Results modal */}
        <StyledModal
          show={modalId.results}
          onHide={() => setModalId(!modalId.results)}
          aria-labelledby="results-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName ="content"
        >
          <StyledModalHeader>
            <img src={One} alt="one image" className='number' />
            <StyledModalTitle id="results-modal">
              {t('report.documents.results.modal.header')}
            </StyledModalTitle>
            <CloseBtn src={Close} alt="close button" role="button" onClick={() => setModalId(!modalId.results)} />
          </StyledModalHeader>
          <StyledModalBody>
            <ModalText className='mb-4 pb-0'>
              {t('report.documents.results.modal.desc')}
            </ModalText>
            <ModalText className='text-center pt-0 pb-3'>
              <b>{t('report.documents.example')}</b>
            </ModalText>
            <img src={ResultsDetails} alt='Documento de resultados' className='img-fluid pb-5' />
          </StyledModalBody>
        </StyledModal>

        {/* Therapies modal */}
        <StyledModal
          show={modalId.therapies}
          onHide={() => setModalId(!modalId.therapies)}
          aria-labelledby="therapies-modal"
          dialogClassName="large"
          contentClassName ="content"
        >
          <StyledModalHeader>
            <img src={Two} alt="two image" className='number' />
            <StyledModalTitle id="therapies-modal">
              {t('report.documents.therapies.modal.header')}
            </StyledModalTitle>
            <CloseBtn src={Close} alt="close button" role="button" onClick={() => setModalId(!modalId.therapies)} />
          </StyledModalHeader>
          <StyledModalBody>
            <ModalText className='mb-4 pb-0'>
              {t('report.documents.therapies.modal.desc')}
            </ModalText>
            <ModalText className='text-center pt-0 pb-3'>
              <b>{t('report.documents.example')}</b>
            </ModalText>
            <img src={TherapiesDetails} alt='Documento de terapias' className='img-fluid pb-5' />
          </StyledModalBody>
        </StyledModal>

        {/* Trials modal */}
        <StyledModal
          show={modalId.trials}
          onHide={() => setModalId(!modalId.trials)}
          aria-labelledby="trials-modal"
          dialogClassName="large"
          contentClassName ="content"
        >
          <StyledModalHeader>
            <img src={Three} alt="three image" className='number' />
            <StyledModalTitle id="trials-modal">
              {t('report.documents.trials.modal.header')}
            </StyledModalTitle>
            <CloseBtn src={Close} alt="close button" role="button" onClick={() => setModalId(!modalId.trials)} />
          </StyledModalHeader>
          <StyledModalBody>
            <ModalText className='mb-4 pb-0'>
              {t('report.documents.trials.modal.desc-part-one')}
              <a style={{color: '#434444'}}
                href='https://www.clinicaltrials.gov/' 
                target='_blank'>
                  <u>{t('report.documents.trials.modal.desc-part-two')}</u>
              </a>
            </ModalText>
            <ModalText className='mb-4 pt-0 pb-0'>
              {t('report.documents.trials.modal.desc-part-three')}
              <u>{t('report.documents.trials.modal.desc-part-four')}</u>
            </ModalText>
            <ModalText className='text-center pt-0 pb-3'>
              <b>{t('report.documents.example')}</b>
            </ModalText>
            <img src={TrialsDetails} alt='Documento de ensayos' className='img-fluid pb-5' />
          </StyledModalBody>
        </StyledModal>
      </Container>
    </>
  );
}