import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col } from  'react-bootstrap';
import BannerBg from '../../assets/backgrounds/aboutUs/banner.png'
import BannerBgMobile from '../../assets/backgrounds/aboutUs/bannermobile.png'

const BannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  @media only screen and (max-width: 768px){
    align-items: flex-start;
  }
`;

const BannerContent = styled(Col)`
  position: absolute;
  left: 10%;
  @media only screen and (max-width: 768px){
    padding: 10% 0 0 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 576px) {
    padding: 12% 6% 0 6%;
  }
`;

const BannerTitle = styled.p`
  font-family: WarblerText-Regular;
  font-weight: 500;
  font-size: 56px;
  line-height: 120%;
  color: #F4AE34;
  text-align: left;
  margin: 0;
  @media only screen and (max-width: 1200px){
    font-size: 40px;
  }
  @media only screen and (max-width: 992px){
    font-size: 42px;
  }
  @media only screen and (max-width: 768px){
    font-size: 34px;
  }
`;

const BannerDesc = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: left;
  margin: 0;
  padding-top: 8px;
  padding-right: 34px;
  color: #1378BC;
  @media only screen and (max-width: 1200px){
    font-size: 20px;
  }
  @media only screen and (max-width: 992px){
    font-size: 18px;
  }
  @media only screen and (max-width: 768px){
    font-size: 14px;
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    width: 55%;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
    let isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
      }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
             window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

  return(
    <BannerContainer>
      <BannerContent xs={12} sm={6} md={5} lg={5} xl={5}>
        <BannerTitle className='mb-2'>Un equipo <br/> multidisciplinario</BannerTitle>
        <BannerDesc>Hic vitae quo eligendi exercitationem consectetur laboriosam laborum aliquid itaque est!</BannerDesc>
      </BannerContent>
      <img src={isMobile ? BannerBgMobile : BannerBg} alt='Banner background' className='img-fluid w-100' />
    </BannerContainer>
  );
}