import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionaliquid%2Fbg%20banner%201.png?alt=media&token=d0c13ee4-7bbe-4b36-bbd4-75eda50720e4');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionaliquid%2Fbg%20banner%202.png?alt=media&token=613bf0b7-17d2-40b4-8308-2bc19633a765');
    height: 460px;
  }
  .row {
    width: 100%;
    align-items: center;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 992px) {
      height: 400px;
    }
    @media only screen and (max-width: 768px) {
      height: 350px;
    }
    img {
      width: 520px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-60%, 0);
      @media only screen and (max-width: 992px) {
        transform: translate(-50%, 0);
        width: 430px;
      }
      @media only screen and (max-width: 768px) {
        transform: translate(-50%, 0%);
        width: 271px;
        bottom: 140px;
      }
      @media only screen and (max-width: 576px) {
        bottom: 155px;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 40px; 
  }
  @media only screen and (max-width: 366px) {
    padding-bottom: 30px;
  }
`;

const Title = styled.p`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 120%;
  color: #eaac34;
  text-align: left;
  max-width: 662px;
  margin: 0;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 556px;
    font-size: 50px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 260px;
    font-size: 32px;
  }
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 130%;
  color: #ffffff;
  margin: 0;
  text-align: left;
  max-width: 662px;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 556px;
    font-size: 22px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 283px;
    font-size: 19px;
  }
`;

export const LiquidBanner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 768;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <BannerBackground>
      <Container>
        <Row>
          <DescColumn
            xs={{span: 12, order: 1}} md={{span: 5, order:2}} lg={{span:6, order:2}}
            className={
              isTablet
                ? 'justify-content-start h-100'
                : 'justify-content-center h-100'
            }
          >
            <Container className='d-flex flex-column justify-content-center' data-aos="fade-up">
              <Title className={isMobile ? 'mb-3' : 'mb-4'}>
                {t('liquid.banner.title')}
              </Title>
              <Desc>{t('liquid.banner.desc')}</Desc>
            </Container>
          </DescColumn>
          <Col
            xs={{span:12, order: 2}}
            md={{span:7, order:1}}
            lg={{span:6, order: 1}}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionaliquid%2Fmujer-joven-segura-que-lucha-contra-cancer%201.png?alt=media&token=9aabd3cf-f4e1-4acb-a659-30781e783315'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
};
