import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FFondo-Textura%201.png?alt=media&token=c92d8401-9b50-45bf-a308-40bf9238f022") center no-repeat;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38" center no-repeat);
    height: 480px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    img {
      width: 500px;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 768px) {
        width: 300px;
        right: -50%;
        bottom: 110px;
        transform: translate(-50%, 0);
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 460px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  color: #FFF;
  text-align: right;
  font-family: Warbler Text;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 800px;
  margin: 0;
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    text-align: center;
    max-width: 540px;
    font-size: 40px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 280px;
    font-size: 25px;
    margin-bottom: 20px !important;
  }
`;

const Desc = styled.p`
  color: #EAAC34;
  text-align: right;
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 0 5px 1rem;
  margin: 0;
  max-width: 800px;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
    text-align: center;
  }
  @media only screen and (max-width: 576px) {
    max-width: 280px;
    font-size: 19px;
  }
`;

export const BannerTwo = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <BannerBackground>
      <Container>
        <Row>
          <Col
            xs={{span: 12, order: 2}}
            md={{span: 5, order: 1}}
            id="col-img"
          >
            <img
              alt='persona sonriendo'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2Fmujer-mediana-edad-cancer-piel.png?alt=media&token=ebe6c330-0388-4b99-9fb6-b300463b3e9a'
            />
          </Col>
          <DescColumn
            xs={{span: 12, order: 1}}
            md={{span: 7, order: 2}}
            className={
              isTablet ? 'justify-content-start mt-5' : 'justify-content-center'
            }
          >
            <Container data-aos='fade-up'>
              <Title className={isMobile ? 'mb-2 ms-auto me-auto' : 'mb-4'}>
                {t('home.banner.title')}
              </Title>
              <Desc className={isMobile ? 'ms-auto me-auto' : ''}>{t('home.banner.desc')}</Desc>
            </Container>
          </DescColumn>
        </Row>
      </Container>
    </BannerBackground>
  );
};
