import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenesList } from './genesList';
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

const TextContainer = styled.div`
  max-width: 288px;
  padding: 0;
  margin: 0;
`;

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #1c3245;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const DetectionBtn = styled.a`
  background: ${(props) =>
    props.selected === 1
      ? '#1379BC'
      : props.selected === 2
      ? '#1379BC'
      : props.selected === 3
      ? '#1379BC'
      : '#EAAC34'};
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  max-width: 240px;
  width: 100%;
  text-align: center;
  color: ${(props) =>
    props.selected === 1
      ? '#FFFFFF'
      : props.selected === 2
      ? '#FFFFFF'
      : props.selected === 3
      ? '#FFFFFF'
      : '#353433'};
  margin: 0;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: ${(props) =>
      props.selected === 1
        ? '#FFFFFF'
        : props.selected === 2
        ? '#FFFFFF'
        : props.selected === 3
        ? '#FFFFFF'
        : '#1379BC'};
  }
  @media only screen and (max-width: 576px) {
    max-width: 100%;
    width: 100%;
  }
  user-select: none;
`;

const LeftColumn = styled.div`
  max-width: 360px;
`;

const TableContainer = styled.div`
  border-radius: 40px;
`;

const TableHeader = styled.div`
  background-color: #eaac34;
  border-radius: 40px 40px 0px 0px;
`;

const TableTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #1c3245;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const TableContent = styled.div`
  background-color: #f2f7f7;
  border-radius: 0px 0px 48px 48px;
`;

const TableDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #1c3245;
  margin: 0;
  padding: 0;
`;

const GenesUl = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListElement = styled.li`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
`;

export const GenesTable = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation('global');
  const [newList, setNewList] = useState();
  const [gen, setGen] = useState(0);
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const formatList = (array, size) => {
    let position = 0;
    let slicedList = [];
    const length = array.length;
    while (position < length) {
      slicedList.push(array.slice(position, (position += size)));
    }
    setNewList(slicedList);
    return newList;
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if(gen === 1) {
      return formatList(GenesList['hotspots'],7);
    } else if (gen === 2) {
      return formatList(GenesList['complete'],6);
    } else if (gen === 3) {
      return formatList(GenesList['fusion'],7);
    }
  }, [gen]);

  return (
    <Container className='pt-5 pb-5'>
      <Row className='pt-4 justify-content-start'>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className='d-flex flex-column justify-content-center'
        >
          <Row
            className={`justify-content-start`}
          >
            <LeftColumn>
              <TextContainer className='mb-4'>
                <Title className='mb-4'>{t('hem.genes.title')}</Title>
                <Desc>{t('hem.genes.desc')}</Desc>
              </TextContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(1)}
                  selected={gen === 1 ? 1 : 0}
                >
                  {t('hem.genes.buttons.button-one')}
                </DetectionBtn>
              </ButtonContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(2)}
                  selected={gen === 2 ? 1 : 0}
                >
                  {t('hem.genes.buttons.button-two')}
                </DetectionBtn>
              </ButtonContainer>
              <ButtonContainer className='mb-4'>
                <DetectionBtn
                  className='pe-4 ps-4 pt-2 pb-2'
                  onClick={() => setGen(3)}
                  selected={gen === 3 ? 1 : 0}
                >
                  {t('hem.genes.buttons.button-three')}
                </DetectionBtn>
              </ButtonContainer>
            </LeftColumn>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={7} xl={6}>
          {gen !== 0 ? (
            <TableContainer
              data-aos="fade-up"
              component={Paper}
            >
              <TableHeader className='pt-2 pb-2'>
                <TableTitle>{t('hem.genes.table.title')}</TableTitle>
              </TableHeader>
              <TableContent className='pb-4'>
                <Row className='pt-4 pb-4'>
                  <TableDesc>
                    {gen === 1 ? (
                      <>
                        {t('hem.genes.table.headers.one')}
                      </>
                    ) : gen === 2 ? (
                      <>
                        {t('hem.genes.table.headers.two')}
                      </>
                    ) : gen === 3 ? (
                      <>
                        {t('hem.genes.table.headers.three')}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableDesc>
                </Row>
                <Table size="small">
                <Row className='justify-content-center'>
                  {newList?.map((item, index) => (
                      <Col xs={4} lg={2} style={{borderLeft:"outset"}} key={index} className='mb-2'>
                        <GenesUl>
                          {item?.map((gene, index) => (
                            <ListElement
                              key={index}
                            >
                              <TableCell> <th>{gene.name}</th></TableCell>
                            </ListElement>
                          ))}
                        </GenesUl>
                      </Col>
                  ))}
                </Row>
                </Table>
              </TableContent>
            </TableContainer>
          ) : (
            <div className='d-flex flex-row justify-content-center'>
              <img 
                src={'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FAxionaLogoLg.png?alt=media&token=004c8da9-2e5b-4834-9e47-6b204d474aef'} 
                alt='Axiona logo' 
                className='img-fluid p-0 d-block w-100'
                style={{maxWidth: '376px'}}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
