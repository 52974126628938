import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Doubts = styled(Row)`
  border: 2px solid #EAAC34;
  border-radius: 40px;
  padding: 7px 53px 0px 0px;
  text-align: left;
  padding: 7px 53px 0px 0px;
  @media only screen and (max-width: 992px) {
    padding: 31px 29px 0px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  div > #linea-labopat {
    position: relative;
  }
  div #linea-labopat {
    positiion: absolute;
    bottom: 0px;
    max-width: 267px;
    width: 100%;
  }
  #phone {
    width: 26px;
    position: absolute;
    top: 8px;
    left: -37px;
    @media only screen and (max-width: 992px) {
      top: -30px;
      left: 0px;
    }
  }
  h5 {
    margin-right: auto;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #1379BC;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #EAAC34;
    position: relative;
    @media only screen and (max-width: 992px) {
      margin-top: 30px;
    }
  }
  p {
    max-width: 320px;
    margin-right: auto;
  }
  button {
    margin-right: auto;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      margin-bottom: 25px;
    }
    @media only screen and (max-width: 992px) {
      margin: 26px auto;
    }
  }
`;

const BlueButton = styled.button`
  background: #1379BC;
  border-radius: 100px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  min-width: 213px;
  padding: 10px 25px 12px;
`;

export const ContactBorder = () => {
  return(
    <Doubts>
      <Col xs={{span: 12, order: 3}} lg={{span: 3, order: 1}}>
        <img id="linea-labopat" alt="linea-labopat" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2FlineaAxiona.png?alt=media&token=33d7cd20-be52-4bd1-a00a-960edd53f2dc"/>
      </Col>
      <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 2}}>
        <h5>¿Desea más información?</h5>
        <p><span>Nuestro equipo está capacitado para responder cualquier duda y darle la asistencia necesaria.</span></p>
      </Col>
      <Col xs={{span: 12, order: 2}} lg={{span: 5, order: 3}}>
        <h3><img id="phone" alt="phone" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fpacientes%2Fphone.png?alt=media&token=5425d569-f710-4c43-84ec-872cf1eaf977"/>
        Llame al +52 55 19 69 4584 o escríbanos por Whatsapp</h3>
        <BlueButton><a target='_blank' href='https://wa.me/5215519694584' rel='noreferrer noopener' style={{color: "#fff"}}>Contáctenos</a></BlueButton>
      </Col>
    </Doubts>
  );
}