import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px !important;
  background: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  // z-index: 10;
  .header {
    background: #1379bc;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0 5px;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .header-row {
    background: rgba(19, 121, 188, 0.15);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #eaac34;
  }
  .black-text,
  .yellow-text-bold,
  .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold,
  .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #eaac34;
  }
  .blue-text-bold {
    color: #1379bc !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  margin-top: 8px;
  max-width: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  max-height: ${(props) => (props.lg === 1 ? '24px' : '18px')};
  width: auto;
  padding: 0;
`;

const DropdownDiv = styled.div`
  position: absolute;
  width: 83%;
  margin-left: 16.66%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 60px 26px 50px;
  z-index: 10;
  h1,
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #353433;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      height: 104px;
      padding: 5px 11px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #1379bc;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 165px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379bc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

export const TherapiesModalContent = (props) => {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <StyledRow className='pt-4 ps-4 pe-4 pb-0 mb-4'>
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
        <Col xs={4} sm={4} md={3} className='ps-0'>
          <TitleContainer>
            <StyledModalTitle>
              {t('hem.modals.therapies.title')}
            </StyledModalTitle>
          </TitleContainer>
        </Col>
        <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
          <Row className='mb-4 pe-3'>
            <StyledModalDesc>
              {t('hem.modals.therapies.content.normal.first')}
              <b>{t('hem.modals.therapies.content.bold.first')}</b>
              {t('hem.modals.therapies.content.normal.second')}
            </StyledModalDesc>
          </Row>
          <Row>
            <ExampleText>
              {t('hem.modals.therapies.content.exampleText')}
            </ExampleText>
          </Row>
        </Col>
      </StyledRow>
      <StyledRowExample className='pb-4'>
        <Col
          xs={12}
          className='header'
          style={{ background: '#ffffff', color: '#1379BC', textAlign: 'left' }}
        >
          Información detallada sobre el tratamiento
        </Col>
        <Row
          className='pe-0 ps-0'
          style={{
            background: 'rgba(19, 121, 188, 0.08)',
            marginBottom: '6px',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
        >
          <Col
            xs={3}
            className='pe-0 d-flex flex-column justify-content-center align-items-start position-relative'
            style={{ paddingLeft: '32px' }}
          >
            <div style={{ fontWeight: '700' }}>En este tipo de cáncer</div>
            <img
              style={{ maxWidth: '20px', height: 'auto', left: '6px' }}
              className='position-absolute'
              src={
                'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92'
              }
              alt='Icono'
            />
          </Col>
          <Col
            xs={3}
            className='pe-0 d-flex flex-column justify-content-center align-items-start position-relative'
            style={{ paddingLeft: '32px' }}
          >
            <div style={{ fontWeight: '700' }}>En otro tipo de cáncer</div>
            <img
              style={{ maxWidth: '20px', height: 'auto', left: '4px' }}
              className='position-absolute'
              src={
                'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FblueIcon.png?alt=media&token=3785f348-a5a8-4e8c-b508-74b5d262723b'
              }
              alt='Icono'
            />
          </Col>
          <Col
            xs={3}
            className='pe-0 d-flex flex-column justify-content-center align-items-start position-relative'
            style={{ paddingLeft: '32px' }}
          >
            <div style={{ fontWeight: '700' }}>
              En este tipo de cáncer y en otros tipos de cáncer
            </div>
            <img
              style={{ maxWidth: '20px', height: 'auto', left: '4px' }}
              className='position-absolute'
              src={
                'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FProbPatogenicaIcon.png?alt=media&token=44ba50ad-1448-41f0-abfe-ab4c1739c38c'
              }
              alt='Icono'
            />
          </Col>
          <Col
            xs={3}
            className='pe-0 d-flex flex-column justify-content-center align-items-start position-relative'
            style={{ paddingLeft: '32px' }}
          >
            <div style={{ fontWeight: '700' }}>Sin pruebas (sin indicios)</div>
            <img
              style={{ maxWidth: '20px', height: 'auto', left: '4px' }}
              className='position-absolute'
              src={
                'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
              }
              alt='Icono'
            />
          </Col>
        </Row>
        <Col
          xs={12}
          className='header ps-3'
          style={{ textAlign: 'left', fontStyle: 'italic', fontWeight: '400' }}
        >
          FLT3 D835Y
        </Col>
        <Col xs={3} className='header-col ps-0 pe-0'>
          <div
            className='justify-content-start ps-3'
            style={{ textAlign: 'left' }}
          >
            Tratamiento correspondiente
          </div>
        </Col>
        <Col xs={1} className='header-col ps-0 pe-0'>
          <div className='justify-content-start ps-3'>FDA</div>
        </Col>
        <Col xs={2} className='header-col ps-0 pe-0'>
          <div className='justify-content-start ps-3'>NCCN</div>
        </Col>
        <Col xs={2} className='header-col ps-0 pe-0'>
          <div className='justify-content-start ps-3'>EMA</div>
        </Col>
        <Col xs={2} className='header-col ps-0 pe-0'>
          <div className='justify-content-start ps-3'>ESMO</div>
        </Col>
        <Col xs={2} className='header-col ps-0 pe-0'>
          <div className='justify-content-start ps-3'>Ensayos clínicos</div>
        </Col>
        <Row className='ps-0 pe-0 pt-2 mt-1'>
          <Col xs={3} className='ps-0 pe-0'>
            <div
              className='justify-content-start ps-3'
              style={{ fontWeight: '700' }}
            >
              cabozantinib
            </div>
          </Col>
          <Col xs={1} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-1'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-2'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-1'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-2'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <div className='d-flex flex-row p-0 ms-2 w-100'>
                <img
                  style={{
                    maxWidth: '20px',
                    height: 'auto',
                    position: 'unset',
                    display: 'block',
                  }}
                  className='ms-3'
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92'
                  }
                  alt='X'
                />
                <p className='w-auto mb-0 ps-2' style={{ fontWeight: '700' }}>
                  (II)
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className='p-0 mt-2 mb-2'>
          <hr className='m-0' style={{ color: '#1379BC' }} />
        </div>
        <Row className='ps-0 pe-0 mt-1 mb-2'>
          <Col xs={3} className='ps-0 pe-0'>
            <div
              className='justify-content-start ps-3'
              style={{ fontWeight: '700' }}
            >
              sunitinib
            </div>
          </Col>
          <Col xs={1} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-1'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-2'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-1'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <img
                style={{
                  maxWidth: '20px',
                  height: 'auto',
                  position: 'unset',
                  display: 'block',
                }}
                className='ms-2'
                src={
                  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FgrayTache.png?alt=media&token=115186c6-e89a-4c38-8030-5137fcc97f9c'
                }
                alt='X'
              />
            </div>
          </Col>
          <Col xs={2} className='ps-0 pe-0'>
            <div className='justify-content-start ps-3'>
              <div className='d-flex flex-row p-0 ms-2 w-100'>
                <img
                  style={{
                    maxWidth: '20px',
                    height: 'auto',
                    position: 'unset',
                    display: 'block',
                  }}
                  className='ms-3'
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92'
                  }
                  alt='X'
                />
                <p className='w-auto mb-0 ps-2' style={{ fontWeight: '700' }}>
                  (II)
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </StyledRowExample>
      <div className='position-relative mb-3'>
        <StyledRowDown className='pb-2'>
          <Col xs={3} className='text-col'>
            <div>Nombre de la terapia blanco asociada al gen alterado.</div>
          </Col>
          <Col xs={6} className='text-col'>
            <div>
              Organismos Internacionales que aprueban dichas terapias en éste u
              otros tipos de Neoplasias.
            </div>
          </Col>
          <Col xs={3} className='right justify-content-center p-0'>
            <Col xs={12} className='text-col pe-0 ps-2'>
              <div>Fases de los ensayos clínicos asociados a los genes.</div>
            </Col>
          </Col>
        </StyledRowDown>
      </div>
    </>
  );
};
