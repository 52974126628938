import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GeneModal } from './geneModal';
import PlantImg from '../../assets/img/report/plant.png';

const Results = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FExample1.png?alt=media&token=400f5e98-592e-41b1-b29a-58c2cd7076c2';
const Therapies = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FExample2.png?alt=media&token=0e313209-296c-41ef-883b-7ef25a5bb3db';
const Trials = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FExample3.png?alt=media&token=7d1f50df-bfe9-4194-b07e-9e3367fc6482';

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin: 0;
  padding: 0;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #353433;
  margin: 0;
  padding: 0;
`;

const AxionaDocuments = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  .first {
    max-width: 410px;
    /* position: absolute; */
    /* left: -20px; */
    /* right: 0; */
    margin-left: auto;
    margin-right: auto;
    transition: all 300ms;
  }
  .second {
    transition: all 300ms;
    max-width: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .third {
    transition: all 300ms;
    max-width: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .mask {
    max-width: 350px;
  }
  @media only screen and (max-width: 992px) {
    .second {
      top: 5%;
    }
    .third {
      top: 5%;
    }
  }
  @media only screen and (max-width: 768px) {
    .first {
      max-width: 380px;
      height: auto;
    }
    .second {
      max-width: 320px;
      height: auto;
    }
    .third {
      max-width: 320px;
      height: auto;
    }
    .mask {
      max-width: 320px;
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 400px) {
    .first {
      max-width: 100%;
      height: auto;
    }
    .second {
      max-width: 85%;
      height: auto;
    }
    .third {
      max-width: 85%;
      height: auto;
    }
    .mask {
      max-width: 85%;
      width: auto;
      height: auto;
    }
  }
`;

const ContainerImg = styled.img`
  background-color: rgba(0, 0, 0, 0);
  fill: rgba(0, 0, 0, 0);
  opacity: 0;
`;

const DocumentImg = styled.img`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.selected === 1 ? '#EAAC34' : '#1379BC')};
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  margin: 0;
  width: auto;
  outline: none;
  border: none;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #ffffff;
    background-color: #eaac34;
  }
  @media only screen and (max-width: 992px) {
    max-width: 200px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 240px;
  }
`;

const StepText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  text-align: left;
`;

const DocumentInfo = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353433;
  max-width: 460px;
  margin-bottom: 0;
`;

const DownloadTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin: 0;
  padding: 0;
  max-width: 650px;
`;

const DownloadBtn = styled.a`
  background: #eaac34;
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #353433;
  text-align: center;
  margin: 0;
  max-width: 215px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #1379bc;
  }
`;

const Plant = styled.img`
  position: absolute;
  top: -150%;
  right: 0;
  max-width: 312px;
  padding: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
  @media only screen and (max-width: 1200px) {
    right: -3%;
    max-width: 270px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 170px;
    right: -5%;
    top: -180%;
  }
  @media only screen and (max-width: 768px) {
    position: unset;
    max-width: 176px;
    margin-right: -10px;
  }
`;

export const InfoGene = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [documentSelected, setDocumentSelected] = useState(0);
  const [modalSelected, setModalSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 992;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Container className={`pt-${isMobile ? '4' : '5'} pb-5`}>
      <Row className='mb-5'>
        <Title>{t('gene.info.title')}</Title>
      </Row>
      <Row className='mb-5'>
        <Desc data-aos="fade-up">{t('gene.info.desc')}</Desc>
      </Row>
      {width <= 992 ? (
        <>
          <Row data-aos="fade-up" className='mb-3'>
            <StepText className='ps-4 mb-2'>
              {t('gene.info.steps.first')}
            </StepText>
            <StyledButton
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 1 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(1);
                setModalSelected(1);
                setShowModal(true);
              }}
            >
              {t('gene.info.buttons.first')}
            </StyledButton>
          </Row>
          <Row data-aos="fade-up" className='mb-3'>
            <StepText className='ps-4 mb-2'>
              {t('gene.info.steps.second')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '237px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 2 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(2);
                setModalSelected(2);
                setShowModal(true);
              }}
            >
              {t('gene.info.buttons.second')}
            </StyledButton>
          </Row>
          <Row data-aos="fade-up" className='mb-4'>
            <StepText className='ps-4 mb-2'>
              {t('gene.info.steps.third')}
            </StepText>
            <StyledButton
              style={{
                maxWidth: '290px'
              }}
              className='pt-2 pe-4 pb-2 ps-4'
              selected={documentSelected === 3 ? 1 : 0}
              onClick={() => {
                setDocumentSelected(3);
                setModalSelected(3);
                setShowModal(true);
              }}
            >
              {t('gene.info.buttons.third')}
            </StyledButton>
          </Row>
          <Row className='mb-4 pb-2'>
            <AxionaDocuments>
              <DocumentImg
              src={documentSelected === 3 ? Trials : documentSelected === 2 ? Therapies : Results}
              alt='Documento de ejemplo'
              className='first'
            />
            </AxionaDocuments>
          </Row>
          <Row className='justify-content-center position-relative mb-4 pb-2'>
            <DocumentInfo data-aos="fade-up">{t('gene.info.document-info')}</DocumentInfo>
          </Row>
        </>
      ) : (
        <Row className='mb-5'>
          <AxionaDocuments className='pt-4 pb-4'>
            <DocumentImg
              src={documentSelected === 3 ? Trials : documentSelected === 2 ? Therapies : Results}
              alt='Documento de ejemplo'
              className='first'
            />
            <ButtonsContainer>
              <Col xs={12}>
                <Row className='h-100 justify-content-between'>
                  <Col md={5}>
                    <div className='h-100 d-flex flex-column justify-content-around'>
                      <Row data-aos="fade-up">
                        <StepText className='ps-4 mb-2'>
                          {t('gene.info.steps.first')}
                        </StepText>
                        <StyledButton
                          className='pt-2 pe-4 pb-2 ps-4'
                          selected={documentSelected === 1 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(1)}
                          onClick={() => {
                            setModalSelected(1);
                            setShowModal(true);
                          }}
                        >
                          {t('gene.info.buttons.first')}
                        </StyledButton>
                      </Row>
                      <Row data-aos="fade-up">
                        <StepText className='ps-4 mb-2'>
                          {t('gene.info.steps.second')}
                        </StepText>
                        <StyledButton
                          style={{
                            maxWidth: '237px'
                          }}
                          className='pt-2 pe-4 pb-2 ps-4'
                          selected={documentSelected === 2 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(2)}
                          onClick={() => {
                            setModalSelected(2);
                            setShowModal(true);
                          }}
                        >
                          {t('gene.info.buttons.second')}
                        </StyledButton>
                      </Row>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className='h-100 d-flex flex-column justify-content-around'>
                      <Row className='justify-content-end' data-aos="fade-up">
                        <StepText
                          className='ps-4 mb-2'
                          style={{ maxWidth: '290px', textAlign: 'left' }}
                        >
                          {t('gene.info.steps.third')}
                        </StepText>
                        <StyledButton
                          style={{
                            maxWidth: '290px'
                          }}
                          className='pt-2 pe-4 pb-2 ps-4'
                          selected={documentSelected === 3 ? 1 : 0}
                          onMouseEnter={() => setDocumentSelected(3)}
                          onClick={() => {
                            setModalSelected(3);
                            setShowModal(true);
                          }}
                        >
                          {t('gene.info.buttons.third')}
                        </StyledButton>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </ButtonsContainer>
          </AxionaDocuments>
          <Row className='mt-4 justify-content-center position-relative mb-3'>
            <Plant src={PlantImg} alt='Ilustración de planta' />
            <DocumentInfo data-aos="fade-up">{t('gene.info.document-info')}</DocumentInfo>
          </Row>
        </Row>
      )}
      <Row className='justify-content-center mb-4'>
        <DownloadTitle>{t('gene.info.download-title')}</DownloadTitle>
      </Row>
      <Row className='justify-content-center'>
        <DownloadBtn
          className='pt-2 pe-4 pb-2 ps-4'
          target='_blank'
          href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FREPORTE%20EJEMPLO%20AXIONA%20GENE%2023.pdf?alt=media&token=8ef9f8f1-b386-4405-a4e4-1289059a2dfc'
          rel='noreferrer noopener'
        >
          {t('gene.info.download-btn')}
        </DownloadBtn>
      </Row>
      {isMobile && (
        <Row className='justify-content-end mt-2 position-relative' style={{overflowX: 'hidden'}}>
          <Plant src={PlantImg} alt='Ilustración de planta' />
        </Row>
      )}
      <GeneModal
        show={showModal}
        setShowModal={setShowModal}
        modalSelected={modalSelected}
      />
    </Container>
  );
};
