export const GenesList = {
  'hotspots': [
    {
      name: 'ABL1',
    },
    {
      name: 'ANKRD26',
    },
    {
      name: 'BRAF',
    },
    {
      name: 'CBL',
    },
    {
      name: 'CSF3R',
    },
    {
      name: 'DDX41',
    },
    {
      name: 'DNMT3A',
    },
    {
      name: 'FLT3 (ITD, TKD)',
    },
    {
      name: 'GATA2',
    },
    {
      name: 'HRAS',
    },
    {
      name: 'IDH1',
    },
    {
      name: 'IDH2',
    },
    {
      name: 'JAK2',
    },
    {
      name: 'KIT',
    },
    {
      name: 'KRAS',
    },
    {
      name: 'MPL',
    },
    {
      name: 'MYD88',
    },
    {
      name: 'NPM1',
    },
    {
      name: 'NRAS',
    },
    {
      name: 'PPM1D',
    },
    {
      name: 'PTPN11',
    },
    {
      name: 'SETBP1',
    },
    {
      name: 'SF3B1',
    },
    {
      name: 'SMC1A',
    },
    {
      name: 'SMC3',
    },
    {
      name: 'SRSF2',
    },
    {
      name: 'U2AF1',
    },
    {
      name: 'WT1',
    },
  ],
  'complete': [
    {
      name: 'ASXL1',
    },
    {
      name: 'BCOR',
    },
    {
      name: 'CALR',
    },
    {
      name: 'CEBPA',
    },
    {
      name: 'ETV6',
    },
    {
      name: 'EZH2',
    },
    {
      name: 'IKZF1',
    },
    {
      name: 'NF1',
    },
    {
      name: 'PHF6',
    },
    {
      name: 'PRPF8',
    },
    {
      name: 'RB1',
    },
    {
      name: 'RUNX1',
    },
    {
      name: 'SH2B3',
    },
    {
      name: 'STAG2',
    },
    {
      name: 'TET2',
    },
    {
      name: 'TP53',
    },
    {
      name: 'ZRSR2',
    },
  ],
  'fusion': [
    {
      name: 'ABL1',
    },
    {
      name: 'ABL2',
    },
    {
      name: 'BCL2',
    },
    {
      name: 'BRAF',
    },
    {
      name: 'CCND1',
    },
    {
      name: 'CREBBP',
    },
    {
      name: 'EGFR',
    },
    {
      name: 'ETV6',
    },
    {
      name: 'FGFR1',
    },
    {
      name: 'FGFR2',
    },
    {
      name: 'FUS',
    },
    {
      name: 'HMGA2',
    },
    {
      name: 'JAK2',
    },
    {
      name: 'KAT6B',
    },
    {
      name: 'KAT6B (MOZ)',
    },
    {
      name: 'KMT2A',
    },
    {
      name: 'KMT2A PTDs',
    },
    {
      name: 'MECOM',
    },
    {
      name: 'MET',
    },
    {
      name: 'MLLT10',
    },
    {
      name: 'MRTFA (MKL1)',
    },
    {
      name: 'MYBL1',
    },
    {
      name: 'MYH11',
    },
    {
      name: 'NTRK2',
    },
    {
      name: 'NTRK3',
    },
    {
      name: 'NUP214',
    },
    {
      name: 'NUP98',
    },
    {
      name: 'PAX5',
    },
    {
      name: 'PDGFRA',
    },
    {
      name: 'PDGFRB',
    },
    {
      name: 'RARA',
    },
    {
      name: 'RUNX1',
    },
    {
      name: 'TCF3',
    },
    {
      name: 'TFE3',
    },
    {
      name: 'ZNF384',
    },
  ],
};