import React from 'react';
import { Banner } from './banner';
import { Axiona } from './axiona';
import { Helmet } from "react-helmet";

export const Patients = () => {
  return(
    <>
    <Helmet>
            <title>Pacientes</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <Banner />
      <Axiona />
    </>
  );
}