import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BannerBackground = styled.div`
  width: 100%;
  height: 460px;
  box-shadow: rgba(10, 10, 10, 0.4) 0px 4px 10px;
  overflow: hidden;
  background: #ffffff;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38" center no-repeat);
    height: 480px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    img {
      width: 700px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 768px) {
        width: 280px;
        left: auto;
        right: -10px;
        bottom: 110px;
        transform: translate(0, 0);
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 460px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  color: #1379BC;
  text-align: center;
  max-width: 600px;
  margin: 0;
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 280px;
    font-size: 35px;
    margin-bottom: 20px !important;
  }
`;

const SubT = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  color: #1C3245;
  text-align: center;
  max-width: 600px;
  margin: 0;

  @media only screen and (max-width: 576px) {
    max-width: 320px;
    font-size: 25px;
    margin-bottom: 20px !important;
  }
`;

const SubT1 = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #1C3245;
  text-align: center;
  max-width: 600px;
  margin: 0;

  @media only screen and (max-width: 576px) {
    max-width: 320px;
    font-size: 25px;
    margin-bottom: 20px !important;
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background-color: #ebab34;
  margin-right: 120px;
  border-radius: 10px;
  border: none;
  text-align: center;
  max-width: 350px;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    margin-top: 0px;
    max-width: 280px;
    font-size: 19px;
  }
`;

export const Liquidbanner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  const [t, i18n] = useTranslation('global');
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <BannerBackground>
      <Container>
        <Row>
          <DescColumn
            xs={12}
            md={7}
            className={
              isTablet ? 'justify-content-start mt-5' : 'justify-content-center'
            }
          >
            <Container data-aos='fade-up'>
              <Title className={isMobile ? 'mb-2 ms-auto me-auto' : 'mb-4'}>
                {t('home.banner.title1')}
              </Title>
              <SubT>Panel multi-cáncer en biopsia líquida.
              <SubT1>
                Prueba de precisión mínimamente invasiva.
              </SubT1>
              </SubT>
              <Desc onClick={()=> window.location.href = "/contact-liquid"} className={isMobile ? 'ms-auto me-auto' : 'mb-4'}>Quiero más información</Desc>
            </Container>
          </DescColumn>
          <Col
            xs={12}
            md={5}
            id="col-img"
          >
            <img
              alt='analisis'
              src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FIlustracion%201.png?alt=media&token=56cf1192-61f1-4afd-aecf-2b6f5ec5725e'
            />
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
};
