import React from "react";
import { AboutGene } from "./aboutGene";
import { Banner } from './banner';
import { GenesTable } from './genes';
import { GeneSlider } from "./geneSlider";
import { InfoGene } from './infoGene';
import { VideoGene } from './videoGene';
import { Helmet } from "react-helmet";

export const AxionaGene = () => {
  return(
    <>
    <Helmet>
            <title>Axiona® GENE</title>
            <meta 
              name='description'
              content='content'
            />
          </Helmet>
      <Banner />
      <VideoGene />
      <AboutGene />
      <GeneSlider />
      <GenesTable />
      <InfoGene />
    </>
  );
}