import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-bootstrap';
import BannerBg from '../../assets/backgrounds/doctors/bannerbg.webp';
import BannerMobile from '../../assets/backgrounds/doctors/bannerbgmobile.webp';

const Background = styled.div`
  width: 100%; 
  background: url(https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fmedicos%2Fbannerbg.webp?alt=media&token=3ec3f150-2fb5-418e-baf6-79922dcfe8ab);
  background-size: cover;
  height: 460px;
  @media only screen and (max-width: 768px){
    height: 400px;
  }
`;

const BannerContent = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 90%;
  margin-left: 5%;
`;

const BannerTitle = styled.p`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px){
    font-size: 40px;
    line-height: 48px;
  }
`;

const BannerDesc = styled.p`
  background-color: #EBAB34;
  max-width: 564px;
  padding: 40px 62px;
  gap: 40px;
  border-radius: 20px 20px 0px 20px;
  text-align: left;
  #description {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    color: white;
    max-width: 426px;
    @media only screen and (max-width: 768px){
      font-size: 19px;
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 768px){
    padding: 40px 32px 32px;
  }
`;
  
  const CallToAction = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #1C3245;
  background: #EAAC34;
  padding: 17px 36px;
  @media only screen and (max-width: 768px){
    padding: 27px 36px;
  }
  a {
    color: #1C3245;
    text-decoration: underline;
  }
`;

export const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
         window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return(
    <>
      <Background>
        <BannerContent xs={12} sm={12} md={10} lg={8} xl={8} data-aos='fade-up' className="d-flex justify-content-center">
          <BannerDesc>
            <BannerTitle>Una nueva era en oncología</BannerTitle>
            <p id="description">
              Conozca los beneficios de Axiona® para su paciente.
              La nueva plataforma para diagnóstico molecular de 
              América Latina.
            </p>
          </BannerDesc>
        </BannerContent>
      </Background>
      <CallToAction>¿Aún no es parte de Axiona®? <a target='_blank' href='https://solicitudes.axionagenetics.com/' rel='noreferrer'>Regístrese aquí</a></CallToAction>
    </>
  );
}