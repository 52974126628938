import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const Slide0 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide.png?alt=media&token=3ac73ff7-354e-47fc-aa5c-e7e1f2363769';
const Slide1 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide1.png?alt=media&token=cb011717-d610-4eac-80d7-80cd3dafc89d';
const Slide2 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide2.png?alt=media&token=e1e45e89-5ea5-4f1e-a929-720f53bb85fa';
const Slide3 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide3.png?alt=media&token=920685f6-542a-4cc3-910a-e0c40d7145c9';
const Slide4 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide4.png?alt=media&token=a530b94d-0aca-47ff-88cd-624a96542bd1';
const Slide5 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide5.png?alt=media&token=964b2813-6ec6-4fe8-935f-db4b7f01cf29';
const Slide6 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide6.png?alt=media&token=8a012ea8-7f15-42b4-9c41-5e77e7639ce2';
const Slide7 = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide7.png?alt=media&token=e9462b2a-8f52-429d-b750-b32c9ee02ee3';

const SectionContainer = styled.div`
  background-color: #f2f7f7;
`;

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #1c3245;
  margin: 0;
  padding: 0;
  max-width: 544px;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 544px;
`;

const StyledSlider = styled(Slider)`
  .row {
    display: flex !important;
  }
  .slick-list {
    margin-bottom: 15px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
  color: #1E1E1E;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  max-width: 500px;
  background-color: #FFFFFF;
  border-radius: 40px;
  img {
    border-radius: 40px;
  }
`;

const SlideText = styled.p`
  font-family: 'Warbler Text';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #353433;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const SlideTextContainer = styled.div`
  max-width: 270px;
`;

const SlideNumberDiv = styled.span`
  background: #EAAC34;
  border-radius: 50%;
  width: auto;
  padding: 8px 20px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #1C3245;
`;

const StyledArrow = styled.div`
  ::before {
    color: #000000
  }
`;

export const SliderHem = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [t, i18n] = useTranslation('global');
  const Items = [
    {
      image: Slide0,
      text: t('hem.slider.slide-zero'),
    },
    {
      image: Slide1,
      text: t('hem.slider.slide-one'),
    },
    {
      image: Slide2,
      text: t('hem.slider.slide-two'),
    },
    {
      image: Slide3,
      text: t('hem.slider.slide-three'),
    },
    {
      image: Slide4,
      text: t('hem.slider.slide-four'),
    },
    {
      image: Slide5,
      text: t('hem.slider.slide-five'),
    },
    {
      image: Slide6,
      text: t('hem.slider.slide-six'),
    },
    {
      image: Slide7,
      text: t('hem.slider.slide-seven'),
    },
  ];

  const CustomArrow = (props) => {
    const {className, style, onClick} = props;
    return(
      <>
        {!isMobile && (
          <StyledArrow
            className={className}
            style={{...style, display: 'block'}}
            onClick={onClick}
          />
        )}
      </>
    );
  }

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <SectionContainer className='pt-5 pb-5'>
      <Container className='pt-3 pb-3'>
        <Row className={`${isMobile ? 'mb-4' : 'mb-5'}`}>
          <Col xs={12}>
            <Row className='justify-content-center mb-4'>
              <Title>
                {t('hem.how-it-works.title')}
              </Title>
            </Row>
            <Row className='justify-content-center'>
              <Desc data-aos="fade-up">
                {t('hem.how-it-works.desc')}
              </Desc>
            </Row>
          </Col>
        </Row>
        <Row data-aos="fade-up">
          <StyledSlider {...settings} className='pt-4 pb-4'>
            {Items.map((item, index) => (
              <Row key={index}>
                <Col xs={12} sm={12} md={6} className='mb-4'>
                  <ImageContainer>
                    <img
                      src={item.image}
                      alt={`Ilustración de paso ${index + 1}`}
                      className='img-fluid'
                    />
                  </ImageContainer>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Row className='align-items-center justify-content-center h-100'>
                    <SlideTextContainer>
                      <div className='mb-3 d-flex flex-row justify-content-start'>
                        <SlideNumberDiv>
                          {index + 1}
                        </SlideNumberDiv>
                      </div>
                      <div className='w-auto'>
                        <SlideText>
                          {item.text}
                        </SlideText>
                      </div>
                    </SlideTextContainer>
                  </Row>
                </Col>
              </Row>
            ))}
          </StyledSlider>
        </Row>
      </Container>
    </SectionContainer>
  );
};
