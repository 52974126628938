import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Col,
  Row,
  Modal,
  ModalBody,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { IoMdClose } from 'react-icons/io';

const StyledModal = styled(Modal)`
  .large {
    max-width: 950px;
    @media only screen and (max-width: 1200px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: hidden;
    padding-bottom: 22px;
    @media only screen and (max-width: 768px) {
      overflow-x: scroll;
      border-radius: 40px 40px 40px 40px !important;
      ::-webkit-scrollbar {
        background-color: #f9f9f9!important;
        height:0px !important;
        -webkit-overflow-scrolling: auto !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const TitleContainer = styled.div`
  background: #1379bc;
  border-radius: 40px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    max-width: 920px;
    width: 820px;
  }
`;

const DetailModal = styled.img`
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TrialsFooterContainer = styled.div`
  background-color: #EAAC34;
  border-radius: 0px 0px 40px 40px;
  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

const FooterText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 810px;
`;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px !important;
  background: #FFFFFF;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  // z-index: 10;
  .header {
    background: #1379BC;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #EAAC34;
  }
  .black-text, .yellow-text-bold, .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold, .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #EAAC34;
  }
  .blue-text-bold {
    color: #1379BC !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
`;

const DropdownDiv = styled.div`
  position: absolute;
  width: 83%;
  margin-left: 16.66%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 60px 26px 50px;
  z-index: 10;
  h1, p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #353433;
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      height: 104px;
      padding: 5px 11px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #1379BC;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 77%, rgba(255,255,255,0) 100%);
      width: 100%;
      min-height: 165px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379BC;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

const Icon = styled.img`
  margin-top: 8px;
  max-width: ${props => props.lg === 1 ? '24px' : '18px'};
  max-height: ${props => props.lg === 1 ? '24px' : '18px'};
  width: auto;
  padding: 0;
`;

const IconDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin-bottom: 0;
  padding: 0;
  width: auto;
`;

const DetailsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  padding-top: 21px;
  padding-bottom: 21px;
  margin-bottom: 45px;
`;

const DetailsSection = styled.div`
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const DetailTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin-bottom: 17px;
  padding: 0;
`;

const DetailText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin-bottom: 0;
  padding: 0;
`;

const DescBullet = styled.div`
  background: #1379BC;
  border-radius: 200px;
  max-width: 10px;
  max-height: 10px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 8px;
`;

export const GeneModal = (props) => {
  const [t, i18n] = useTranslation('global');
  const [openSecondCol, setOpenSecondCol] = useState(false);
  const [openThirdCol, setOpenThirdCol] = useState(false);
  const [openFourthCol, setOpenFourthCol] = useState(false);

  const IconsMean = [
    {
      alt: 'incierto',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FInciertoIcon.png?alt=media&token=ee946822-0e62-47e5-8d2e-f1445104792f',
      title: '*Variante de significado incierto: ',
      desc: 'Cambio en la secuencia de ADN de un gen cuyo efecto en la salud no se conoce en el momento de su clasificación.'
    },
    {
      alt: 'patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FPatogenicaIcon.png?alt=media&token=9840b228-bc1b-4696-b549-6a6abf12ed92',
      title: '*Variante patogénica: ',
      desc: 'Alteración genética que aumenta la susceptibilidad o predisposición a padecer cáncer hereditario.'
    },
    {
      alt: 'probabilidad patogenica',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FProbPatogenicaIcon.png?alt=media&token=44ba50ad-1448-41f0-abfe-ab4c1739c38c',
      title: '*Variante probablemente patogénica: ',
      desc: 'Alteración genética que probablemente sea responsable de causar cáncer hereditario, pero no hay suficiente evidencia científica para aseverarlo.'
    },
    {
      alt: 'interpretaciones contradictorias',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionagene%2FContradictoriasIcon.png?alt=media&token=27c28d1e-a615-488f-b0f7-286711207aa7',
      title: '*Interpretaciones contradictorias de la patogenicidad: ',
      desc: 'Definición asignada por ClinVar a las variantes con resultados contradictorios que no pueden ser clasificadas usando los términos recomendados por la ACMG/AMP para describir la patogenicidad y aquellos recomendados por ClinGen para clasificar los alelos de riesgo y variantes mendelianas de baja penetrancia.'
    },
  ];

  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <StyledRow className='pt-4 ps-4 pe-4 pb-0'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={3} className='ps-0'>
                <TitleContainer>
                  <StyledModalTitle>
                    {t('gene.modals.results.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={8} lg={8} xl={8} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc>
                    {t('gene.modals.results.content-one')}
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>{t('gene.modals.results.example')}</ExampleText>
                </Row>
              </Col>
            </StyledRow>
            <StyledRowExample className='pb-4'>
              <Col xs={12} className="header">Resumen de resultados</Col>
              <Col xs={2} className="header-col"><div>GEN</div></Col>
              <Col xs={10} className="right">
                <Col xs={3} className="header-col"><div>VARIANTE</div></Col>
                <Col xs={3} className="header-col"><div>FRECUENCIA ALÉLICA</div></Col>
                <Col xs={3} className="header-col"><div>CIGOSIDAD</div></Col>
                <Col xs={3} className="header-col"><div>CLASIFICACIÓN DE <br/>LA VARIANTE</div></Col>
              </Col>
              <Col xs={2} className="info-col">FANCG</Col>
              <Col xs={10} className="right">
                <Col xs={3} className="info-col">c.1156C{'>'}G (p.Pro386Ala)</Col>
                <Col xs={3} className="info-col">42.05 %</Col>
                <Col xs={3} className="info-col">Heterocigoto</Col>
                <Col xs={3} className="info-col black-text position-relative">Significado incierto &nbsp;
                  <Icon 
                    src={IconsMean[0].icon} 
                    alt={`Icono ${IconsMean[0].alt}`}
                    lg={0}
                  />
                </Col>
              </Col>
              <hr/>
              <Col xs={2} className="info-col">HNF1A</Col>
              <Col xs={10} className="right">
                <Col xs={3} className="info-col yellow-text">c.864delG<br/>(p.Pro291GlnfsTer51){!openSecondCol && <UilAngleDown size={36} color="#EAAC34" className="botoncito" 
                  onClick={()=>{
                    setOpenFourthCol(false);
                    setOpenThirdCol(false);
                    setOpenSecondCol(true);
                  }}/>}
                </Col>
                <Col xs={3} className="info-col">40.16 %</Col>
                <Col xs={3} className="info-col">Heterocigoto</Col>
                <Col xs={3} className="info-col d-flex flex-direction-column">
                  <Row>
                    <Col xs={12} className="info-col yellow-text-bold position-relative">
                      PATOGÉNICA &nbsp;
                      <Icon 
                        src={IconsMean[1].icon} 
                        alt={`Icono ${IconsMean[1].alt}`}
                        lg={0}
                      />
                    </Col>
                    <Col xs={12} className="info-col blue-text-bold position-relative">
                      /PROB. PATOGÉNICA &nbsp;
                      <Icon 
                        src={IconsMean[2].icon} 
                        alt={`Icono ${IconsMean[2].alt}`}
                        lg={0}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
              {openSecondCol &&
                <div style={{position: "relative", padding: "0"}}>
                  <DropdownDiv>
                    <UilAngleUp size={36} color="#EAAC34" className="botoncito" onClick={()=>{
                      setOpenSecondCol(false);
                    }}/>
                    <h1>[c. xxxx xxxx (p.xxx ### xxx)]</h1>
                    <p>En el reporte se utiliza una nomenclatura que describe el lugar donde ocurre el cambio o alteración de la(s) variante(s) dentro del gen, se lee de izquierda a derecha, comenzando por la letra "c.", que se significa codón (ADN codificante), luego, la posición de la mutación dentro del gen "1156" y después describe el tipo de mutación; en caso de ser una sustitución se señala el cambio en los pares de base nitrogenada, en este caso, fue un cambio de una C por una G (es decir, una citosina por una guanina) descrito en la abreviación como "C{'>'}G", siendo la primer letra la base habitualmente encontrada en ese sitio, y la segunda letra la base por la cual se sustituyó en este caso. En consiguiente se describe el sitio de cambio a nivel proteína "(p.Pro368Ala)" siendo Pro (prolina) y Ala (alanina) abreviaciones universales pare el nombre de los aminoácidos (https://www.genscript.com/Amino Acid Code.html) ,dónde los números entre ellos se refieren al sitio de la alteración.</p>
                  </DropdownDiv>
                </div>
              }
            </StyledRowExample>
            <div className='position-relative'>
              <StyledRowDown className='pb-4'>
                <Col xs={2} className="text-col">
                  <div>Gen que se encontró alterado.</div>
                </Col>
                <Col xs={10} className="right">
                  <Col xs={3} className="text-col"><div>Alteración detectada en la secuencia del ADN y el cambio de la proteína resultante.</div></Col>
                  <Col xs={3}/>
                  <Col xs={3}/>
                  <Col xs={3} className="text-col"><div>El tipo de la variante encontrada.</div></Col>
                </Col>
              </StyledRowDown>
              <StyledRowDown className='pb-4' style={{position: "absolute", top: "0", zIndex: "6"}}>
                <Col xs={2}/>
                <Col xs={10} className="right">
                  <Col xs={3}/>
                  <Col xs={3} className="text-col">
                    <div style={openThirdCol ? {height: "auto", alignItems: "end", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px"} : {}}>
                      {openThirdCol ? <>
                        <p>Se refiere a la métrica para describir que tan común es la presencia de un alelo (un alelo es la manifestación de una versión de un gen) con respecto a una población determinada.</p>
                        <UilAngleUp size={36} color="#EAAC34" className="botoncito" onClick={()=>{
                          setOpenThirdCol(false);
                        }}/>
                      </> : <>
                        <p>Se refiere a la métrica para describir que tan común es la presencia...</p>
                        <UilAngleDown size={36} color="#EAAC34" className="botoncito" onClick={()=>{
                          setOpenFourthCol(false);
                          setOpenThirdCol(true);
                          setOpenSecondCol(false);
                        }}/>
                      </>}
                    </div>
                  </Col>
                  <Col xs={3} className="text-col">
                    <div style={openFourthCol ? {height: "auto", alignItems: "end", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px"} : {}}>
                      {openFourthCol ? <>
                        <p>Tipo de alelo, dicho gen puede ser heterocigoto u homocigoto, si es homocigoto quiere decir que se cuenta con la misma versión de alelo heredado tanto por la madre como del padre biológico; si es heterocigoto, quiere decir que existe diferencia entre la versión del alelo heredado por parte de alguno de los progenitores.</p>
                        <UilAngleUp size={36} color="#EAAC34" className="botoncito" onClick={()=>{
                          setOpenFourthCol(false);
                        }}/>
                      </> : <>
                        <p>Tipo de alelo, dicho gen puede ser heterocigoto u homocigoto, si es...</p>
                        <UilAngleDown size={36} color="#EAAC34" className="botoncito" onClick={()=>{
                          setOpenFourthCol(true);
                          setOpenThirdCol(false);
                          setOpenSecondCol(false);
                        }}/>
                      </>}
                    </div>
                  </Col>
                  <Col xs={3}/>
                </Col>
              </StyledRowDown>
            </div>
            <div style={{padding: "0", opacity: openSecondCol || openThirdCol || openFourthCol ? ".3" : "1"}}>
              {IconsMean.map((item, index) => (
                <StyledRow className='align-items-start pt-0 ps-4 pe-4 pb-0 justify-content-between' key={index}>
                  <Icon 
                    src={item.icon} 
                    alt={`Icono ${item.alt}`}
                    lg={index === 3 ? 1 : 0}
                  />
                  <div className='w-auto p-0' style={{maxWidth: '95%'}}>
                    <IconDesc>
                      <b>
                        {item.title}
                      </b>
                      {item.desc}
                    </IconDesc>
                  </div>
                </StyledRow>
              ))}
            </div>
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <Row className='p-4 pb-0 mb-3'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={5} className='ps-0 h-auto'>
                <TitleContainer className='h-auto' style={{paddingTop: '34px', paddingBottom: '34px'}}>
                  <StyledModalTitle style={{maxWidth: '295px'}}>
                    {t('gene.modals.therapies.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={6} lg={6} xl={6} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc>
                    {t('gene.modals.therapies.content-one')}
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>{t('gene.modals.therapies.example')}</ExampleText>
                </Row>
              </Col>
            </Row>
            <DetailsCard>
              <DetailsSection>
                <DetailTitle>
                  {'FANCG, c.1156C>G (p.Pro386Ala), Heterocigoto, Significado Incierto.'}
                </DetailTitle>
                <StyledRow className='align-items-start justify-content-between'>
                  <DescBullet/>
                  <div className='p-0 w-auto' style={{maxWidth: '97%'}}>
                    <DetailText>
                      Este cambio de secuencia reemplaza a la prolina por alanina en el codon 386 de la proteína del grupo G de anemia de Fanconi (FANCG). El residuo de prolina está poco conservado y existe una pequeña diferencia fisicoquímica entre la prolina y la alanina...
                    </DetailText>
                  </div>
                </StyledRow>
              </DetailsSection>
            </DetailsCard>
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby='results-modal'
          dialogClassName='large'
          backdropClassName='background'
          contentClassName='content'
        >
          <StyledModalBody>
            <Row className='p-4 pb-0 mb-3'>
              <CloseBtn onClick={() => props.setShowModal(!props.show)}>
                <IoMdClose />
              </CloseBtn>
              <Col xs={4} sm={4} md={5} className='ps-0 h-auto'>
                <TitleContainer className='h-auto' style={{paddingTop: '18px', paddingBottom: '18px'}}>
                  <StyledModalTitle style={{maxWidth: '295px'}}>
                    {t('gene.modals.trials.title')}
                  </StyledModalTitle>
                </TitleContainer>
              </Col>
              <Col xs={7} sm={7} md={6} lg={6} xl={6} className='pe-0'>
                <Row className='mb-4 pe-3'>
                  <StyledModalDesc>
                    {t('gene.modals.trials.content-one')}
                  </StyledModalDesc>
                </Row>
                <Row>
                  <ExampleText>{t('gene.modals.therapies.example')}</ExampleText>
                </Row>
              </Col>
            </Row>
            <DetailsCard>
              <DetailsSection>
                <DetailTitle>
                  {'FANCG, c.1156C>G (p.Pro386Ala), Heterocigoto, Significado Incierto.'}
                </DetailTitle>
                <StyledRow className='align-items-start justify-content-between'>
                  <DescBullet/>
                  <div className='p-0 w-auto' style={{maxWidth: '97%'}}>
                    <DetailText>
                      Esta variante está relacionada con las siguientes enfermedades en OMIM: anemia de Fanconi, grupo de complementación G (614082).
                    </DetailText>
                  </div>
                </StyledRow>
              </DetailsSection>
            </DetailsCard>
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
