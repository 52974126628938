import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import Kit from '../../assets/img/aboutSolid/kit.png';
import Person from '../../assets/img/aboutSolid/person.png';
import PersonMobile from '../../assets/img/aboutSolid/personMobile.png';
import { FaPhoneAlt } from 'react-icons/fa';
import { UilDownloadAlt } from '@iconscout/react-unicons'

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #1C3245;
  margin: 0;
  padding: 0;
  max-width: 386px;
  text-align: left;
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const Subtitle = styled.h2`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1C3245;
  margin: 0;
  padding: 0;
  text-align: left;
  max-width: 386px;
`;

const Desc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 386px;
  text-align: left;
`;

const TargetText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  text-align: left;
  max-width: 386px;
`;

const RequestButtonContainer = styled.div`
  max-width: 386px;
  padding: 0;
`;

const RequestButton = styled.a`
  background: #1379BC;
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #FFFFFF;
  margin: 0;
  max-width: 213px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #FFFFFF;
    background-color: #1C3245;
  }
`;

const SpecsTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1C3245;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const SpecName = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #1C3245;
  margin: 0;
  padding: 0;
`;

const SpecDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #1C3245;
  margin: 0;
  padding: 0;
`;

const SpecsContainer = styled.div`
  background: #D9D9D9;
  border-radius: 20px 20px 0px 20px;
  max-width: 509px;
`;

const SpecHeme = styled.div`
text-decoration: underline;
text-align: center;
color: #1379BC;
> a {
font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 40px;
text-decoration: underline;
text-align: center;
color: #1379BC;
margin: 0;
padding: 0;
}
`;

const ContactSection = styled.div`
  background-color: #EAAC34;
`;

const PersonImg = styled.img`
  position: absolute;
  bottom: 0;
  left: -4%;
  max-width: 286px;
  max-height: 237px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
  @media only screen and (max-width: 1200px) {
    left: -7%;
  }
`;

const ContactDesc = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  max-width: 342px;
  text-align: left;
`;

const LineAxiona = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  text-align: left;
  max-width: 397px;
  > a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
  }
`;

const StyledImg = styled.img`
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
  -webkit-user-drag: none;
`;

const StyledSpan = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #EAAC34;
  color: white;
  padding: 0.5em;
  border-radius: 4px;
  margin-left: ${({tooltipPosition}) => tooltipPosition ?? 1}px;
  width: 250px;
  text-align: center;
  z-index: 2;
`;

const Tooltip = ({ text, children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(0);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
    const rect = e.target.getBoundingClientRect();
    const left = rect.left + rect.width / 2;
    const viewportWidth = window.innerWidth;
    if (left < 125) {
      setTooltipPosition(125 - left);
    }else if(left > (viewportWidth - 125)){
      setTooltipPosition((viewportWidth - 125) - left);
    }else{
      setTooltipPosition(0);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      {children}
      {isHovered && (
        <StyledSpan
          tooltipPosition={tooltipPosition}
          className="yellow-div"
        >
          {text}
        </StyledSpan>
      )}
    </span>
  );
};

export const AboutHem = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [t, i18n] = useTranslation("global");
  let isMobile = width <= 768;
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  
  return(
    <>
      <Container className={`${isMobile ? 'pt-5 pb-5' : 'pt-4 pb-4'}`}>
        <Row>
          <Col xs={12} sm={12} md={6} className='d-flex flex-column justify-content-center'>
            <Row className='mb-4 justify-content-start'>
              <Title>
                {t('hem.panel.title')}
              </Title>
            </Row>
            <Row className={`justify-content-start ${isMobile ? 'mb-5' : 'mb-4'}`}>
              <Desc data-aos="fade-up">
                {t('hem.panel.desc-one')}
                <b>          
                  {t('hem.panel.bold-one')} 
                  <br /><br />
                  <Tooltip style={{background:"#EAAC34"}} text="Leucemia Mieloide Aguda (AML, por sus siglas en inglés)"><span style={{color:"#1379BC", fontSize:"24px"}}> AML </span></Tooltip>  {' '}
                  <Tooltip style={{background:"#EAAC34"}} text="Síndrome mielodisplásico (MDS, por sus siglas en inglés)"><span style={{color:"#1379BC", padding:"10px", fontSize:"24px"}}> MDS </span></Tooltip>  {' '}
                  <Tooltip style={{background:"#EAAC34"}} text="Neoplasias mieloproliferativas (MPN, por sus siglas en inglés)"><span style={{color:"#1379BC", padding:"10px", fontSize:"24px"}}> MPN </span></Tooltip>  {' '}
                  <Tooltip style={{background:"#EAAC34"}} text="Leucemia Mielomonocítica Crónica (CMML, por sus siglas en inglés)"><span style={{color:"#1379BC", padding:"10px", fontSize:"24px"}}> CMML </span></Tooltip>  {' '}
                  <Tooltip style={{background:"#EAAC34"}} text="Leucemia mielomonocítica juvenil (JMML, por sus siglas en inglés)"><span style={{color:"#1379BC", fontSize:"24px"}}> JMML </span></Tooltip>
                </b>
                <br/><br/>
                {t('hem.panel.desc-two')}
                {/* <b>
                  {t('hem.panel.bold-two')}
                </b>
                {t('hem.panel.desc-three')} */}
              </Desc>
            </Row>
            <Row className='justify-content-start mb-4'>
              <Subtitle data-aos="fade-up">
                {t('hem.panel.subtitle')}
              </Subtitle>
            </Row>
            <Row className={`justify-content-start ${isMobile ? 'mb-5' : 'mb-4'} pb-2`}>
              <TargetText data-aos="fade-up">
                {t('hem.panel.target-one')}
                <b>
                  {t('hem.panel.target-bold-one')}
                </b>
                {t('hem.panel.target-two')}
                <b>
                  {t('hem.panel.target-bold-two')}
                </b>
              </TargetText>
            </Row>
            {!isMobile && (
              <RequestButtonContainer>
                <Row className='justify-content-center' data-aos="fade-up">
                  <RequestButton 
                    className='pe-4 pt-2 ps-4 pb-2'
                    target='_blank'
                    href='https://solicitudes.axionagenetics.com/'
                    rel='noreferrer'
                  >
                    {t('hem.panel.request')}
                  </RequestButton>
                </Row>
              </RequestButtonContainer>
            )}
          </Col>
          <Col xs={12} sm={12} md={6}>
            {!isMobile && (
              <Row className='justify-content-center'>
                <StyledImg
                  src={"https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FKitHeme.png?alt=media&token=e39913b4-b3c0-4f84-b7d2-c8202c939a3d"}
                  alt='Imagen de kit axiona hem'
                  className='img-fluid'
                  data-aos="fade-up"
                />
              </Row>
            )}
            <Row className='mb-4'>
              <SpecsTitle>
                {t('hem.panel.specs.title')}
              </SpecsTitle>
            </Row>
            <Row className='justify-content-center mb-5' data-aos="fade-up">
              <SpecsContainer className='pe-3 ps-3 pt-2 pb-2'>
                <Row>
                  <SpecName>
                    {t('hem.panel.specs.spec-one.title')}
                  </SpecName>
                </Row>
                <Row>
                  <SpecDesc>
                    <span style={{color:"#EAAC34"}}>★ </span>{t('hem.panel.specs.spec-one.desc-one')}
                  </SpecDesc>
                  <SpecDesc>
                  <span style={{color:"#EAAC34"}}>★ </span>{t('hem.panel.specs.spec-one.desc-two')}
                  </SpecDesc>
                </Row>
                <Row>
                  <SpecName>
                    {t('hem.panel.specs.spec-two.title')}
                  </SpecName>
                </Row>
                <Row>
                  <SpecDesc>
                    {t('hem.panel.specs.spec-two.desc')}
                  </SpecDesc>
                </Row>
              </SpecsContainer>
              <Row>
                <SpecHeme> 
                  <a 
                    target='_blank'
                    href='https://drive.google.com/file/d/17fUfx1_HaIKiTfSFJ-2agYBSVMk0QgsC/view?usp=drive_link'
                    rel='noreferrer'><UilDownloadAlt/>Especificaciones kit Axiona® HEME</a>
                </SpecHeme>
                </Row>
            </Row>
            {isMobile && (
              <>
              <Row className='justify-content-center mb-5'>
                <StyledImg
                  src={"https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2FKitHeme.png?alt=media&token=e39913b4-b3c0-4f84-b7d2-c8202c939a3d"}
                  alt='Imagen de kit axiona gene'
                  className='img-fluid'
                />
              </Row>
              <Row className='justify-content-center'>
                <RequestButton 
                  className='pe-4 pt-2 ps-4 pb-2'
                  target='_blank'
                  href='https://solicitudes.axionagenetics.com/'
                  rel='noreferrer noopener'
                >
                  {t('hem.panel.request')}
                </RequestButton>
              </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <ContactSection data-aos="fade-up">
        <Container className={`pt-5 ${isMobile ? '' : 'pb-5'} position-relative`}>
          {!isMobile && (
            <PersonImg src={Person} alt='Ilustración de soporte técnico' />
          )}
          <Row className={`pt-3 pb-3 justify-content-end ${isMobile && 'mb-5'}`}>
            <Col xs={12} sm={12} md={8} lg={5} className='d-flex flex-column align-items-center mb-4'>
              <Row className={`w-100 justify-content-${width <= 992 ? 'start' : 'center'} mb-4`}>
                <Subtitle style={{color: '#FFFFFF', textAlign: 'left', maxWidth: '342px', width: '100%'}}>
                  {t('hem.contact.title')}
                </Subtitle>
              </Row>
              <Row className={width <= 992 && 'w-100 justify-content-start'}>
                <ContactDesc>
                  <b>
                    {t('hem.contact.desc-bold')}
                  </b>
                  {t('hem.contact.desc')}
                </ContactDesc>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={8} lg={5}>
              {isMobile && (
                <Row className='mb-2'>
                  <LineAxiona>
                    <a href='tel:+525519694584'>
                      <FaPhoneAlt style={{fontSize: '26px', color: '#FFFFFF'}} className='me-3 w-auto' />
                    </a>
                  </LineAxiona>
                </Row>
              )}
              <Row className={`mb-4 ${width <= 992 && 'justify-content-start'}`}>
                <LineAxiona className='position-relative'>
                  {!isMobile && (
                    <a href='tel:+525519694584'>
                      <FaPhoneAlt style={{fontSize: '26px', color: '#FFFFFF', top: 0, left: '-12%'}} className='me-3 w-auto mt-2 position-absolute' />
                    </a>
                  )}
                  {t('hem.contact.line')}
                  <a href='tel:+525519694584'>
                    {t('hem.contact.tel')}
                  </a>
                  {' '}{t('hem.contact.line-two')}
                </LineAxiona>
              </Row>
              <Row className={width <= 992 ? 'justify-content-start mt-5' : ''}>
                <RequestButton className='pe-2 pt-2 ps-2 pb-2' target='_blank' href='https://wa.me/5215519694584' rel='noreferrer noopener'>
                  {t('hem.contact.chat')}
                </RequestButton>
              </Row>
            </Col>
          </Row>
          {isMobile && (
            <img src={PersonMobile} alt='Ilustración de soporte técnico' className='img-fluid' />
          )}
        </Container>
      </ContactSection>
    </>
  );
};